import React from "react";
import "./MiniLoad.css";
type Props = {
  style?: Record<string, string>;
};

const MiniLoad = ({ style }: Props) => {
  return <div style={style} className="loader"/>;
};

export default MiniLoad;
