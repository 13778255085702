import React, { useEffect, useState } from "react";
import i18next from "i18next";
import { IG, PG, SE, SG, SI, SP } from "../consts";
import wrongText from "./wrongText";
import { emailRegex } from "const";

interface IErrors extends Record<string, string> {}

// TODO REFACTOR
export const useVerify = (checkProgram: boolean) => {
  const [errors, setErrors] = useState<IErrors>({});
  const [isValid, setIsValid] = useState<boolean>(true);

  useEffect(() => {
    setIsValid(!Object.keys(errors).length);
  }, [errors]);

  const addError = (error: Record<string, string>) => {
    const [[errorKey, errorValue]] = Object.entries(error);

    setErrors({ ...errors, [errorKey]: errorValue });
  };

  const getError = (errorKey: string) => errors[errorKey];

  const clearError = (errorKey: string) => {
    const updatedErrors = { ...errors };
    delete updatedErrors[errorKey];
    setErrors(updatedErrors);
  };

  const clearAllErrors = () => setErrors({});

  const verifyApplication = (application, applicant) => {
    const errors = checkApplication(application, applicant, checkProgram);

    setErrors(errors);

    return !Object.keys(errors).length;
  };

  return {
    errors,
    getError,
    addError,
    clearError,
    clearAllErrors,
    isValid,
    verifyApplication,
  };
};

const setErrorText = (inputName: string): string => {
  return i18next.t(`applicationFormValidation.${inputName}`, {
    ns: "errors",
  });
};

export const checkApplication = (application, applicant, checkProgram) => {
  const errors: IErrors = {};

  const addError = (error: Record<string, string>) => {
    Object.assign(errors, error);
  };

  // applicationForm
  const applicationFormErrors = checkApplicationForm(
    application.applicationForm
  );

  if (applicationFormErrors) addError(applicationFormErrors);

  // projectIdea
  const projectIdeaErrors = checkProjectIdeaFields(
    application.programs,
    application.projectIdea
  );
  if (projectIdeaErrors) addError(projectIdeaErrors);

  // indicators, order of error popup
  if (application.programs[0]?.name === SE) {
    let indicators = application.projectIdea.indicators;
    const indicatorsErrors = checkIndicators(application.programs, indicators);
    if (indicatorsErrors) addError(indicatorsErrors);
  }

  // calendarPlan
  if (application.programs[0]?.name === SE) {
    let calendarPlan = application.projectIdea.calendarPlan;
    const calendarPlanErrors = checkCalendarPlan(
      application.programs,
      calendarPlan
    );
    if (calendarPlanErrors) addError(calendarPlanErrors);
  }

  // organizationPotential
  if (
    application.programs[0]?.name === IG ||
    application.programs[0]?.name === SE
  ) {
    const organizationPotentialErrors = checkOrganizationPotential(
      application.programs,
      application.organizationPotential
    );
    if (organizationPotentialErrors) addError(organizationPotentialErrors);
  }

  // Scalability
  if (application.programs[0]?.name === PG) {
    const scalabilityErrors = checkScalability(
      application.programs,
      application.effectiveness
    );
    if (scalabilityErrors) addError(scalabilityErrors);
  }

  // Scalability
  if (application.programs[0]?.name === IG) {
    const scalabilityErrors = checkScalability(
      application.programs,
      application.scalability
    );
    if (scalabilityErrors) addError(scalabilityErrors);
  }

  // indicators, order of error popup
  if (application.programs[0]?.name !== SE) {
    let indicators = application.projectIdea.indicators;

    if (application.programs[0]?.name === PG)
      indicators = application.effectiveness.indicators;

    if (application.programs[0]?.name === IG)
      indicators = application.scalability.indicators;

    const indicatorsErrors = checkIndicators(application.programs, indicators);
    if (indicatorsErrors) addError(indicatorsErrors);
  }

  // calendarPlan
  if (application.programs[0]?.name !== SE) {
    let calendarPlan = application.projectIdea.calendarPlan;

    if (application.programs[0]?.name === SP)
      calendarPlan = application.effectiveness.calendarPlan;

    if (application.programs[0]?.name === PG)
      calendarPlan = application.effectiveness.calendarPlan;

    if (application.programs[0]?.name === IG)
      calendarPlan = application.scalability.calendarPlan;

    const calendarPlanErrors = checkCalendarPlan(
      application.programs,
      calendarPlan
    );
    if (calendarPlanErrors) addError(calendarPlanErrors);
  }

  if (application.programs[0]?.name === SG) {
    // businessModel
    const businessErrors = checkBusiness(
      application.programs,
      application.businessModel
    );
    if (businessErrors) addError(businessErrors);

    // teamMembers
    const tmErrors = checkTM(application.teamMembers, applicant);
    if (tmErrors) addError(tmErrors);
  }

  if (application.programs[0]?.name === IG) {
    // businessModel
    const businessErrors = checkBusiness(
      application.programs,
      application.businessModel
    );
    if (businessErrors) addError(businessErrors);
  }

  if (application.programs[0]?.name === SE) {
    // businessModel
    const businessErrors = checkBusiness(
      application.programs,
      application.businessModel
    );
    if (businessErrors) addError(businessErrors);
  }

  if (application.programs[0]?.name === IG) {
    // teamMembers
    const tmErrors = checkTM(application.teamMembers, applicant);
    if (tmErrors) addError(tmErrors);
  }

  // Resource
  const resourceErrors = checkExtraResource(
    application.programs,
    application.resource,
    application
  );
  if (resourceErrors) addError(resourceErrors);

  // teamMembers
  const tmErrors = checkTM(application.teamMembers, applicant);
  const shouldCheckTM = application.programs[0]?.name !== SE;
  if (tmErrors && shouldCheckTM) addError(tmErrors);

  if (
    application.programs[0]?.name === SG ||
    application.programs[0]?.name === IG ||
    application.programs[0]?.name === SE
  ) {
    const innovaErrors = checkInnovativeness(
      application.programs,
      application.innovativeness
    );
    if (innovaErrors) addError(innovaErrors);
  }

  if (
    application.programs[0]?.name === PG ||
    application.programs[0]?.name === SG ||
    application.programs[0]?.name === IG
  ) {
    // environmental
    const environmentalErrors = checkEnvironmental(
      application.programs,
      application.environmental
    );
    if (environmentalErrors) addError(environmentalErrors);
  }

  // JAS camp program
  if (
    application.programs[0]?.name === IG ||
    application.programs[0]?.name === SG
  ) {
    const JasCampErrors = checkIfJasCamp(application.appDocs, checkProgram);
    if (JasCampErrors) addError(JasCampErrors);
  }

  if (application.programs[0]?.name === SE) {
    const certificateError = checkCourseCertificate(application);
    if (certificateError) addError(certificateError);
  }

  if (Object.keys(errors).length) {
    const [fieldName, errorValue] = Object.entries(errors)[0];
    wrongText(errorValue);
    let fieldRefs = document.getElementsByName(fieldName);
    let dataAttrRef = document.querySelector(`[data-val=${fieldName}]`);
    const yOffset = -250;
    if (dataAttrRef) {
      const y =
        dataAttrRef.getBoundingClientRect().top + window.pageYOffset + yOffset;
      window.scrollTo({ top: y, behavior: "smooth" });
    }

    if (fieldRefs.length) {
      const y =
        fieldRefs[0].getBoundingClientRect().top + window.pageYOffset + yOffset;
      window.scrollTo({ top: y, behavior: "smooth" });
    } else {
      const fieldRef = document.getElementById(fieldName);
      if (fieldRef) {
        const y =
          fieldRef.getBoundingClientRect().top + window.pageYOffset + yOffset;
        window.scrollTo({ top: y, behavior: "smooth" });
      }
    }
  }
  return errors;
};

const checkIfJasCamp = (appDocs, checkProgram) => {
  if (!checkProgram && appDocs.length === 0)
    return {
      checkProgram: setErrorText("checkJasCamp"),
    };
  return false;
};

const checkCourseCertificate = (application) => {
  if (!application.courseCertificate.file?.name)
    return {
      courseCertificate: setErrorText("noCourseCertificate"),
    };
};

const checkProjectIdeaFields = (programs, projectIdea) => {
  const projectIdeaErors: any = {};
  if (!projectIdea)
    return {
      socialProblem: i18next.t("FillEmptyFieldsProjectIdea"),
    };

  if (!projectIdea.socialProblem)
    projectIdeaErors.socialProblem = setErrorText("socialProblem");

  // разные сценарии для разных программ тк поля перемешаны
  if (programs[0]?.name === SE) {
    if (!projectIdea.motivationalQuestion)
      projectIdeaErors.motivationalQuestion = setErrorText(
        "motivationalQuestion"
      );

    if (!projectIdea.problemSolving)
      projectIdeaErors.problemSolving = setErrorText("problemSolving");

    if (!projectIdea.viability)
      projectIdeaErors.viability = setErrorText("viability");

    if (!projectIdea.geography)
      projectIdeaErors.geography = setErrorText("geography");

    if (!projectIdea.beneficiaries)
      projectIdeaErors.beneficiaries = setErrorText("beneficiaries");
  }

  if (programs[0]?.name === SP) {
    if (!projectIdea.problemSolving)
      projectIdeaErors.problemSolving = setErrorText("problemSolving");

    if (!projectIdea.geography)
      projectIdeaErors.geography = setErrorText("geography");

    if (!projectIdea.beneficiaries)
      projectIdeaErors.beneficiaries = setErrorText("beneficiaries");

    if (!projectIdea.motivationalQuestion)
      projectIdeaErors.motivationalQuestion = setErrorText(
        "motivationalQuestion"
      );
  }

  if (programs[0]?.name === PG) {
    if (!projectIdea.motivationalQuestion)
      projectIdeaErors.motivationalQuestion = setErrorText(
        "motivationalQuestion"
      );

    if (!projectIdea.problemSolving)
      projectIdeaErors.problemSolving = setErrorText("problemSolving");

    if (!projectIdea.beneficiaries)
      projectIdeaErors.beneficiaries = setErrorText("beneficiaries");

    if (!projectIdea.customers)
      projectIdeaErors.customers = setErrorText("customers");

    if (!projectIdea.marketingChannels)
      projectIdeaErors.marketingChannels = setErrorText("marketingChannels");

    if (!projectIdea.projectPartners)
      projectIdeaErors.projectPartners = setErrorText("projectPartners");
  }

  if (programs[0]?.name === IG) {
    if (!projectIdea.beneficiaries)
      projectIdeaErors.beneficiaries = setErrorText("beneficiaries");

    if (!projectIdea.motivationalQuestion)
      projectIdeaErors.motivationalQuestion = setErrorText(
        "motivationalQuestion"
      );

    if (!projectIdea.geography)
      projectIdeaErors.geography = setErrorText("geography");
  }

  if (programs[0]?.name === SG) {
    if (!projectIdea.motivationalQuestion)
      projectIdeaErors.motivationalQuestion = setErrorText(
        "motivationalQuestion"
      );

    if (!projectIdea.problemSolving)
      projectIdeaErors.problemSolving = setErrorText("problemSolving");

    if (!projectIdea.viability)
      projectIdeaErors.viability = setErrorText("viability");

    if (!projectIdea.geography)
      projectIdeaErors.geography = setErrorText("geography");

    if (!projectIdea.beneficiaries)
      projectIdeaErors.beneficiaries = setErrorText("beneficiaries");
  }

  if (Object.keys(projectIdeaErors).length === 0) return false;
  return projectIdeaErors;
};

const checkOrganizationPotential = (programs, organizationPotential) => {
  const organizationPotentialErors: any = {};
  let financialNotFilledCount = 0;
  if (programs[0].name !== SE && !organizationPotential.operatingBusiness) {
    organizationPotentialErors.operatingBusiness =
      setErrorText("operatingBusiness");
    return organizationPotentialErors
  }
  console.log(organizationPotential);
  if (
    (organizationPotential.financialRevenue &&
      !organizationPotential.financialRevenue.length) &&
    (organizationPotential.financialExpenses &&
      !organizationPotential.financialExpenses.length)
  ) {
   organizationPotentialErors.financialRevenue =
      setErrorText("financialRevenue");
   return organizationPotentialErors
  }

  if ((!organizationPotential.financialExpenses.length && organizationPotential.financialRevenue.length) ||
    (organizationPotential.financialExpenses.length && !organizationPotential.financialRevenue.length)) {
    organizationPotentialErors.financialRevenue = setErrorText("financialRevenueFull");
    return organizationPotentialErors;
  }

  organizationPotential.financialExpenses.forEach(
    (expense) => {
      if (expense.taxExpenses === '') financialNotFilledCount++;
      if (expense.directCosts === '') financialNotFilledCount++;
      if (expense.marketing === '') financialNotFilledCount++;
    },
  );

  organizationPotential.financialRevenue.forEach(
    (expense) => {
      if (expense.services === '') financialNotFilledCount++;
    },
  );

  const financialExpensesAllFilledWithZero = organizationPotential.financialExpenses.every(
    (expense) =>
      expense.taxExpenses === 0 && expense.marketing === 0 && expense.directCosts === 0,
  );

  const financialRevenueAllFilledWithZero = organizationPotential.financialRevenue.every(
    (expense) =>
      expense.services === 0,
  );

  if (financialNotFilledCount === 8) {
    organizationPotentialErors.financialRevenue =
      setErrorText('financialRevenue');
    return organizationPotentialErors;
  } else if (financialNotFilledCount > 0) {
    organizationPotentialErors.financialRevenue =
      setErrorText('financialRevenueFull');
    return organizationPotentialErors;
  }

  if (!(financialExpensesAllFilledWithZero && financialRevenueAllFilledWithZero) && !organizationPotential.file.name) {
    organizationPotentialErors.financialRevenue = setErrorText(
      'financialRevenueFile',
    );
    return organizationPotentialErors;
  }

  if (Object.keys(organizationPotentialErors).length === 0) return false;

  return organizationPotentialErors;
};

const checkScalability = (programs, scalability) => {
  const scalabilityErrors: any = {};

  if (!scalability.idea) {
    if (programs[0].name === IG) {
      scalabilityErrors.idea = setErrorText("ideaIG");
    } else scalabilityErrors.idea = setErrorText("idea");
  }

  if (programs[0].name === IG) {
    if (!scalability.goal) {
      scalabilityErrors.goal = setErrorText("goal");
    }
  }

  if (programs[0].name === PG) {
    if (!scalability.geography) {
      scalabilityErrors.geography = setErrorText("geography");
    }
  }
  if (Object.keys(scalabilityErrors).length === 0) return false;

  return scalabilityErrors;
};

const checkInnovativeness = (programs, inn) => {
  const innovationErrors: any = {};

  if (!inn.innovation) {
    innovationErrors.innovation = setErrorText("innovation");
  }

  if (!inn.competitors) {
    innovationErrors.competitors = setErrorText("competitors");
  }

  if (Object.keys(innovationErrors).length === 0) return false;
  return innovationErrors;
};

const checkIndicators = (prog, indicators) => {
  let errorsCount = 0;
  if (indicators && indicators.length) {
    for (let i = 0; i < indicators.length; i++) {
      if (!indicators[i].name) errorsCount++;
      if (!indicators[i].unit || indicators[i].unit === i18next.t('choose')) errorsCount++;
      if (!indicators[i].ultimateGoal) errorsCount++;
    }

    if (errorsCount >= 1 && errorsCount < indicators.length * 3)
      return {indicators: setErrorText('indicatorsFull')};
    if (errorsCount === indicators.length * 3)
      return {indicators: setErrorText('indicators')};
    return false;
  } else {
    return {
      name: i18next.t("EditEfficiencyIoS"),
      unit: i18next.t("EditEfficiencyIoS"),
      ultimateGoal: i18next.t("EditEfficiencyIoS"),
    };
  }
};

const checkCalendarPlan = (programs, calendarPlan) => {
  if (!calendarPlan?.length) {
    return {
      calendarPlan: setErrorText("calendarPlan"),
    };
  }

  let errorsCount = 0
  calendarPlan.forEach((plan) => {
    const dateArr = plan.deadline.split('-');
    if (!plan.event) errorsCount++
    if (plan.deadline === '-' || !plan.deadline || dateArr[0] === 'null' || dateArr[1] === 'null') errorsCount++
  });

  if (errorsCount && errorsCount < 2 * calendarPlan.length) {
    return {calendarPlan: setErrorText('calendarPlanFull')}
  }
  if (errorsCount) {
    return { calendarPlan: setErrorText('calendarPlan')}
  }
};

const checkExtraResource = (programs, resource, application = null) => {
  const inputRequiredErrorText = i18next.t("input.required", { ns: "errors" });
  const extraResourcesErrors: any = {};
  if (
    programs[0].name === SI ||
    programs[0].name === SG ||
    programs[0].name === IG
  ) {
    if (!resource.partners) {
      extraResourcesErrors.partners = setErrorText("partners");
    }
  }

  if (programs[0].name === SI) {
    if (!resource.results) {
      extraResourcesErrors.results = inputRequiredErrorText;
    }
  }

  if (!resource.budget || !resource.budget.length) {
    extraResourcesErrors.budget = setErrorText("budget");
  }

  if (programs[0].name === SI) {
    if (!resource.marketTest) {
      extraResourcesErrors.marketTest = inputRequiredErrorText;
    }
    if (!resource.income) {
      extraResourcesErrors.income = inputRequiredErrorText;
    }

    if (!resource.funds) {
      extraResourcesErrors.funds = inputRequiredErrorText;
    }
    if (!resource.description) {
      extraResourcesErrors.description = inputRequiredErrorText;
    }
  }
  if (programs[0].name === SE) {
    const tmErrors = checkTM(application?.teamMembers, "");
    if (tmErrors) return { ...extraResourcesErrors, ...tmErrors };
    if (!resource.partners) {
      extraResourcesErrors.partners = setErrorText("partners");
    }
  }
  if (resource.budget) {

    const source = [
      i18next.t('fundsr', { lng: 'ru' }).toLowerCase(),
      i18next.t('fundsr', { lng: 'en' }).toLowerCase(),
      i18next.t('fundsr', { lng: 'kz' }).toLowerCase(),
    ];
    let sumAll = 0;
    for (let budget of resource.budget) {
      if (
        !budget.expenditureItem ||
        !budget.amount ||
        !budget.quantity ||
        !budget.source
      ) {
        extraResourcesErrors.expenditureItem = setErrorText("budgetFull");
      }
      if (!source.includes(budget.source.toLowerCase())) continue
      if (typeof budget.price === "number") {
        sumAll += budget.price;
      } else if (budget.price) {
        sumAll += budget.price.replace(/^\s+|\s+|\s$/g, "") - 0;
      }
    }
    if (sumAll > 1500000 && programs[0].name === SP) {
      extraResourcesErrors.budget = i18next.t("limits.limitSP");
    }
    if (sumAll > 5000000 && programs[0].name === SE) {
      extraResourcesErrors.budget = i18next.t("limits.limitSE");
    }
    // if (sumAll > 10000000 && programs[0].name === SI) {
    //   extraResourcesErrors.budget = i18next.t("limits.limitSI");
    // }
    // if (sumAll > 5000000 && programs[0].name === IG) {
    //   extraResourcesErrors.budget = i18next.t("limits.limitIG");
    // }
    // if (sumAll > 3000000 && programs[0].name === SG) {
    //   extraResourcesErrors.budget = i18next.t("limits.limitSG");
    // }
    // if (sumAll > 1000000 && programs[0].name === PG) {
    //   extraResourcesErrors.budget = i18next.t("limits.limitPG");
    // }
  }

  if (programs[0].name !== PG && programs[0].name !== SP) {
    let errorsCount= 0;
    if (!resource.materialBase || !resource.materialBase.length) {
      extraResourcesErrors.materialBase = setErrorText('materialBase');
    }
    if (resource?.materialBase?.length > 0) {
      for (let mb of resource.materialBase) {
        if (!mb.purpose) errorsCount++
        if (!mb.name) errorsCount++
      }

      if (errorsCount > 0 && errorsCount < 2 * resource.materialBase.length) {
        extraResourcesErrors.purpose = setErrorText('materialBaseFull');
      } else if (errorsCount) {
        extraResourcesErrors.purpose = setErrorText('materialBase');
      }
    }
  }

  if (Object.keys(extraResourcesErrors).length === 0) return false;
  return extraResourcesErrors;
};

const checkApplicationForm = (applicationForm) => {
  const fieldApllicationFormErrors: any = {};

  if (!applicationForm) {
    return { projectName: i18next.t("EditApplicationForm") };
  }

  if (!applicationForm.projectName) {
    fieldApllicationFormErrors.projectName = setErrorText("projectName");
  }

  if (
    applicationForm.projectDuration === "-" ||
    !applicationForm.projectDuration
  ) {
    fieldApllicationFormErrors.projectDuration =
      setErrorText("projectDuration");
  }

  if (!applicationForm.applicantKind) {
    fieldApllicationFormErrors.applicantKind = setErrorText("applicantKind");
  }

  if (applicationForm.projectDuration) {
    let projectDuration = applicationForm.projectDuration.split("-");
    if (projectDuration[0] === "null" || projectDuration[1] === "null") {
      fieldApllicationFormErrors.projectDuration =
        setErrorText("projectDuration");
    }
  }

  if (applicationForm.applicantKind === "ORGANIZATION") {
    if (!applicationForm.organizationName) {
      fieldApllicationFormErrors.organizationName =
        setErrorText("organizationName");
    }

    if (!applicationForm.activityDirection) {
      fieldApllicationFormErrors.activityDirection =
        setErrorText("activityDirection");
    }

    if (!applicationForm.organizationBin) {
      fieldApllicationFormErrors.organizationBin =
        setErrorText("organizationBin");
    } else if (applicationForm.organizationBin.length !== 12) {
      fieldApllicationFormErrors.organizationBin =
        setErrorText("organizationBin");
    }
  }

  if (Object.keys(fieldApllicationFormErrors).length === 0) return false;
  return fieldApllicationFormErrors;
};

const checkTM = (tm = [], applicant) => {
  const teamMembersErrors: any = {};
  if (!tm.length) {
    teamMembersErrors.teamMembers = setErrorText("teamMembers");
  }
  console.log("tm", tm);
  for (let i in tm) {
    if (!tm[i].email) {
      // teamMembersErrors.email = setErrorText("teamMembers");
      teamMembersErrors[`tm-email-${i}`] = setErrorText("invalidEmailInTM");
    }
    if (tm[i].email && !emailRegex.test(tm[i].email)) {
      teamMembersErrors[`tm-email-${i}`] = setErrorText("invalidEmailInTM");
    }
    if (!tm[i].iin) {
      // teamMembersErrors.iin = setErrorText("teamMembers");
      teamMembersErrors[`tm-iin-${i}`] = setErrorText("invalidUINInTM");
    }
    if (tm[i].iin && tm[i].iin.length !== 12) {
      // teamMembersErrors.iin = setErrorText("invalidUINInTM");
      teamMembersErrors[`tm-iin-${i}`] = setErrorText("invalidUINInTM");
    }
    if (!tm[i].fio) {
      // teamMembersErrors.iin = setErrorText("teamMembers");
      teamMembersErrors[`tm-fio-${i}`] = setErrorText("teamMembersTable.fio");
    }
    if (!tm[i].role) {
      // teamMembersErrors.iin = setErrorText("teamMembers");
      teamMembersErrors[`tm-role-${i}`] = setErrorText("teamMembersTable.role");
    }
    if (!tm[i].workExperience) {
      // teamMembersErrors.iin = setErrorText("teamMembers");
      teamMembersErrors[`tm-workExperience-${i}`] = setErrorText("teamMembersTable.workExperience");
    }
    if (!tm[i].resume) {
      // teamMembersErrors.iin = setErrorText("tmResume");
      teamMembersErrors[`tm-resume-${i}`] = setErrorText("tmResume");
    }
  }

  if (Object.keys(teamMembersErrors).length === 0) return false;
  return teamMembersErrors;
};

const checkEnvironmental = (programs, environmental) => {
  const environmentalErrors: any = {};

  if (!environmental.environmental)
    environmentalErrors.environmental = setErrorText("environmental");

  if (!environmental.methods)
    environmentalErrors.methods = setErrorText("methods");

  if (!environmental.friendliness)
    environmentalErrors.friendliness = setErrorText("friendliness");

  if (Object.keys(environmentalErrors).length === 0) return false;
  return environmentalErrors;
};

const checkBusiness = (programs, businessModel) => {
  const inputRequiredErrorText = i18next.t("input.required", { ns: "errors" });
  const tableRequiredErrorText = i18next.t("input.table", { ns: "errors" });
  const businessErrors: any = {};
  let financialModelCount = 0;

  if (programs[0].name === SG) {
    if (!businessModel.product)
      businessErrors.product = setErrorText("product");
  }
  if (programs[0].name === SE) {
    if (!businessModel.product)
      businessErrors.product = setErrorText("product");
    if (!businessModel.clientPortrait)
      businessErrors.clientPortrait = setErrorText("clientPortrait");
    if (!businessModel.marketingChannels)
      businessErrors.marketingChannels = setErrorText("marketingChannels");
  }

  if (!businessModel.clientPortrait)
    businessErrors.clientPortrait = setErrorText("clientPortrait");

  if (!businessModel.marketingChannels)
    businessErrors.marketingChannels = setErrorText("marketingChannels");

  if (!businessModel.financialModel.costAndVolume) {
    if (programs[0].name === IG) {
      businessErrors.costAndVolume = setErrorText('financialRevenueAfterScal');
    } else {
      financialModelCount++;
    }
  }

  if (!businessModel.financialModel.expenditure) {
    if (programs[0].name === IG) {
      businessErrors.expenditure = setErrorText('financialRevenueAfterScal');
    } else {
      financialModelCount++;
    }
  }

  if (!businessModel.financialModel.incomeDistribution) {
    if (programs[0].name === IG) {
      businessErrors.incomeDistribution = setErrorText(
        'financialRevenueAfterScal',
      );
    } else {
      financialModelCount++;
    }
  }

  if (financialModelCount < 3 && financialModelCount > 0) {
    businessErrors.incomeDistribution = setErrorText('expenditureFull');
  } else if (financialModelCount) {
    businessErrors.incomeDistribution = setErrorText('expenditure');
  }

  if (Object.keys(businessErrors).length === 0) return false;

  return businessErrors;
};
