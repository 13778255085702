import React from "react";
import { Trans } from "react-i18next";
import classNames from "classnames";
import wrongText from "../../helpers/wrongText";
import FileComponent from "../FileComponent";
import { injectAppState } from "../../stores";
import { PG, SE, SP } from "../../consts";
import Load from "../Load";
import { ApplicationFormContext } from "../ApplicationFormProvider";
import { sectionNumber } from "../ApplicationFormProvider/sectionNumber";
import i18next from "i18next";
import "./TM.css";
import matchOnlyLetters from "../../helpers/matchOnlyLetters";
import InputMask from "react-input-mask";
import validateFileSize from "../../helpers/validateFileSize";
import { toast } from "react-toastify";
import MiniLoad from "../MiniLoad/MiniLoad";
import Notify from "../../helpers/Notifier";

@injectAppState
class TM extends React.Component<any, any> {
  static contextType = ApplicationFormContext;

  state = {
    fileLoading: false,
    teamRowLimitLogVisible: false,
    shouldRerender: false,
  };

  handleOnBlur = (error: string) => {
    this.context.clearError(error);
  };

  handleInputChangeTM = (event, index) => {
    const target = event.target;
    const { name, type } = target;
    let value = target.type === "checkbox" ? target.checked : target.value;
    const experiences = [...this.context.applicationForm.teamMembers];
    const isOnlyLetters = matchOnlyLetters(value);

    if (value.length) {
      if (name === "iin" && value === this.context.applicant.iin) {
        wrongText(i18next.t("IinOfApplicantInTMError"));
        return;
      }
      if (name === "iin" && (value.length > 12 || !/^\d+$/.test(value))) {
        return;
      }

      if (
        name === "email" &&
        (value.length > 40 || !/^[a-zA-Z@.0-9]+$/.test(value))
      ) {
        return;
      }

      if (
        target.type === "text" &&
        isOnlyLetters &&
        name !== "iin" &&
        name !== "workExperience"
      ) {
        return;
      }
    }

    experiences[index][name] = value;
    this.context.handleApplicationFormChange({ teamMembers: experiences });
  };

  addTM = () => {
    this.context.clearError("teamMembers");
    const teamMembers = this.context.applicationForm.teamMembers
      ? [...this.context.applicationForm.teamMembers]
      : [];
    let experienceForm = {
      email: "",
      iin: "",
      fio: "",
      workExperience: "",
      role: "",
    };
    if (teamMembers.length < 6) {
      teamMembers.push(experienceForm);
      this.context.handleApplicationFormChange({ teamMembers });
      this.setState({
        teamRowLimitLogVisible: false,
      });
    } else {
      this.setState({
        teamRowLimitLogVisible: true,
      });
    }
  };

  deleteTM = (index) => {
    let experiences = [...this.context.applicationForm.teamMembers];
    if (experiences.length > 0) experiences.splice(index, 1);

    this.context.handleApplicationFormChange({ teamMembers: experiences });
  };

  handleFileChange = (files, idx) => {
    if (!validateFileSize(50, files)) {
      Notify.pushErrorMessage(i18next.t("info.fileSizeLimit", { ns: "errors" }));
      return;
    }
    let teamMembers = [...this.context.applicationForm.teamMembers];

    if (files.length) {
      this.setState({ fileLoading: true });

      Array.from(files).map((item) => {
        this.props.appState.filesStore
          .uploadFile(item)
          .then((response) => {
            teamMembers[idx] = {
              ...teamMembers[idx],
              resume: { file: response.data },
            };

            this.context.handleApplicationFormChange({ teamMembers });
          })
          .catch(() => {
            wrongText(i18next.t("ErrorLoading"));
          })
          .finally(() => {
            this.setState({ fileLoading: false });
          });
      });
    }
  };

  loadFile = (id) => {
    return this.props.appState.filesStore.downloadFile(id);
  };

  renderDocFile(doc) {
    if (doc) {
      return (
        <FileComponent id={doc.id} name={doc.name} extension={doc.extension} />
      );
    }
  }

  deleteFile(teamMember, teamMembers) {
    teamMember.resume = "";
    this.context.handleApplicationFormChange({ teamMembers });
  }

  getLeaderReadOnly = (readOnly, idx) => {
    const programName = this.props.program;
    if (readOnly) return readOnly;
    if (programName === SE && idx === 0) {
      return true;
    }
  };

  render() {
    const teamMembers = this.context.applicationForm.teamMembers;
    const { readOnly, getError } = this.context;
    const programName = this.props.program;
    const applicant = this.context.applicant;
    const fieldError = getError("teamMembers") && teamMembers.length <= 1;
    return (
      <div className={fieldError && "tm-error red-border-error"}>
        {programName !== PG && programName !== SP && programName !== SE && (
          <>
            <div className="compform__divider" />
            <h1 className="main__question">
              {sectionNumber.renderSection("TEAM")}. <Trans>RESOURCE</Trans>
            </h1>
          </>
        )}

        <div>
          <h3 className="form__question" id="teamMembers">
            {sectionNumber.renderSubSection("teamMembers")}.{" "}
            <Trans>teamMembers</Trans>
          </h3>
          <p className="form__question__descrption">
            <Trans>teamMExtra</Trans>
          </p>
        </div>

        <div className="application-table__body application-table__body_tm m-t">
          {teamMembers &&
            teamMembers
              .sort((a, b) => {
                if (a.iin === applicant.iin) return -1;
                else if (b.iin === applicant.iin) return 1;
                else return 0;
              })
              .map((tm, idx) => {
                const tableErrorByIndex =
                  getError(`tm-email-${idx}`) ||
                  getError(`tm-iin-${idx}`) ||
                  getError(`tm-fio-${idx}`) ||
                  getError(`tm-role-${idx}`) ||
                  getError(`tm-workExperience-${idx}`) ||
                  getError(`tm-resume-${idx}`);

                return (
                  <div key={idx}>
                    <p className="tm-table-label" style={{ fontSize: "12px" }}>
                      {idx === 0 ? (
                        <>
                          <Trans>teamLeader</Trans>
                          <br />
                          <span style={{ fontWeight: 400 }}>
                            <i>
                              <Trans>teamLeaderReworkNotification</Trans>
                            </i>
                          </span>
                        </>
                      ) : (
                        <Trans>teamMember</Trans>
                      )}
                    </p>
                    <div
                      className={
                        tableErrorByIndex
                          ? "application-table application-table-tm red-border-error"
                          : "application-table application-table-tm"
                      }
                    >
                      <table className="second__table second__table-tm">
                        <tbody>
                          <tr>
                            <th id="width-40">
                              {idx === 0 ? (
                                "Email"
                              ) : (
                                <Trans>OPMemberEmail</Trans>
                              )}
                            </th>
                            <td className="expense-plan__td">
                              <input
                                value={tm.email}
                                maxLength={200}
                                className={classNames(
                                  "expense-plan__input",
                                  this.getLeaderReadOnly(readOnly, idx) &&
                                    "cursor-not-allowed"
                                )}
                                placeholder={i18next.t('placeHolder')}
                                type="email"
                                name="email"
                                data-val={`tm-email-${idx}`}
                                required
                                onBlur={() =>
                                  this.handleOnBlur(`tm-email-${idx}`)
                                }
                                onChange={(event) =>
                                  this.handleInputChangeTM(event, idx)
                                }
                                readOnly={this.getLeaderReadOnly(readOnly, idx)}
                              />
                            </td>
                          </tr>
                          <tr>
                            <th id="width-40">
                              <Trans>IIN</Trans>
                            </th>
                            <td className="expense-plan__td">
                              <InputMask
                                placeholder={i18next.t('placeHolderNumber')}
                                value={tm.iin}
                                name="iin"
                                data-val={`tm-iin-${idx}`}
                                onBlur={() =>
                                  this.handleOnBlur(`tm-iin-${idx}`)
                                }
                                onChange={(event) =>
                                  this.handleInputChangeTM(event, idx)
                                }
                                className={classNames(
                                  "expense-plan__input",
                                  this.getLeaderReadOnly(readOnly, idx) &&
                                    "cursor-not-allowed"
                                )}
                                required
                                readOnly={this.getLeaderReadOnly(readOnly, idx)}
                              />
                            </td>
                          </tr>
                          <tr>
                            <th id="width-40">
                              <Trans>fullName</Trans>
                            </th>
                            <td className="expense-plan__td">
                              <input
                               placeholder={i18next.t('placeHolder')}
                                value={tm.fio ?? ""}
                                maxLength={200}
                                className={classNames(
                                  "expense-plan__input",
                                  this.getLeaderReadOnly(readOnly, idx) &&
                                    "cursor-not-allowed"
                                )}
                                type="text"
                                onBlur={() =>
                                  this.handleOnBlur(`tm-fio-${idx}`)
                                }
                                name="fio"
                                data-val={`tm-fio-${idx}`}
                                onChange={(event) =>
                                  this.handleInputChangeTM(event, idx)
                                }
                                readOnly={this.getLeaderReadOnly(readOnly, idx)}
                              />
                            </td>
                          </tr>
                          <tr>
                            <th id="width-40">
                              {idx === 0 ? (
                                <Trans>specificRoleLeader</Trans>
                              ) : (
                                <Trans>specificRole</Trans>
                              )}
                            </th>
                            <td className="expense-plan__td">
                              <input
                                value={tm.role}
                                className={classNames(
                                  "expense-plan__input",
                                  this.getLeaderReadOnly(readOnly, idx) &&
                                    "cursor-not-allowed"
                                )}
                                placeholder={i18next.t('placeHolder')}
                                maxLength={200}
                                type="text"
                                data-val={`tm-role-${idx}`}
                                name="role"
                                onBlur={() =>
                                  this.handleOnBlur(`tm-role-${idx}`)
                                }
                                onChange={(event) =>
                                  this.handleInputChangeTM(event, idx)
                                }
                                readOnly={this.getLeaderReadOnly(readOnly, idx)}
                              />
                            </td>
                          </tr>
                          <tr>
                            <th id="width-40">
                              {idx === 0 ? (
                                <Trans>WorkExperienceLeader</Trans>
                              ) : (
                                <Trans>WorkExperience</Trans>
                              )}
                            </th>
                            <td className="expense-plan__td">
                              <input
                                type="text"
                                value={tm.workExperience}
                                name="workExperience"
                                data-val={`tm-workExperience-${idx}`}
                                className={classNames(
                                  "expense-plan__input",
                                  this.getLeaderReadOnly(readOnly, idx) &&
                                    "cursor-not-allowed"
                                )}
                                placeholder={i18next.t('placeHolder')}
                                onBlur={() =>
                                  this.handleOnBlur(`tm-workExperience-${idx}`)
                                }
                                onChange={(event) =>
                                  this.handleInputChangeTM(event, idx)
                                }
                                readOnly={this.getLeaderReadOnly(readOnly, idx)}
                              />
                            </td>
                          </tr>
                          <tr data-val={`tm-resume-${idx}`}>
                            <th id="width-40">
                              <Trans>Resume</Trans>
                            </th>
                            <td className={"expense-plan__td"}>
                              <div
                                className="inputfile-wrap"
                                style={{ justifyContent: "center" }}
                              >
                                {tm?.resume?.file && (
                                  <div className="flex align-items-center">
                                    {this.renderDocFile(tm.resume.file)}
                                    {!this.getLeaderReadOnly(readOnly, idx) && (
                                      <span
                                        onClick={() =>
                                          this.deleteFile(tm, teamMembers)
                                        }
                                        className="icon-delete"
                                      >
                                        +
                                      </span>
                                    )}
                                  </div>
                                )}
                                <label
                                  className={classNames(
                                    "button-input btn btn-success",
                                    this.getLeaderReadOnly(readOnly, idx) &&
                                      "cursor-not-allowed disabled"
                                  )}
                                  style={{ marginTop: "0", minWidth: "126px" }}
                                  htmlFor={`inputfileTM-${idx}`}
                                >
                                  {this.state.fileLoading ? (
                                    <MiniLoad />
                                  ) : (
                                    <Trans>ChooseFile</Trans>
                                  )}
                                </label>
                                <i
                                  style={{
                                    paddingTop: "5px",
                                    paddingBottom: "5px",
                                    fontWeight: "400",
                                  }}
                                >
                                  <Trans>SupportedFileFormatsCV</Trans>
                                </i>
                                <input
                                  type="file"
                                  id={`inputfileTM-${idx}`}
                                  accept=".pdf,.doc,.docx"
                                  onChange={(event) =>
                                    this.handleFileChange(
                                      event.target.files,
                                      idx
                                    )
                                  }
                                  disabled={this.getLeaderReadOnly(
                                    readOnly,
                                    idx
                                  )}
                                />
                              </div>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                      {!readOnly && idx !== 0 && idx !== 1 && (
                        <button
                          className="button-input btn btn-danger tm-delete-btn"
                          onClick={() => this.deleteTM(idx)}
                        >
                          <Trans>delete</Trans>
                        </button>
                      )}
                    </div>
                  </div>
                );
              })}
        </div>
        <div className="add-expense__container">
          <button
            className="button-input btn btn-success"
            onClick={this.addTM}
            disabled={(teamMembers && teamMembers.length >= 10) || readOnly}
          >
            <Trans>Add</Trans>
          </button>
          {this.state.teamRowLimitLogVisible && (
            <p
              className="form__question__descrption"
              style={{
                color: "#dc3545",
                fontSize: "12px",
              }}
            >
              <br />
              <Trans>TMlimit</Trans>
            </p>
          )}
        </div>
      </div>
    );
  }
}

export default TM;
