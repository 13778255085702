import React from "react";
import logoWhite from "../../assets/icons/logowhite.png";
import { Container } from "react-bootstrap";
import "./footer.css";
import { Trans } from "react-i18next";
import { AppStateObserver, injectAppState } from "../../stores";
import { observer } from "mobx-react";

@injectAppState
@observer
class Footer extends React.Component<AppStateObserver> {
  render() {
    const { language } = this.props.appState.userStore;

    return (
      <Container fluid className="footer">
        <section className="footer-wrapper">
          <section className="footer__logo">
            <img src={logoWhite} className="logowhite" alt="logoWhite" />
          </section>
          <section className="footer__contacts">
            <p>
              <span style={{ fontWeight: "bold" }}>
                <Trans>ConnectWidth</Trans>:
              </span>
              +7 (771) 055-79-41, sdfgrants@nu.edu.kz
            </p>
            <p>
              <Trans>portalProject</Trans>
            </p>
          </section>
        </section>
      </Container>
    );
  }
}

export default Footer;
