import * as React from "react";
import { Trans } from "react-i18next";
import './UserGuide.css';
import UserGuideInstruction from "./UserGuideInstructionCard";
import INSTRUCTIONS from './INSTRUCTIONS.json';
import { MdDoubleArrow } from "react-icons/md";

interface Instruction {
    id: string;
    title: string;
    desc: string;
    imgURL: string;
    imgAlt: string;
  }

const UserGuide = () => {
    const navigationList = [
        {
            name: 'navigationItem1',
            id: 'id1'
        },
        {
            name: 'navigationItem2',
            id: 'id2'
        },
        {
            name: 'navigationItem3',
            id: 'id3'
        },
        {
            name: 'navigationItem4',
            id: 'id4'
        },
        {
            name: 'navigationItem5',
            id: 'id5'
        },
        {
            name: 'navigationItem6',
            id: 'id6'
        }
    ];

    const [activeTab, setActiveTab] = React.useState('');
    const scrollToInstructionPart = (id) => {
        const elem = document.getElementById(id);
        const THRESHOLD_MARGIN = 150;

        if(elem) {
            const offset = elem.offsetTop - THRESHOLD_MARGIN;
            window.scrollTo({
                behavior: 'smooth',
                top: offset
            })
        }
    }

    return (
        <div className="guide-container">
            <div className="guide__header">
                <h1>
                    <Trans>UserGuide</Trans>
                </h1>
            </div>
            <div className="guide__sections">
                <div className="guide__section guide__section-instruction">
                {(INSTRUCTIONS as Instruction[]).map((instruction, i) => (
                    <UserGuideInstruction 
                        key={i}
                        title={instruction.title}
                        desc={instruction.desc}
                        id={instruction.id}
                        imgURL={instruction.imgURL}
                        imgAlt={instruction.imgAlt}
                />
                ))}
            </div>
                <div className="guide__section guide__section-navigation">
                    <div className="guide__navigation-block">
                        <div className="guide__navigation-headline">
                            <Trans>Content</Trans>
                        </div>
                        <div className="guide__navigation-list">
                            <ul>
                                <>
                                    {navigationList.map((nav) => (
                                        <li key={nav?.id}
                                            className={activeTab === nav?.id ? 'nav-item_active' : 'nav-item'}
                                            onClick={() => {
                                                setActiveTab(nav?.id);
                                                scrollToInstructionPart(nav?.id)
                                            }}>
                                            <MdDoubleArrow style={{color: '#DE944C', fontSize: '15px' }}/>
                                            <span><Trans>{nav?.name}</Trans></span>
                                        </li>
                                    ))}
                                </>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default UserGuide;