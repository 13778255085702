import React, { forwardRef, useEffect, useRef, useState } from 'react';
import DatePicker from 'react-datepicker';
import './date-picker-multi.css';
import 'react-datepicker/dist/react-datepicker.css';
import { registerLocale } from 'react-datepicker';
import ru from 'date-fns/locale/ru';
import kk from 'date-fns/locale/kk';
import i18n from '../../i18n';
import moment from 'moment';
import classNames from 'classnames';
import Calendar from '../../assets/images/calendar.png';
import { addDays, subDays } from 'date-fns';
import InputMask from 'react-input-mask';
import i18next from 'i18next';

registerLocale('ru', ru);
registerLocale('kz', kk);

const ExampleCustomInput = forwardRef(
  ({ onBlur, onKeyDown, value, onClick, onChange, name, disabled }: any, ref) => {
    return (
      <InputMask
        mask="99.99.9999-99.99.9999"
        value={value}
        className="example-custom-input"
        onClick={onClick}
        onChange={onChange}
        onKeyDown={onKeyDown}
        ref={ref}
        name={name}
        maskChar="_"
        placeholder={i18next.t('dateFromTo')}
        onBlur={onBlur}
        disabled={disabled}
      />
    );
  }
);

const DatePickerMultiComponent = (props) => {
  const { name, selected, readOnly, className, isNotFilled, clearError, ...rest } = props;
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const ref = useRef();
  const minDate = moment().toDate(); 
const maxDate = moment('31.12.2040', 'DD.MM.YYYY').toDate(); 

  useEffect(() => {
    if (selected) {
      const sel = selected.split('-');
      const startDate =
        sel[0] && sel[0] !== 'null'
          ? moment(sel[0], 'DD.MM.yyyy').toDate()
          : null;
      const endDate =
        sel[1] && sel[1] !== 'null'
          ? moment(sel[1], 'DD.MM.yyyy').toDate()
          : null;
      setStartDate(startDate);
      setEndDate(endDate);
    }
  }, [selected]);

  const formatDate = (date) => {
    if (date) {
      let year = date.getFullYear();
      let month = (1 + date.getMonth()).toString().padStart(2, '0');
      let day = date.getDate().toString().padStart(2, '0');

      return day + '.' + month + '.' + year;
    }

    return null;
  };

  const handleChange = (dates) => {
    clearError && clearError('projectDuration');
    const [start, end] = dates;
    setStartDate(start);
    setEndDate(end);
    if (start && end) {
      props.handleChangeDate(
        formatDate(start) + '-' + formatDate(end),
        props.idx
      );
    } else {
      props.handleChangeDate('', props.idx);
    }
  };

  const handleClick = () => {
    setStartDate(null);
    setEndDate(null);
  };

  const changeDate = (event) => {
    const [start, end] = event ? event.split('-') : [null, null];
    if (start.includes('_') || end.includes('_')) {
      setStartDate(null);
      setEndDate(null);
      return;
    }
    const formattedStart = moment(start, 'DD.MM.YYYY');
    const formattedEnd = moment(end, 'DD.MM.YYYY');
    if (formattedStart.isValid() || formattedEnd.isValid()) {
      setStartDate(formattedStart.toDate());
      setEndDate(formattedEnd.toDate());
      props.handleChangeDate(start + '-' + end, props.idx);
    } else {
      setStartDate(null);
      setEndDate(null);
    }
  };

  const onBlur = (event) => {
    if (event?.target?.value) {
      changeDate(event.target.value);
    }
  };

  const handleKeyDown = (event) => {
    if (event.key === 'Enter' && event?.target?.value) {
      changeDate(event.target.value);
    }
  };

  return (
    <div
      className={isNotFilled ? 'calendar project-duration__error' : 'calendar'}
    >
      <DatePicker
        wrapperClassName="calendar__datePicker"
        name={name}
        autoComplete="none"
        locale={i18n.language ? i18n.language : 'ru'}
        className={classNames(
          'default-input expense-plan__input expense-plan__calendar',
          className
        )}
        startDate={startDate}
        endDate={endDate}
        timeIntervals={30}
        showMonthDropdown
        showYearDropdown
        peekNextMonth
        dropdownMode="select"
        isClearable={!readOnly}
        // excludeDateIntervals={[
        //   {
        //     start: subDays(new Date(), 1000000),
        //     end: addDays(new Date(), -1),
        //   },
        // ]}
        {...rest}
        dateFormat="dd.MM.yyyy"
        selected={startDate}
        onChange={handleChange}
        selectsRange
        onClick={handleClick}
        onKeyDown={handleKeyDown}
        onBlur={onBlur}
        customInput={<ExampleCustomInput disabled={readOnly} name={name} ref={ref} />}
        minDate={minDate}
        maxDate={maxDate}
        disabled={readOnly}
      />
      <img
        className="calendar__icon"
        src={Calendar}
        alt="Calendar"
        width={20}
        height={20}
      />
    </div>
  );
};

export default DatePickerMultiComponent;
