import * as React from 'react';
import { Trans } from 'react-i18next';

type Props = {
  close: () => void;
};

export default function PolicyText({ close }: Props) {
  return (
    <div className="modal policy-modal">
      <div className="btn-policy" onClick={close} />
      <div className="modal__header modal-title modal__header--policy">
        <h1>
          <Trans>policyConf</Trans>
        </h1>
      </div>
      <div className="policy-text">
        <strong>ПОЛИТИКА КОНФИДЕНЦИАЛЬНОСТИ ПЕРСОНАЛЬНЫХ ДАННЫХ ПОРТАЛА ПРОЕКТОВ И ПРОГРАММ SDF-PORTAL.KZ</strong>

        <div>1. Настоящая Политика конфиденциальности персональных данных (далее – Политика конфиденциальности)
          портала проектов и программ sdf-portal.kz (далее – Портал) действует в отношении всей информации,
          которую корпоративный фонд «Фонд социального развития» (далее – Фонд) может получить о Пользователе во
          время использования Портала.
        </div>
        <p>I. ОПРЕДЕЛЕНИЕ ТЕРМИНОВ</p>

        <div>2. В настоящей Политике конфиденциальности используются следующие термины:</div>
        <div>1) Администрация Портала Фонда (далее – Администрация Портала) – работники, ответственные за
          управление
          Порталом, действующие от имени Фонда, которые организуют и (или) осуществляют обработку персональных
          данных, а также определяют цели обработки персональных данных, состав персональных данных, подлежащих
          обработке, действия (операции), совершаемые с персональными данными.
        </div>
        <div> 2) Пользователь – физическое лицо или представитель юридического лица, филиала, представительства,
          зарегистрировавшийся на Портале с ролями, предусмотренными Порталом и/или пользующийся Порталом.
        </div>
        <div>3) ЭЦП – электронно-цифровая подпись, выданная Национальным Удостоверяющим центром Республики
          Казахстан,
          используемая для подписания и верификации, юридической значимости действий Пользователя с Порталом.
        </div>
        <div>4) Регистрация на Портале – процедура аутентификации Пользователя и создания учетной записи
          Пользователя
          на портале посредством сертификата, выданного национальным удостоверяющим центром Республики Казахстан.
        </div>
        <div>5) Персональные данные – любые относящиеся к Пользователю сведения, зафиксированные на электронном,
          бумажном и (или) ином материальном носителе, которые могут содержать (включая, но не ограничиваясь):
          <ul>
            <li>сведения, необходимые для регистрации и авторизации на Портале;</li>
            <li>информация об изменении и(или) дополнении вышеуказанных данных.</li>
          </ul>

        </div>
        <div>6) Обработка персональных данных – любое действие (операция) или совокупность действий (операций),
          совершаемых с использованием средств автоматизации или без использования таких средств с персональными
          данными, включая сбор, запись, систематизацию, накопление, хранение, уточнение (обновление, изменение),
          извлечение, использование, передачу (распространение, предоставление, доступ), обезличивание,
          блокирование, удаление, уничтожение персональных данных.
        </div>
        <div>7) Cookies – небольшой фрагмент данных, отправленный веб-сервером и хранимый на компьютере
          пользователя,
          который веб-клиент или веб-браузер каждый раз пересылает веб-серверу в HTTP-запросе при попытке открыть
          страницу соответствующего сайта.
        </div>

        <p>II. ОБЩИЕ ПОЛОЖЕНИЯ</p>

        <div>3. Использование Пользователем Портала означает согласие с настоящей Политикой конфиденциальности и
          условиями обработки персональных данных Пользователя.
        </div>
        <div>4. В случае несогласия с условиями Политики конфиденциальности Пользователь не получает доступ к
          порталу.
        </div>
        <div>5. Настоящая Политика конфиденциальности применяется только к Порталу. Фонд не контролирует и не
          несет
          ответственность за сайты третьих лиц, на которые Пользователь может перейти по ссылкам, доступным на
          Портале.
        </div>

        <p>III. ПРЕДМЕТ ПОЛИТИКИ КОНФИДЕНЦИАЛЬНОСТИ</p>

        <div>6. Настоящая Политика конфиденциальности устанавливает обязательства Фонда по неразглашению и
          обеспечению
          режима защиты конфиденциальности персональных данных, которые Пользователь предоставляет Администрации
          Портала при регистрации на Портале.
        </div>
        <div>7. Персональные данные, разрешенные к обработке в рамках настоящей Политики конфиденциальности,
          предоставляются Пользователем путём заполнения регистрационной формы на Портале.
        </div>
        <div>8. Фонд защищает Персональные данные, которые автоматически передаются в процессе просмотра рекламных
          блоков и при посещении страниц, на которых установлен статистический скрипт системы:
        </div>
        <div>1) IP адрес;</div>
        <div>2) информация из cookies;</div>
        <div>3) информация о браузере (или иной программе, которая осуществляет доступ к показу рекламы);</div>
        <div>4) время доступа;</div>
        <div>5) адрес страницы, на которой расположен рекламный блок;</div>
        <div>6) реферер (адрес предыдущей страницы).</div>
        <div>9. Отключение cookies может повлечь невозможность доступа к Порталу, требующим авторизации.</div>
        <div>10. Фонд осуществляет сбор статистики об IP-адресах своих посетителей. Данная информация используется
          с
          целью выявления и решения технических проблем.
        </div>
        <div>11. Пользователю запрещается указывать на Портале персональные данные третьих лиц (за исключением
          условий
          представления интересов этих лиц, имея документальное подтверждение третьих лиц на осуществление таких
          действий).
        </div>

        <p>IV. ЦЕЛИ СБОРА ПЕРСОНАЛЬНОЙ ИНФОРМАЦИИ ПОЛЬЗОВАТЕЛЯ</p>

        <div>12. Персональные данные Пользователя Фонд может использовать в целях:</div>
        <div>1) идентификации Пользователя;</div>
        <div>2) установления с Пользователем обратной связи, включая направление уведомлений, запросов, касающихся
          использования Портала, оказания услуг, обработка запросов и заявок от Пользователя;
        </div>
        <div>3) определения места нахождения Пользователя для обеспечения безопасности, предотвращения
          мошенничества в
          установленном законодательстве порядке;
        </div>
        <div>4) подтверждения достоверности и полноты персональных данных, предоставленных Пользователем;</div>
        <div>5) уведомления Пользователя о состоянии заявки;</div>
        <div>6) предоставления Пользователю эффективной клиентской и технической поддержки при возникновении
          проблем,
          связанных с использованием Портала.
        </div>

        <p>V. ОБЯЗАННОСТИ СТОРОН</p>

        <div>13. Пользователь обязан:</div>
        <div>1) предоставить достоверную информацию о персональных данных, необходимую для пользования Порталом;
        </div>
        <div>2) обновить, дополнить предоставленную информацию о персональных данных в случае изменения данной
          информации.
        </div>
        <div>3) быть ознакомленным с настоящей Политикой;</div>
        <div>4) осуществлять иные обязанности в сфере персональных данных, когда такие обязанности возникают у
          субъекта
          персональных данных в ходе обработки его персональных данных.
        </div>
        <div>14. Фонд обязан:</div>
        <div>1) использовать полученную информацию исключительно для целей, указанных в разделе IV настоящей
          Политики
          конфиденциальности;
        </div>
        <div>2) принимать меры предосторожности для защиты конфиденциальности персональных данных Пользователя
          согласно
          порядку, обычно используемого для защиты такого рода информации в существующем деловом обороте;
        </div>
        <div>3) осуществить блокирование персональных данных, относящихся к соответствующему Пользователю, с
          момента
          обращения или запроса Пользователя или его законного представителя либо уполномоченного органа по защите
          прав субъектов персональных данных на период проверки, в случае выявления недостоверных персональных
          данных или неправомерных действий.
        </div>

        <p>VI. ОТВЕТСТВЕННОСТЬ СТОРОН</p>

        <div>15. В случае утраты или разглашения конфиденциальной информации Фонд не несет ответственность, если
          данная
          конфиденциальная информация:
        </div>
        <div>1) стала публичным достоянием до ее утраты или разглашения;</div>
        <div>2) была получена третьими лицами или предоставлена Пользователем третьим лицам самостоятельно до
          момента
          ее получения Фондом;
        </div>
        <div>3) была разглашена с согласия Пользователя.</div>
        <div>16. Пользователь несет ответственность за предоставленную информацию в соответствии с
          законодательством
          Республики Казахстан.
        </div>

        <p>VII. РАЗРЕШЕНИЕ СПОРОВ</p>

        <div>17. До обращения в суд с иском по спорам, возникающим из отношений между Пользователем Портала и
          Фондом,
          обязательным является предъявление претензии (письменного предложения о добровольном урегулировании
          спора).
        </div>
        <div>18. Получатель претензии в течение 30 календарных дней со дня получения претензии, письменно
          уведомляет
          заявителя претензии о результатах рассмотрения претензии.
        </div>
        <div>19. При недостижении соглашения спор может быть передан на рассмотрение в суд по подсудности, в
          соответствии с действующим законодательством Республики Казахстан по месту нахождения Фонда.
        </div>
        <div>20. К настоящей Политике конфиденциальности и отношениям между Пользователем и Фондом применяется
          действующее законодательство Республики Казахстан.
        </div>
        <div>21. Вопросы, не урегулированные настоящей Политикой, регулируются в соответствии с действующими
          законодательствами Республики Казахстан
        </div>

        <p>VIII. ДОПОЛНИТЕЛЬНЫЕ УСЛОВИЯ</p>

        <div>22. Фонд вправе вносить изменения в настоящую Политику конфиденциальности в одностороннем порядке без
          согласия Пользователя. Все изменения вступают в силу с момента ее размещения на Портале.
        </div>
        <div>23. В случае если Фондом были внесены любые изменения в Политику конфиденциальности, с которыми
          Пользователь не согласен, он обязан прекратить использование Портала. Факт непрекращения использования
          Портала является подтверждением согласия и принятия Пользователем соответствующей редакцией Политики
          конфиденциальности.
        </div>
        <div>24. Все предложения или вопросы по настоящей Политике конфиденциальности следует сообщать по адресу
          <strong> sdf@nu.edu.kz</strong> c указанием темы «Политика конфиденциальности».
        </div>
        <div>25. Действующая Политика конфиденциальности размещена на странице Портала.</div>
      </div>
    </div>
  );
};