import * as React from 'react';
import Header from '../../components/Header';
import './MainLayout.css';
import { inject } from 'mobx-react';
import { withRouter } from 'react-router-dom';
import IdleTimer from 'react-idle-timer';
import Footer from '../../components/Footer/Footer';

const MainLayout = inject('userState')((props) => {
  const timeout = 1000 * 60 * 15;
  let idleTimer = null;

  const onAction = () => {};

  const onActive = () => {};

  const onIdle = () => {
    idleTimer.reset();
    props.userState.logout().then(() => {
      props.history.push('/');
    });
  };

  return (
    <>
      <IdleTimer
        ref={(ref) => (idleTimer = ref)}
        element={document}
        onActive={onActive}
        onIdle={onIdle}
        onAction={onAction}
        debounce={250}
        timeout={timeout}
      />

      <Header />

      <div className="page-wrapper page-wrapper__mob">
        <div className="container">{props.children}</div>
      </div>

      <Footer />
    </>
  );
});

export default withRouter(MainLayout);
