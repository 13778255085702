import React, { useContext } from "react";
import { Trans } from "react-i18next";
import { ApplicationFormContext } from "../ApplicationFormProvider";
import Popup from "reactjs-popup";

type Props = {
  loading: boolean;
  isECPsigning: boolean;
};

const ResetFormButton: React.FC<Props> = ({ loading, isECPsigning }) => {
  const { applicationForm, clearApplicationForm } = useContext(
    ApplicationFormContext
  );

  return (
    <Popup
      trigger={
        <button className="btn btn-resetAll" disabled={loading || isECPsigning}>
          <Trans>RESETALL</Trans>
        </button>
      }
      modal
    >
      {(close) => (
        <div className="modal">
          <div className="modal__header modal-title">
            <h1>
              <Trans>Confirmation</Trans>
            </h1>
          </div>
          <div className="modal__content" style={{ textAlign: "center" }}>
            <Trans>ResetFormConfirmation</Trans>
            <br />
            <Trans>fieldLoose</Trans>
          </div>
          <div className="btn__actions">
            <button
              className="button-input btn btn-success"
              onClick={() => {
                clearApplicationForm({ programs: applicationForm.programs });
                close();
              }}
            >
              <Trans>Yes</Trans>
            </button>
            <button
              className="button-input btn btn-light btn-popup"
              onClick={() => {
                close();
              }}
            >
              <Trans>Cancel</Trans>
            </button>
          </div>
        </div>
      )}
    </Popup>
  );
};

export default ResetFormButton;
