export default function accessFormByApplicantType(applicantType, contractCode = '') {
  if (contractCode === 'SP') {
    switch (applicantType) {
      case 'STUDENT':
        return true;
      case 'EMPLOYEE':
        return true;
      case 'ORGANIZATION':
        return true;
      case 'GRADUATE_NU':
        return true;
      default:
        return false;
    }
  }

  if (contractCode === 'SE') {
    switch (applicantType) {
      case 'STUDENT':
        return true;
      case 'GRADUATE_NU':
        return true;
      case 'ORGANIZATION':
        return true;
      default:
        return false;
    }
  }
}
