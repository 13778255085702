import * as React from 'react';
import { Route } from 'react-router-dom';
import { inject } from 'mobx-react';
import MainLayout from './layouts/MainLayout';

const AppRoute = inject('userState')((props) => {
  const { component: Component, layout: Layout = MainLayout, ...rest } = props;

  return (
    <Route
      {...rest}
      render={(props) => (
        <Layout>
          <Component {...props} />
        </Layout>
      )}
    />
  );
});

export default AppRoute;
