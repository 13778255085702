import { enGB, kk, ru } from 'date-fns/locale';

const localeChanger = (lang) => {
  switch (lang) {
    case 'ru':
      return ru;
    case 'kz':
      return kk;
    case 'en':
      return enGB;
    // default:
    //     return ru;
  }
};

export default localeChanger;
