import React, { useState, useEffect } from "react";
import { Trans, useTranslation } from "react-i18next";
import moment from "moment-timezone";
import { cubaREST } from '../../cubaREST';
import MiniLoad from '../MiniLoad/MiniLoad';

export const TimerEndCurrentStep = () => {
  const [remainingTime, setRemainingTime] = useState(<div/>);
  const [isLoading, setLoading] = useState(true);
  const [apiData, setApiData] = useState(null);
  const { t } = useTranslation();

  const calculateRemainingTime = () => {
    if (apiData && apiData.dateEndCurrentStep) {
      const targetDateTime = moment.tz(
        apiData.dateEndCurrentStep,
        "Asia/Almaty",
      );

      const currentDateTime = moment();

      if (targetDateTime > currentDateTime) {
        const diff = moment.duration(targetDateTime.diff(currentDateTime));

        setLoading(false);
        setRemainingTime(
          <div>
              <span style={{ color: "#007e6d", fontWeight: 700 }}>
                {Math.floor(diff.asDays())} {t("timer_day")}.
              </span>{" "}
            <span style={{ color: "#007e6d", fontWeight: 700 }}>
                {diff.hours()} {t("timer_hour")}.
              </span>{" "}
            <span style={{ color: "#007e6d", fontWeight: 700 }}>
                {diff.minutes()} {t("timer_minute")}.
              </span>{" "}
            <span style={{ color: "#007e6d", fontWeight: 700 }}>
                {diff.seconds()} {t("timer_second")}.
              </span>{" "}
          </div>,
        );
      } else {
        setRemainingTime(null);
      }
    }
  };

  const fetchData = async () => {
    try {
      setLoading(true);
      const response = await cubaREST
        .invokeService('fsr_CompetitionService', 'getCurrentStepDeadline', null, { method: "GET" });
      if (typeof response === "string") {
        const data = JSON.parse(response);
        if (data.status === "success" && data.dateEndCurrentStep) {
          setApiData(data);
        }
      }
    } catch (error) {
      setLoading(false);
      console.error("Fetch API error:", error);
    }
  };
  useEffect(() => {
    fetchData();
  }, []);

  useEffect(() => {
    const interval = setInterval(() => {
      calculateRemainingTime();
    }, 1000);

    return () => clearInterval(interval);
  }, [apiData, t]);

  if (!apiData) {
    return null;
  }

  if (remainingTime === null ) {
    return <div className="textDateTimer">
      <Trans>SEisOver</Trans>
    </div>
  }
  return (
    <>
      {!isLoading && remainingTime &&
        <div className="date_timer">
          <Trans>DateSEend</Trans>
          <span style={{ marginRight: "5px" }}>:</span>
          <span className="timer__number">{remainingTime}</span>
          <span style={{ marginLeft: "5px" }}>(GMT+6)</span>
        </div>
      }
    </>
  );
};
