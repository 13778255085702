import React, { ChangeEvent, useContext, useEffect, useState } from 'react';
import classNames from 'classnames';
import { Trans } from 'react-i18next';
import { ApplicationFormContext } from 'components/ApplicationFormProvider';
import i18next from "i18next";
interface IProps {
  defaultValue: string;
  name: string;
  onChange: (event: ChangeEvent<HTMLTextAreaElement>) => void;
  className?: string;
  readOnly?: boolean;
}

const Textarea = ({ defaultValue, onChange, name, className }: IProps) => {
  const { readOnly, getError, clearError } = useContext(ApplicationFormContext);
  const [value, setValue] = useState(defaultValue);

  useEffect(() => {
    setValue(defaultValue);
  }, [defaultValue]);

  const handleChange = (event) => {
    clearError && clearError(name);

    if (event.target.value.length > 2000) {
      return;
      // event.target.value = event.target.value.slice(0, 2000);
    }

    setValue(event.target.value);

    if (onChange) {
      onChange(event);
    }
  };

  return (
    <div className="wrap-area">
      <textarea
        className={classNames(
          'text-area',
          getError(name) && 'input__error',
          className
        )}
        value={value}
        name={name}
        onChange={handleChange}
        readOnly={readOnly}
        placeholder={i18next.t('placeHolder')}
      />

      <p className={'limit-label'}>
        <Trans>textAreaCounter</Trans>:{' '}
        {value?.length > 2000 ? (
          <span style={{ color: '#dc3545', fontSize: '12px' }}>
            <Trans>extraSymbols</Trans>
          </span>
        ) : (
          2000 - value?.length || 0
        )}
        /2000{' '}
      </p>
    </div>
  );
};

export default Textarea;
