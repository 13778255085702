import { initializeApp } from '@cuba-platform/rest';
const axios = require('axios').default;

export const cubaREST = initializeApp({
  name: 'fsr',
  apiUrl: process.env.REACT_APP_CUBA_APP_URL,
  storage: window.localStorage,
});

export const myCuba = axios.create({
  baseURL: process.env.REACT_APP_CUBA_APP_URL_V2,
  timeout: 10000,
  headers: {
    'Content-Type': 'application/json;charset=UTF-8',
    Authorization: 'Bearer ' + localStorage.getItem('fsr_cubaAccessToken'),
  },
});
