import React from "react";
import { Switch } from "react-router-dom";
import "./assets/css/main.css";
import "./App.css";
import AuthLayout from "./layouts/AuthLayout";
import HeaderLayout from "./layouts/HeaderLayout";
import ProtectedRoute from "./ProtectedRoute";
import AppRoute from "./AppRoute";
import MainPage from "./pages/MainPage";
import Competitions from "./pages/Competitions";
import NotFound from "./pages/NotFound";
import SignIn from "./pages/SignIn";
import SignUp from "./pages/SignUp";
import SignUpConfirm from "./pages/SignUpConfirm";
import CompetitionDetail from "./pages/CompetitionDetail";
import ApplicationDetail from "./pages/ApplicationDetail";
import Cabinet from "./pages/Cabinet";
import ContractDetail from "./pages/ContractDetail";
import Register from "./pages/Register";
import ForgotPassword from "./pages/ForgotPassword";
import ForgotPasswordConfirm from "./pages/ForgotPasswordConfirm";
import RestorePassword from "./pages/RestorePassword";
import ChangeEmail from "./pages/ChangeEmail";
import FAQ from "./pages/FAQ";
import { toast, ToastContainer } from "react-toastify";
import ApplicationsPage from "pages/ApplicationsPage/ApplicationsPage";
import UserGuide from "pages/UserGuide/UserGuide";
import ChangePasswordPage from "pages/ChangePasswordPage/ChangePasswordPage";
// test

const App: React.FC = () => {
  return (
    <>
      <Switch>
        <ProtectedRoute path="/" component={MainPage} exact />
        <ProtectedRoute
          path="/competition/:id"
          component={CompetitionDetail}
          exact
        />
        <ProtectedRoute
          path="/application/:id/:compId"
          component={ApplicationDetail}
          exact
        />
        <ProtectedRoute path="/competitions" component={Competitions} />
        <ProtectedRoute path="/our-projects" component={MainPage} />
        <ProtectedRoute path="/cabinet" component={Cabinet} />
        <ProtectedRoute path="/applications" component={ApplicationsPage} />
        <ProtectedRoute
          path="/change-password"
          component={ChangePasswordPage}
        />

        {/* TODO /contract/:id is not used*/}
        <ProtectedRoute path="/contract/:id" component={ContractDetail} />
        <AppRoute path="/user-guide" component={UserGuide} />
        <AppRoute path="/sign-in" component={SignIn} layout={AuthLayout} />
        <AppRoute
          path="/sign-up"
          component={SignUp}
          exact
          layout={AuthLayout}
        />
        <AppRoute
          path="/sign-up/confirmation"
          component={SignUpConfirm}
          layout={AuthLayout}
        />
        <AppRoute
          path="/registration/:hash"
          component={Register}
          exact
          layout={AuthLayout}
        />
        <AppRoute
          path="/restore-password/:hash"
          component={RestorePassword}
          exact
          layout={AuthLayout}
        />
        <AppRoute
          path="/change-email/:code"
          component={ChangeEmail}
          exact
          layout={AuthLayout}
        />
        <AppRoute
          path="/forgot-password/confirmation"
          component={ForgotPasswordConfirm}
          exact
          layout={AuthLayout}
        />
        <AppRoute
          path="/forgot-password"
          component={ForgotPassword}
          exact
          layout={AuthLayout}
        />
        <AppRoute path="/faq" component={FAQ} />
        <AppRoute component={SignIn} layout={AuthLayout} />
      </Switch>
      <ToastContainer />
    </>
  );
};

export default App;
