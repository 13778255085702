import * as React from "react";
import "./Competition.css";
import { Link } from "react-router-dom";
import { format, differenceInCalendarDays } from "date-fns";
import { ru, enGB } from "date-fns/locale";
import { Trans } from "react-i18next";
import { observer } from "mobx-react";
import { AppStateObserver, injectAppState } from "../../stores";
import i18next from "i18next";
import localeChanger from "../../helpers/localeChanger";
import i18n from "i18next";
import Notify from "../../helpers/Notifier";

export interface CompetitionProps {
  name: string;
  dateStart: string;
  dateEnd: string;
  id: string;
  status: string;
  info: string;
  isMainPage?: boolean;
  anketaOk: any;
}

export interface CompetitionState {}

@injectAppState
@observer
class Competition extends React.Component<
  AppStateObserver & CompetitionProps,
  CompetitionState
> {
  public static defaultProps = {
    isMainPage: false,
  };



  handleNotActive = () => {
    Notify.pushErrorMessage(i18next.t("fillOut"), );
  };

  render() {
    const { name, dateStart, dateEnd, id, status, info } = this.props;
    const { language } = this.props.appState.userStore;
    const localeDate = localeChanger(i18n.language ?? "ru");
    const competitionLasts = dateStart
      ? differenceInCalendarDays(new Date(dateStart), new Date())
      : null;
    let competitionRightComponent;
    let styleComp = this.stylePanel();

    switch (status) {
      case "COLLECTION_OF_APPLICATION":
        competitionRightComponent = (
          <div className="competition__right">
            {this.props.anketaOk ? (
              <Link
                className="button-input btn btn-success btn-mob"
                to={{ pathname: `/competition/${id}` }}
              >
                <Trans>Apply</Trans>
              </Link>
            ) : (
              <p className="red-error">
                <Trans>fillOutWithLink</Trans>{" "}
                <Link to="/cabinet/personal" className="red-error">
                  <Trans>fillOutCabinet</Trans>
                </Link>
                {"."}
              </p>
            )}
            <p className="competition__last">
              {competitionLasts > 0 ? `Осталось ${competitionLasts} дней` : ""}
            </p>
          </div>
        );
        break;
      case "COMPLETED":
        competitionRightComponent = (
          <div className="competition__right">
            <Link
              className="button-input btn btn-save"
              to={`/competition/${id}`}
            >
              <Trans>ViewResults</Trans>
            </Link>
          </div>
        );
        break;
      default:
        competitionRightComponent = <div className="competitionRight" />;
        break;
    }

    return (
      <div className="application-view portal__card__home">
        <h3 className="competition__name">{name}</h3>
        <p className="comp__description">{info}</p>
        <div className="application-view__content">
          <div className="competition__info">
            <p className="portal__text">
              <Trans>StartDateApplication</Trans>
            </p>
            <p className="portal__pi__text">
              <span>
                {dateStart
                  ? format(Date.parse(dateStart), "dd MMMM u", {
                      locale: localeDate,
                    })
                  : "Не назначена"}
              </span>
            </p>

            <p className="portal__text">
              <Trans>EndDateApplication</Trans>
            </p>
            <p className="portal__pi__text">
              <span>
                {dateEnd
                  ? format(Date.parse(dateEnd), "dd MMMM u", {
                      locale: localeDate,
                    })
                  : "Не назначена"}
              </span>
            </p>

            <p className="portal__text">
              <Trans>CompetitionType</Trans>
            </p>
            <p className="portal__pi__text">{name}</p>

            <p className="portal__text">
              <Trans>Status</Trans>
            </p>
            <p className="portal__pi__text">
              <Trans>{status}</Trans>
            </p>
          </div>
          {competitionRightComponent}
        </div>
      </div>
    );
  }

  stylePanel() {
    let style;
    if (
      this.props.isMainPage &&
      this.props.status === "COLLECTION_OF_APPLICATION"
    ) {
      style = {
        background: "#FDF5F1",
        borderBottom: "none",
      };
    } else if (this.props.isMainPage && this.props.status === "COMPLETED") {
      style = {
        background: "#f0f4fb",
        borderBottom: "none",
      };
    }

    return style;
  }

  styleDate() {
    let style;
    if (
      this.props.isMainPage &&
      this.props.status === "COLLECTION_OF_APPLICATION"
    ) {
      style = {
        background: "#FFC000",
        padding: "6px 4px",
      };
    }

    return style;
  }
}

export default Competition;
