import * as React from "react";
import { observer } from "mobx-react";
import { AppStateObserver, injectAppState } from "../../stores";
import { Link, RouteComponentProps } from "react-router-dom";
import { toast } from "react-toastify";
import { Trans } from "react-i18next";
import "react-toastify/dist/ReactToastify.css";
import "./ChangePassword.css";
import i18next from "i18next";
import eyeIcon from "../../assets/icons/eye.svg";
import Notify from "../../helpers/Notifier";

export interface ChangePasswordProps {}

export interface ChangePasswordState {}
@injectAppState
@observer
class ChangePassword extends React.Component<
  AppStateObserver & RouteComponentProps,
  ChangePasswordProps,
  ChangePasswordState
> {
  state = {
    login: "",
    oldPw: "",
    newPw: "",
    newPwConfirm: "",
    oldPasswordError: "",
    passwordError: "",
    passwordConfirmError: "",
    error: "",
    showOldPassword: false,
    showNewPassword: false,
    showPasswordConfirm: false,
  };
  handlePasswordConfirmChange = (event) => {
    this.setState({ newPwConfirm: event.target.value });
  };
  handleOldPasswordChange = (event) => {
    this.setState({ oldPw: event.target.value });
  };
  handleNewPasswordChange = (event) => {
    this.setState({ newPw: event.target.value });
  };

  handleOnSubmit = (event) => {
    event.preventDefault();
    const { login, oldPw, newPw, newPwConfirm } = this.state;
    if (this.validate(oldPw, newPw, newPwConfirm)) {
      this.props.appState.userStore
        .changePassword({
          login: login,
          oldPw: oldPw,
          newPw: newPw,
        })
        .then((res) => {
          if (JSON.parse(res).status === "SUCCESS") {
            Notify.pushSuccessMessage(i18next.t("Success"));
          } else {
            Notify.pushErrorMessage("Неверный пароль");
          }
        });
    }
  };

  validatePasswordReqs(password) {
    const regex =
      /^(?=.*[A-Z])(?=.*[_!@#-$%^&*])(?=.*\d)[a-zA-Z\d_!@#-$%^&*]{8,}$/;
    return regex.test(password);
  }

  validate = (oldPassword, password1, password2) => {
    if (oldPassword === "") {
      this.setState({ oldPasswordError: i18next.t("EnterTheCurrentPassword") });
    } else this.setState({ oldPasswordError: "" });
    if (password1 === "") {
      this.setState({ passwordError: i18next.t("EnterNewPassword") });
    } else this.setState({ passwordError: "" });
    if (password2 === "") {
      this.setState({
        passwordConfirmError: i18next.t("passwordConfirmError"),
      });
    } else this.setState({ passwordConfirmError: "" });
    if (!this.validatePasswordReqs(password1)) {
      this.setState({
        passwordError: "regex",
      });
      return false;
    } else this.setState({ passwordError: "" });
    if (password1 && password2 && oldPassword) {
      if (password1 === password2) {
        this.setState({ error: "" });
        return true;
      } else {
        this.setState({ error: i18next.t("PasswordsMatch") });
        return false;
      }
    } else {
      return false;
    }
  };

  componentDidMount() {
    const login = localStorage.getItem("applicant");
    this.setState({ login });

    i18next.on("languageChanged", this.updateTranslations);
  }

  updateTranslations = () => {
    this.setState({
      oldPasswordError: "",
      passwordError: "",
      passwordConfirmError: "",
      error: "",
    });
  };

  constructor(props) {
    super(props);
  }
  render() {
    const {
      login,
      oldPw,
      newPw,
      newPwConfirm,
      oldPasswordError,
      passwordError,
      passwordConfirmError,
      error,
      showNewPassword,
      showOldPassword,
      showPasswordConfirm,
    } = this.state;
    return (
      <React.Fragment>
        <div className="password-change__container">
          <div className="sign-in__login sign-in__login--password mb-3">
            <label htmlFor="" className="input__header">
              <Trans>Username</Trans> <span style={{ color: "red" }}>*</span>
            </label>
            <input
              type="text"
              className="input-reset-password"
              defaultValue={login}
              readOnly
            />
          </div>
          <div
            className="sign-in__login sign-in__login--password"
            style={{ marginBottom: "20px" }}
          >
            <label htmlFor="" className="input__header">
              <Trans>OldPassword</Trans> <span style={{ color: "red" }}>*</span>
            </label>
            <div className="input__wrapper">
              <input
                className="input-reset-password"
                type={showOldPassword ? "text" : "password"}
                placeholder={i18next.t("EnterTheCurrentPassword")}
                onChange={(event) => this.handleOldPasswordChange(event)}
                // placeholder="••••••••••"
              />
              <img
                className="input__password-icon"
                onClick={() =>
                  this.setState({ showOldPassword: !showOldPassword })
                }
                src={eyeIcon}
                alt="eye icon"
              />
            </div>
          </div>
          <p className="password-change__error">{oldPasswordError}</p>
          <div
            className="sign-in__login sign-in__login--password"
            style={{ marginBottom: "20px" }}
          >
            <label htmlFor="" className="input__header">
              <Trans>NewPassword</Trans> <span style={{ color: "red" }}>*</span>
            </label>
            <div className="input__wrapper">
              <input
                className="input-reset-password"
                type={showNewPassword ? "text" : "password"}
                placeholder={i18next.t("EnterNewPassword")}
                onChange={(event) => this.handleNewPasswordChange(event)}
              />
              <img
                className="input__password-icon"
                onClick={() =>
                  this.setState({ showNewPassword: !showNewPassword })
                }
                src={eyeIcon}
                alt="eye icon"
              />
            </div>
          </div>
          <p className="password-change__error">
            {passwordError === "regex"
              ? i18next.t("passwordValidation.notValidByReqs", {
                  ns: "errors",
                })
              : passwordError}
          </p>
          <div
            className="sign-in__login sign-in__login--password position-relative"
            style={{ marginBottom: "20px" }}
          >
            <label htmlFor="" className="input__header">
              <Trans>ConfirmPassword</Trans>{" "}
              <span style={{ color: "red" }}>*</span>
            </label>
            <div className="input__wrapper">
              <input
                className="input-reset-password"
                type={showPasswordConfirm ? "text" : "password"}
                placeholder={i18next.t("RepeatNewPassword")}
                onChange={(event) => this.handlePasswordConfirmChange(event)}
              />
              <img
                className="input__password-icon"
                onClick={() =>
                  this.setState({ showPasswordConfirm: !showPasswordConfirm })
                }
                src={eyeIcon}
                alt="eye icon"
              />
            </div>
          </div>
          <p className="password-change__error">{passwordConfirmError}</p>
          <p className="password-change__error">{error}</p>
          <button
            className="button-input btn btn-success password-save-btn"
            onClick={(event) => {
              this.handleOnSubmit(event);
            }}
          >
            <Trans>Save</Trans>
          </button>
        </div>
      </React.Fragment>
    );
  }
}

export default ChangePassword;
