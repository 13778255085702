import React from 'react';
import {Trans} from 'react-i18next';
import './styles.css';
import convertToMasked from '../../../helpers/pure/convertToMasked';
import renderHeader from './title';
import {ApplicationFormContext} from '../../ApplicationFormProvider';
import {sectionNumber} from '../../ApplicationFormProvider/sectionNumber';
import classNames from 'classnames';
import wrongText from '../../../helpers/wrongText';
import i18next from 'i18next';
import Load from '../../Load';
import {injectAppState} from '../../../stores';
import FileComponent from '../../FileComponent';
import validateFileSize from '../../../helpers/validateFileSize';
import {toast} from 'react-toastify';
import MiniLoad from '../../MiniLoad/MiniLoad';
import Notify from "../../../helpers/Notifier";

const financialRevenueInitial = [
  {
    year: '2021',
    services: '',
  },
  {
    year: '2022',
    services: '',
  },
];
const financialExpensesInitial = [
  {
    year: '2021',
    taxExpenses: '',
    marketing: '',
    directCosts: '',
  },
  {
    year: '2022',
    taxExpenses: '',
    marketing: '',
    directCosts: '',
  },
];

@injectAppState
class FinancialModel extends React.Component<any, any> {
  static contextType = ApplicationFormContext;
  state = {
    financialExpenses:
      this.context.applicationForm.organizationPotential.financialExpenses
        ?.length > 0
        ? this.context.applicationForm.organizationPotential.financialExpenses
        : financialExpensesInitial,
    financialRevenue:
      this.context.applicationForm.organizationPotential.financialRevenue
        ?.length > 0
        ? this.context.applicationForm.organizationPotential.financialRevenue
        : financialRevenueInitial,
    fileLoading: false,
  };

  calculateSum = (a, b) => {
    if (isNaN(a) && isNaN(b)) {
      return '';
    } else if (!a && b) {
      return parseInt(b);
    } else if (!b && a) {
      return parseInt(a);
    }
    return parseInt(a) + parseInt(b);
  };

  calculateSumWithMask = (a, b) => {
    let num = null;
    if (isNaN(a) && isNaN(b)) {
      return '';
    } else if (!a && b) {
      num = parseInt(b);
    } else if (!b && a) {
      num = parseInt(a);
    } else {
      num = parseInt(a) + parseInt(b);
    }
    return convertToMasked(num, false);
  };

  calculateSubstr = (financialExpenses, financialRevenue, type) => {
    if (isNaN(financialExpenses?.marketing) && isNaN(financialExpenses?.directCosts) &&
      isNaN(financialExpenses?.services) && isNaN(financialExpenses?.taxExpenses)) {
      return '';
    }
    const tovary = financialRevenue ? financialRevenue.services : '';
    const rashody = this.calculateSum(financialExpenses?.marketing, financialExpenses?.directCosts);
    const donalogov = Number(tovary) - Number(rashody);
    const pure = donalogov - financialExpenses?.taxExpenses;
    if (type === 'pure') {
      if (isNaN(pure)) return '';
      const value = convertToMasked(pure, false);
      if (!tovary && value === 0) return 0;
      return value;
    }
    if (type === 'donalogov') {
      if (isNaN(donalogov)) return '';
      const value = convertToMasked(donalogov, false);
      if (!tovary && value === 0) return 0;
      return value;
    }
  };

  handleFRChange = (event, year) => {
    let value = event.target.value.replace(/\s/g, '');
    const inputName = event.target.name;
    if (value.length > 10 || isNaN(Number(value))) return;
    this.setState(
      (prevState) => {
        const financialRevenue = [...prevState.financialRevenue];
        financialRevenue[year][inputName] = value && Number(value);
        return {financialRevenue};
      },
      () => {
        const {financialRevenue} = this.state;
        this.context.handleApplicationFormChange({
          organizationPotential: {financialRevenue},
        });
      },
    );
  };

  handleFEChange = (event, year) => {
    let value = event.target.value.replace(/\s/g, '');
    const inputName = event.target.name;
    if (value.length > 10 || isNaN(Number(value))) return;
    this.setState(
      (prevState) => {
        const financialExpenses = [...prevState.financialExpenses];
        financialExpenses[year][inputName] = value && Number(value);
        return {financialExpenses};
      },
      () => {
        const {financialExpenses} = this.state;
        this.context.handleApplicationFormChange({
          organizationPotential: {financialExpenses},
        });
      },
    );
  };

  handleFileChange = (files) => {
    if (!validateFileSize(50, files)) {
      Notify.pushErrorMessage(i18next.t('info.fileSizeLimit', {ns: 'errors'}));
      return;
    }
    let file = this.context.applicationForm.organizationPotential.file;

    if (files.length) {
      this.setState({fileLoading: true});

      Array.from(files).map((item) => {
        this.props.appState.filesStore
          .uploadFile(item)
          .then((response) => {
            file = {...file, ...response.data};

            this.context.handleApplicationFormChange({
              organizationPotential: {file},
            });
          })
          .catch(() => {
            wrongText(i18next.t('ErrorLoading'));
          })
          .finally(() => {
            this.setState({fileLoading: false});
          });
      });
    }
  };

  renderDocFile(doc) {
    if (doc) {
      return (
        <FileComponent id={doc.id} name={doc.name} extension={doc.extension}/>
      );
    }
  }

  deleteOPFile = () => {
    let file = this.context.applicationForm.organizationPotential.file;

    if (file) {
      for (let key in file) {
        delete file[key];
      }
    }
    this.context.handleApplicationFormChange({
      organizationPotential: {file},
    });
  };

  hanldeInputBlur = () => {
    this.context.clearError('financialRevenue');
  };

  checkIfAllInputsZero = (financialRevenue, financialExpenses): boolean => {
    for (const item of financialRevenue) {
      if (item.services !== 0) {
        return false;
      }
    }
    for (const item of financialExpenses) {
      if (
        item.marketing !== 0 ||
        item.taxExpenses !== 0 ||
        item.directCosts !== 0
      ) {
        return false;
      }
    }
    return true;
  };

  renderFinancialModel = () => {
    const financialRevenue =
      this.context.applicationForm.organizationPotential.financialRevenue ??
      this.state.financialRevenue;

    const financialExpenses =
      this.context.applicationForm.organizationPotential.financialExpenses ??
      this.state.financialExpenses;
    const {readOnly} = this.context;
    const {file} = this.context.applicationForm.organizationPotential;

    return (
      <>
        <table className="second__table finance-table">
          <tbody>
          <tr className="first__table-title">
            <th id="width-40"/>
            <th id="width-30">2021</th>
            <th id="width-30">2022</th>
          </tr>
          <tr style={{background: '#ffffff'}}>
            <td>
              <p style={{marginTop: '5px'}} className={'fn_table-title'}>
                <Trans>organizationPotential.financialRevenue</Trans>
              </p>
            </td>
            <td/>
            <td/>
          </tr>
          <tr>
            <th>
              <Trans>organizationPotential.goods</Trans>
            </th>
            <td
              className="expense-plan__td"
              style={{background: '#ffffff'}}
            >
              <input
                className="placeholder-none"
                name="services"
                type="text"
                value={
                  financialRevenue[0]?.services?.toLocaleString('ru') || ''
                }
                onBlur={this.hanldeInputBlur}
                onChange={(e) => this.handleFRChange(e, 0)}
                readOnly={readOnly}
                placeholder={i18next.t('placeHolderNumber')}
              />
            </td>
            <td
              className="expense-plan__td"
              style={{background: '#ffffff'}}
            >
              <input
                className="placeholder-none"
                name="services"
                type="text"
                value={
                  financialRevenue[1]?.services?.toLocaleString('ru') || ''
                }
                onBlur={this.hanldeInputBlur}
                onChange={(e) => this.handleFRChange(e, 1)}
                readOnly={readOnly}
                placeholder={i18next.t('placeHolderNumber')}
              />
            </td>
          </tr>
          <tr>
            <th>
              <Trans>organizationPotential.totalRevenue</Trans>
            </th>
            <td className="expense-plan__td">
              <input
                placeholder={i18next.t('AmountIsAutomaticallyCalculated')}
                className="placeholder-none"
                value={convertToMasked(financialRevenue[0]?.services, false)}
                readOnly
              />
            </td>
            <td className="expense-plan__td">
              <input
                placeholder={i18next.t('AmountIsAutomaticallyCalculated')}
                className="placeholder-none"
                value={convertToMasked(financialRevenue[1]?.services, false)}
                readOnly
              />
            </td>
          </tr>
          <tr style={{background: '#ffffff'}}>
            <td>
              <p style={{marginTop: '5px'}} className={'fn_table-title'}>
                <Trans>organizationPotential.financialExpenses</Trans>
              </p>
            </td>
            <td/>
            <td/>
          </tr>
          <tr>
            <th>
              <Trans>organizationPotential.direct</Trans>
            </th>
            <td
              className="expense-plan__td"
              style={{background: '#ffffff'}}
            >
              <input
                className="placeholder-none"
                name="directCosts"
                placeholder={i18next.t('placeHolderNumber')}
                type="text"
                value={
                  financialExpenses[0]?.directCosts?.toLocaleString('ru') ||
                  ''
                }
                onBlur={this.hanldeInputBlur}
                onChange={(e) => this.handleFEChange(e, 0)}
                readOnly={readOnly}
              />
            </td>
            <td
              className="expense-plan__td"
              style={{background: '#ffffff'}}
            >
              <input
                className="placeholder-none"
                name="directCosts"
                type="text"
                value={
                  financialExpenses[1]?.directCosts?.toLocaleString('ru') ||
                  ''
                }
                onBlur={this.hanldeInputBlur}
                onChange={(e) => this.handleFEChange(e, 1)}
                readOnly={readOnly}
                placeholder={i18next.t('placeHolderNumber')}
              />
            </td>
          </tr>
          <tr>
            <th>
              <Trans>organizationPotential.marketing</Trans>
            </th>
            <td
              className="expense-plan__td"
              style={{background: '#ffffff'}}
            >
              <input
                className="placeholder-none"
                name="marketing"
                type="text"
                value={
                  financialExpenses[0]?.marketing?.toLocaleString('ru') || ''
                }
                onBlur={this.hanldeInputBlur}
                onChange={(e) => this.handleFEChange(e, 0)}
                readOnly={readOnly}
                placeholder={i18next.t('placeHolderNumber')}
              />
            </td>
            <td
              className="expense-plan__td"
              style={{background: '#ffffff'}}
            >
              <input
                className="placeholder-none"
                name="marketing"
                type="text"
                value={
                  financialExpenses[1]?.marketing?.toLocaleString('ru') || ''
                }
                onBlur={this.hanldeInputBlur}
                onChange={(e) => this.handleFEChange(e, 1)}
                readOnly={readOnly}
                placeholder={i18next.t('placeHolderNumber')}
              />
            </td>
          </tr>
          <tr>
            <th>
              <Trans>organizationPotential.totalExpenses</Trans>
            </th>
            <td className="expense-plan__td">
              <input
                placeholder={i18next.t('AmountIsAutomaticallyCalculated')}
                className="placeholder-none"
                value={this.calculateSumWithMask(
                  financialExpenses[0]?.marketing,
                  financialExpenses[0]?.directCosts,
                )}
                readOnly
              />
            </td>

            <td className="expense-plan__td">
              <input
                placeholder={i18next.t('AmountIsAutomaticallyCalculated')}
                className="placeholder-none"
                value={this.calculateSumWithMask(
                  financialExpenses[1]?.marketing,
                  financialExpenses[1]?.directCosts,
                )}
                readOnly
              />
            </td>
          </tr>
          <tr>
            <th>
              <Trans>organizationPotential.income</Trans>
            </th>
            <td className="expense-plan__td">
              <input
                placeholder={i18next.t('AmountIsAutomaticallyCalculated')}
                className="placeholder-none"
                value={this.calculateSubstr(
                  financialExpenses[0],
                  financialRevenue[0],
                  'donalogov',
                )}
                readOnly
              />
            </td>
            <td className="expense-plan__td">
              <input
                placeholder={i18next.t('AmountIsAutomaticallyCalculated')}
                className="placeholder-none"
                value={this.calculateSubstr(
                  financialExpenses[1],
                  financialRevenue[1],
                  'donalogov',
                )}
                readOnly
              />
            </td>
          </tr>
          <tr>
            <th>
              <Trans>organizationPotential.tax</Trans>
            </th>
            <td
              className="expense-plan__td"
              style={{background: '#ffffff'}}
            >
              <input
                className="placeholder-none"
                name="taxExpenses"
                type="text"
                value={
                  financialExpenses[0]?.taxExpenses?.toLocaleString('ru') ||
                  ''
                }
                onBlur={this.hanldeInputBlur}
                onChange={(e) => this.handleFEChange(e, 0)}
                readOnly={readOnly}
                placeholder={i18next.t('placeHolderNumber')}
              />
            </td>
            <td
              className="expense-plan__td"
              style={{background: '#ffffff'}}
            >
              <input
                className="placeholder-none"
                name="taxExpenses"
                type="text"
                value={
                  financialExpenses[1]?.taxExpenses?.toLocaleString('ru') ||
                  ''
                }
                onBlur={this.hanldeInputBlur}
                onChange={(e) => this.handleFEChange(e, 1)}
                readOnly={readOnly}
                placeholder={i18next.t('placeHolderNumber')}
              />
            </td>
          </tr>
          <tr>
            <th>
              <Trans>organizationPotential.netIncome</Trans>
            </th>
            <td className="expense-plan__td">
              <input
                placeholder={i18next.t('AmountIsAutomaticallyCalculated')}
                className="placeholder-none"
                value={this.calculateSubstr(
                  financialExpenses[0],
                  financialRevenue[0],
                  'pure',
                )}
                readOnly
              />
            </td>
            <td className="expense-plan__td">
              <input
                placeholder={i18next.t('AmountIsAutomaticallyCalculated')}
                className="placeholder-none"
                value={this.calculateSubstr(
                  financialExpenses[1],
                  financialRevenue[1],
                  'pure',
                )}
                readOnly
              />
            </td>
          </tr>
          {/*OPMemberEmail*/}
          </tbody>
        </table>
        <div className="inputfile-wrap op-file">
          <button
            style={{marginTop: '0'}}
            className="button-input btn btn-success"
            disabled={readOnly || this.checkIfAllInputsZero(
                financialRevenue,
                financialExpenses,
              )}
          >
            <label
              htmlFor={`inputfileOP`}
            >
              {this.state.fileLoading ? <MiniLoad/> : <Trans>ChooseFile</Trans>}
            </label>
          </button>
          {file && !!Object.keys(file)?.length && this.renderDocFile(file)}

          {!readOnly && file && !!Object.keys(file)?.length && (
            <div className="empty_td">
              <span onClick={() => this.deleteOPFile()} className="icon-delete">
                +
              </span>
            </div>
          )}

          <input
            type="file"
            id={`inputfileOP`}
            accept=".pdf,.doc,.docx"
            onChange={(event) => this.handleFileChange(event.target.files)}
            disabled={
              readOnly ||
              this.checkIfAllInputsZero(financialRevenue, financialExpenses)
            }
            readOnly={
              readOnly ||
              this.checkIfAllInputsZero(financialRevenue, financialExpenses)
            }
          />
        </div>
        <div
          style={{
            paddingTop: '5px',
            paddingBottom: '5px',
            fontWeight: '400',
            fontSize: '12px',
          }}
        >
         <i><Trans>SupportedFileFormatsCV</Trans></i>
        </div>
      </>
    );
  };

  render() {
    const programName = this.props.program.name;
    const {getError} = this.context;
    return (
      <>
        <h3 className="form__question" id="financialRevenue">
          {sectionNumber.renderSubSection('financialModel')}.{' '}
          {renderHeader(programName)}
        </h3>
        <p className="form__question__descrption">
          <Trans>organizationPotential.modelExtra</Trans>
        </p>
        <div
          className={
            getError('financialRevenue') || getError('financialExpenses')
              ? 'red-border-error application-table__body'
              : 'application-table__body'
          }
        >
          <div className="application-table" style={{marginBottom: 0}}>
            {this.renderFinancialModel()}
          </div>
        </div>
      </>
    );
  }
}

export default FinancialModel;
