import * as React from 'react';
import DocComponent from '../components/DocComponent';
import { inject } from 'mobx-react';

export interface CompetitionRequirementsProps {
  loadAttachment;
  loadFile;
  appState?: any;
}

const CompetitionRequirements = inject('appState')(
  (props: CompetitionRequirementsProps) => {
    const { competition } = props.appState.competitionsStore;

    return (
      <React.Fragment>
        <div className="comp__requirements__wrapper">
          <div className="comp__requirements__left">
            {competition.requirements &&
              competition.requirements.length > 0 && (
                <div>
                  <h2 className="comp__requirements__header">
                    Заявитель должен одновременно соответствовать следующим
                    требованиям:{' '}
                  </h2>
                  <p className="comp__requirements__text" />
                  <div className="comp__requirements__container">
                    {competition.requirements.slice().map((req_el, req_key) => (
                      <div className="comp__requirements__item" key={req_key}>
                        <p className="comp__requirements__number">
                          {req_el.item}
                        </p>
                        <p className="comp__requirements__desc">
                          {capitalizeFirstLetter(req_el.description)}
                        </p>
                        <p className="comp__requirements__mandatory">
                          {req_el.mandatory ? '*' : ''}
                        </p>
                      </div>
                    ))}
                  </div>
                </div>
              )}
            {competition.attachments && competition.attachments.length > 0 && (
              <div className="comp__attachments">
                <h2 className="comp__attachments__header">Приложения</h2>
                <div className="comp__attachments__container">
                  {competition.attachments
                    .slice()
                    .sort((a, b) => {
                      if (a.name && b.name) {
                        return a.name.localeCompare(b.name);
                      }
                    })
                    .map((att_el) => renderDocComponent(att_el))}
                </div>
              </div>
            )}
          </div>
        </div>
        {competition.scorings && competition.scorings.length > 0 && (
          <div className="comp__scorings">
            <h2>Критерий отбора</h2>
            <div className="comp__scorings__container">
              <table className="comp__scorings__table">
                <tbody>
                  {competition.scorings.map((scoring, idx) => {
                    return (
                      <tr key={idx}>
                        <td>{idx + 1}</td>
                        <td>{scoring.achievement}</td>
                        <td>{scoring.point} балла(ов)</td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
          </div>
        )}
      </React.Fragment>
    );

    const renderDocComponent = (element) => {
      const { loadAttachment, loadFile } = props;
      return (
        <DocComponent
          key={element.id}
          id={element.id}
          getDoc={loadAttachment}
          getFile={loadFile}
          fileType="attachment"
          name={element.name}
        />
      );
    };

    const capitalizeFirstLetter = (string) => {
      if (string) {
        return string.charAt(0).toUpperCase() + string.slice(1);
      }
    };
  }
);

export default CompetitionRequirements;
