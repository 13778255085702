import React from "react";
import { Link, RouteComponentProps, withRouter } from "react-router-dom";
import { observer } from "mobx-react";
import { Trans, withTranslation } from "react-i18next";
import {
  Breadcrumb,
  Dropdown,
  Nav,
  Navbar,
  NavDropdown,
  Accordion,
  Card,
} from "react-bootstrap";
import cl from "classnames";
import { AppStateObserver, injectAppState } from "../../stores";
import logo from "../../assets/icons/logo.png";

import "./Header.css";
import Popup from "reactjs-popup";
import Breadcrumps from "../Breadcrumps";
import i18n from "i18next";
import HeaderMenu from "../HeaderMenu";
import eyeIcon from "../../assets/icons/eye.svg";
import { TimerforCompetition } from "../Timer/TimerforCompetition";
import { BsFillEyeFill } from "react-icons/bs";
import { TimerEndCurrentStep } from "components/Timer/TimerEndCurrentStep";

export interface HeaderProps {}

export interface HeaderState {}

@injectAppState
@observer
class Header extends React.Component<
  AppStateObserver & RouteComponentProps,
  HeaderProps,
  HeaderState
> {
  state = {
    showMenu: false,
    isActive: false,
    isDropdownVisible: false,
    activeItem: null,
    isListVisibleUsefulMaterial: false,
    isListVisibleProfile: false,
    menu: [
      {
        name: "Main",
        path: "/",
      },
      {
        name: "personalData",
        link: "/cabinet/personal",
      },
    ],
  };

  handleMouseEnter = () => {
    this.setState({ isDropdownVisible: true });
  };

  handleMouseLeave = () => {
    this.setState({ isDropdownVisible: false });
  };

  toggleMenu = () => {
    let show = this.state.showMenu;
    show = !show;
    this.setState({ showMenu: show });
  };

  toggleMenuBurger = () => {
    this.setState({ isActive: !this.state.isActive });
    this.setState({ showMenu: !this.state.showMenu });
  };

  closeMenu = () => {
    this.setState({ showMenu: false });
  };

  logout = () => {
    this.props.appState.userStore.logout().then(() => {
      this.props.history.push("/");
    });
  };

  handleLanguageChange(languages) {
    this.props.appState.userStore.changeLanguage(languages);
    this.forceUpdate();
  }

  changeTitleLang(language) {
    switch (language) {
      case "ru":
        document.title =
          "Портал проектов и программ Фонда социального развития.";
        break;
      case "kz":
        document.title =
          "Әлеуметтік даму қорының жобалары мен бағдарламаларының порталы.";
        break;
      case "en":
        document.title =
          "Portal of projects and programs of the Social Development Fund.";
        break;
      default:
        document.title =
          "Портал проектов и программ Фонда социального развития.";
        break;
    }
  }

  isChromeBrowser() {
    if (
      navigator.userAgent.includes("Opera") ||
      navigator.userAgent.includes("OPR")
    ) {
      // Opera
      return false;
    } else if (navigator.userAgent.includes("Edg")) {
      // Edge
      return false;
    } else if (navigator.userAgent.includes("Chrome")) {
      // Chrome
      return true;
    } else if (navigator.userAgent.includes("Safari")) {
      // Safari
      return false;
    } else if (navigator.userAgent.includes("Firefox")) {
      // Firefox
      return false;
    } else {
      return false;
    }
  }

  toggleListProfile = () => {
    this.setState({
      isListVisibleProfile: !this.state.isListVisibleProfile,
    });
  };

  toggleListUsefulMaterial = () => {
    this.setState({
      isListVisibleUsefulMaterial: !this.state.isListVisibleUsefulMaterial,
    });
  };

  handleItemClick = itemName => {
    this.setState({ activeItem: itemName });
  };

  render() {
    const { authenticated } = this.props.appState.userStore;
    const { links } = this.props.appState.breadcrumbsStore;
    const { menu } = this.state;

    const { isListVisibleProfile, isListVisibleUsefulMaterial } = this.state;

    const { activeItem } = this.state;

    const renameEntityGrade = {
      GRADUATE_NIS: i18n.t("GraduateNIS"),
      GRADUATE_NU: i18n.t("GraduateNU"),
      STUDENT: i18n.t("Student"),
      EMPLOYEE: i18n.t("Employee"),
      THIRD_PERSON: i18n.t("ThirdPerson"),
    };

    const AboutTheSocial = {
      ru: "https://fund.nu.edu.kz/social-impact/",
      en: "https://fund.nu.edu.kz/en/social-impact/",
      kz: "https://fund.nu.edu.kz/kz/social-impact/",
    };

    const SocialDevelopmentFund = {
      ru: "https://fund.nu.edu.kz/",
      en: "https://fund.nu.edu.kz/en/",
      kz: "https://fund.nu.edu.kz/kz/",
    };

    const currentLanguage = i18n.language;

    const { language } = i18n;
    const { applicant } = this.props.appState.applicantsStore;
    const applicantType = applicant?.applicantType;
    const shouldChangeType =
      this.props.appState.applicantsStore.isTypeChanging ||
      this.props.appState?.applicantsStore?.applicant?.applicantType ===
        "THIRD_PERSON";
    this.changeTitleLang(language);

    const { showMenu } = this.state;
    return (
      <>
        <header className="body__header">
          <div className="body__header--fix">
            <Navbar
              className={!authenticated && "visible body__header--fix"}
              expand="lg"
              expanded={showMenu}
              onToggle={authenticated && this.toggleMenuBurger}
            >
              <div className="container container__nav">
                <Link className="img__header" to="/">
                  <img
                    src={logo}
                    alt="logo"
                    className="Logoheader d-inline-block aling-top"
                  />
                </Link>

                <div className="header__links--mob links-desc">
                  <Nav.Link
                    style={{
                      color: language === "ru" ? "#de944c" : "black",
                    }}
                    onClick={() => this.handleLanguageChange("ru")}
                  >
                    RU
                  </Nav.Link>
                  <Nav.Link
                    style={{
                      color: language === "en" ? "#de944c" : "black",
                    }}
                    onClick={() => this.handleLanguageChange("en")}
                  >
                    EN
                  </Nav.Link>
                  <Nav.Link
                    style={{
                      color: language === "kz" ? "#de944c" : "black",
                    }}
                    onClick={() => this.handleLanguageChange("kz")}
                  >
                    KZ
                  </Nav.Link>
                </div>

                {authenticated && (
                  <div className="burgerMenu">
                    {showMenu ? (
                      <div
                        className="burger-icon-coles"
                        aria-controls="basic-navbar-nav"
                        onClick={this.toggleMenu}
                      >
                        <svg
                          aria-hidden="true"
                          role="presentation"
                          viewBox="0 0 1000 1000"
                          xmlns="http://www.w3.org/2000/svg"
                          className="burger-icon-close-svg"
                        >
                          <path
                            className="burger-icon-close-path"
                            d="M742 167L500 408 258 167C246 154 233 150 217 150 196 150 179 158 167 167 154 179 150 196 150 212 150 229 154 242 171 254L408 500 167 742C138 771 138 800 167 829 196 858 225 858 254 829L496 587 738 829C750 842 767 846 783 846 800 846 817 842 829 829 842 817 846 804 846 783 846 767 842 750 829 737L588 500 833 258C863 229 863 200 833 171 804 137 775 137 742 167Z"
                          ></path>
                        </svg>
                      </div>
                    ) : (
                      <div
                        className="burger-icon-open"
                        aria-controls="basic-navbar-nav"
                        onClick={this.toggleMenu}
                      >
                        <svg
                          aria-hidden="true"
                          role="presentation"
                          viewBox="0 0 1000 1000"
                          xmlns="http://www.w3.org/2000/svg"
                          className="burger-icon-open-svg"
                        >
                          <path
                            className="burger-icon-open-path"
                            d="M104 333H896C929 333 958 304 958 271S929 208 896 208H104C71 208 42 237 42 271S71 333 104 333ZM104 583H896C929 583 958 554 958 521S929 458 896 458H104C71 458 42 487 42 521S71 583 104 583ZM104 833H896C929 833 958 804 958 771S929 708 896 708H104C71 708 42 737 42 771S71 833 104 833Z"
                          ></path>
                        </svg>
                      </div>
                    )}
                  </div>
                )}

                <Navbar.Collapse id="basic-navbar-nav">
                  <Nav
                    className={
                      authenticated ? "me-auto nav-header" : "nav-header"
                    }
                    style={
                      authenticated
                        ? { marginLeft: "none" }
                        : { marginLeft: "auto" }
                    }
                  >
                    {authenticated && !shouldChangeType && (
                      <>
                        <div className="header__links header__links--mob">
                          <div className="dropdown">
                            <div
                              style={{
                                border: "none",
                                padding: "0 !important",
                                paddingLeft: "0",
                                paddingRight: "0",
                              }}
                              className="btn dropdown-toggle"
                            >
                              <Link
                                className="dropdown-content-title"
                                to="/"
                                style={{
                                  color:
                                    activeItem === "Comps"
                                      ? "#de944c"
                                      : "inherit",
                                }}
                                onClick={() => this.handleItemClick("Comps")}
                              >
                                <Trans>Comps</Trans>
                              </Link>
                            </div>
                          </div>
                          <div className="accordion-header">
                            <div
                              style={{
                                border: "none",
                                padding: "0 !important",
                                paddingLeft: "0",
                                paddingRight: "0",
                              }}
                              className="btn dropdown-toggle"
                            >
                              <Link
                                className="dropdown-content-title"
                                to="/"
                                style={{
                                  color:
                                    activeItem === "Comps"
                                      ? "#de944c"
                                      : "inherit",
                                }}
                                onClick={() => this.handleItemClick("Comps")}
                              >
                                <Trans>Comps</Trans>
                              </Link>
                            </div>
                          </div>
                        </div>
                        <div className="divider-rotate" />
                        <div className="header__links header__links--mob">
                          <div className="dropdown">
                            <div
                              style={{
                                border: "none",
                                padding: "0 !important",
                                paddingLeft: "0",
                                paddingRight: "0",
                              }}
                              className="btn dropdown-toggle"
                            >
                              <Link
                                to="/cabinet/personal"
                                className="dropdown-content-title"
                                style={{
                                  color:
                                    activeItem === "Profile"
                                      ? "#de944c"
                                      : "inherit",
                                }}
                                onClick={() => this.handleItemClick("Profile")}
                                onMouseEnter={this.handleMouseEnter}
                                onMouseLeave={this.handleMouseLeave}
                              >
                                <Trans>Profile</Trans>
                                <svg
                                  style={{
                                    marginLeft: "10px",
                                    marginBottom: "5px",
                                    color:
                                      activeItem === "Profile"
                                        ? "#de944c"
                                        : "inherit",
                                  }}
                                  width="8"
                                  viewBox="0 0 320 512"
                                  xmlns="http://www.w3.org/2000/svg"
                                  fill="currentColor"
                                >
                                  <path d="M143 352.3L7 216.3c-9.4-9.4-9.4-24.6 0-33.9l22.6-22.6c9.4-9.4 24.6-9.4 33.9 0l96.4 96.4 96.4-96.4c9.4-9.4 24.6-9.4 33.9 0l22.6 22.6c9.4 9.4 9.4 24.6 0 33.9l-136 136c-9.2 9.4-24.4 9.4-33.8 0z"></path>
                                </svg>
                              </Link>
                            </div>
                            <ul
                              className="dropdown-menu"
                              aria-labelledby="dropdownMenuButton"
                              style={{
                                transition: "0.25s",
                                boxShadow: "0px 5px 20px 0px rgba(0,0,0,.1)",
                                textTransform: "none",
                              }}
                            >
                              <li>
                                <Link
                                  to="/cabinet/personal"
                                  className={`dropdown-content-link dropdown-item-header ${
                                    activeItem === "Profile"
                                      ? "active-item"
                                      : ""
                                  }`}
                                  onClick={() =>
                                    this.handleItemClick("Profile")
                                  }
                                >
                                  <Trans>Profile</Trans>
                                </Link>
                              </li>
                              <li>
                                <Link
                                  to="/applications"
                                  className={`dropdown-content-link dropdown-item-header ${
                                    activeItem === "myApplications"
                                      ? "active-item"
                                      : ""
                                  }`}
                                  onClick={() =>
                                    this.handleItemClick("myApplications")
                                  }
                                >
                                  <Trans>myApplications</Trans>
                                </Link>
                              </li>
                              <li>
                                <Link
                                  to="/change-password"
                                  className={`dropdown-content-link dropdown-item-header ${
                                    activeItem === "changePassword"
                                      ? "active-item"
                                      : ""
                                  }`}
                                  onClick={() =>
                                    this.handleItemClick("changePassword")
                                  }
                                >
                                  <Trans>changePassword</Trans>
                                </Link>
                              </li>
                            </ul>
                          </div>
                          <div className="accordion">
                            <div
                              className="accordion-content-title"
                              onClick={this.toggleListProfile}
                            >
                              <Trans>Profile</Trans>
                              <svg
                                style={{
                                  marginLeft: "10px",
                                  marginBottom: "5px",
                                }}
                                width="8"
                                viewBox="0 0 320 512"
                                xmlns="http://www.w3.org/2000/svg"
                                fill="currentColor"
                              >
                                <path d="M143 352.3L7 216.3c-9.4-9.4-9.4-24.6 0-33.9l22.6-22.6c9.4-9.4 24.6-9.4 33.9 0l96.4 96.4 96.4-96.4c9.4-9.4 24.6-9.4 33.9 0l22.6 22.6c9.4 9.4 9.4 24.6 0 33.9l-136 136c-9.2 9.4-24.4 9.4-33.8 0z"></path>
                              </svg>
                            </div>
                            <ul
                              className="accordion-menu"
                              style={{
                                transition: "0.25s",
                                textTransform: "none",
                                display: this.state.isListVisibleProfile
                                  ? "block"
                                  : "none",
                              }}
                            >
                              <li>
                                <Link
                                  to="/cabinet/personal"
                                  className={`accordion-item-header ${
                                    activeItem === "Profile"
                                      ? "active-item"
                                      : ""
                                  }`}
                                  onClick={() =>
                                    this.handleItemClick("Profile")
                                  }
                                >
                                  <Trans>Profile</Trans>
                                </Link>
                              </li>
                              <li>
                                <Link
                                  to="/applications"
                                  className={`accordion-item-header ${
                                    activeItem === "myApplications"
                                      ? "active-item"
                                      : ""
                                  }`}
                                  onClick={() =>
                                    this.handleItemClick("myApplications")
                                  }
                                >
                                  <Trans>myApplications</Trans>
                                </Link>
                              </li>
                              <li>
                                <Link
                                  to="/change-password"
                                  className={`accordion-item-header ${
                                    activeItem === "changePassword"
                                      ? "active-item"
                                      : ""
                                  }`}
                                  onClick={() =>
                                    this.handleItemClick("changePassword")
                                  }
                                >
                                  <Trans>changePassword</Trans>
                                </Link>
                              </li>
                            </ul>
                          </div>
                          <div className="divider-rotate" />
                        </div>

                        <div className="dropdown">
                          <button
                            className="btn dropdown-toggle header__links header__links--mob"
                            style={{
                              border: "none",
                              padding: "0 !important",
                              paddingLeft: "0",
                            }}
                          >
                            <Link
                              to="#"
                              className="dropdown-content-title"
                              onClick={this.toggleMenuBurger}
                              onMouseEnter={this.handleMouseEnter}
                              onMouseLeave={this.handleMouseLeave}
                            >
                              <Trans>UsefulMaterial</Trans>
                              <svg
                                style={{
                                  marginLeft: "10px",
                                  marginBottom: "5px",
                                }}
                                width="8"
                                viewBox="0 0 320 512"
                                xmlns="http://www.w3.org/2000/svg"
                                fill="currentColor"
                              >
                                <path d="M143 352.3L7 216.3c-9.4-9.4-9.4-24.6 0-33.9l22.6-22.6c9.4-9.4 24.6-9.4 33.9 0l96.4 96.4 96.4-96.4c9.4-9.4 24.6-9.4 33.9 0l22.6 22.6c9.4 9.4 9.4 24.6 0 33.9l-136 136c-9.2 9.4-24.4 9.4-33.8 0z"></path>
                              </svg>
                            </Link>
                          </button>
                          <ul
                            className="dropdown-menu"
                            aria-labelledby="dropdownMenuButton"
                            style={{
                              transition: "0.25s",
                              boxShadow: "0px 5px 20px 0px rgba(0,0,0,.1)",
                              textTransform: "none",
                            }}
                          >
                            <li>
                              <a
                                className="dropdown-item-header"
                                href={`${AboutTheSocial[currentLanguage]}`}
                                target="blank"
                              >
                                <Trans>AboutTheSocial</Trans>
                              </a>
                            </li>
                            <li>
                              <a
                                className="dropdown-item-header"
                                href={`${SocialDevelopmentFund[currentLanguage]}`}
                                target="blank"
                              >
                                <Trans>SocialDevelopmentFund</Trans>
                              </a>
                            </li>
                            
                          </ul>
                        </div>
                        <div className="accordion">
                          <div
                            className="accordion-content-title"
                            onClick={this.toggleListUsefulMaterial}
                          >
                            <Trans>UsefulMaterial</Trans>
                            <svg
                              style={{
                                marginLeft: "10px",
                                marginBottom: "5px",
                              }}
                              width="8"
                              viewBox="0 0 320 512"
                              xmlns="http://www.w3.org/2000/svg"
                              fill="currentColor"
                            >
                              <path d="M143 352.3L7 216.3c-9.4-9.4-9.4-24.6 0-33.9l22.6-22.6c9.4-9.4 24.6-9.4 33.9 0l96.4 96.4 96.4-96.4c9.4-9.4 24.6-9.4 33.9 0l22.6 22.6c9.4 9.4 9.4 24.6 0 33.9l-136 136c-9.2 9.4-24.4 9.4-33.8 0z"></path>
                            </svg>
                          </div>
                          <ul
                            className="accordion-menu"
                            aria-labelledby="accordionMenuButton"
                            style={{
                              transition: "0.25s",
                              textTransform: "none",
                              display: this.state.isListVisibleUsefulMaterial
                                ? "block"
                                : "none",
                            }}
                          >
                            <li>
                              <a
                                className="accordion-item-header"
                                href={`${AboutTheSocial[currentLanguage]}`}
                                target="_blank"
                              >
                                <Trans>AboutTheSocial</Trans>
                              </a>
                            </li>
                            <li>
                              <a
                                className="accordion-item-header"
                                href={`${SocialDevelopmentFund[currentLanguage]}`}
                                target="_blank"
                              >
                                <Trans>SocialDevelopmentFund</Trans>
                              </a>
                            </li>
                          </ul>
                        </div>
                      </>
                    )}
                    {authenticated ? (
                      <></>
                    ) : (
                      <div className="langSelector">
                        <Nav.Link
                          style={{
                            padding: "5px 5px 5px 5px",
                            color: language === "ru" ? "#de944c" : "black",
                          }}
                          onClick={() => this.handleLanguageChange("ru")}
                        >
                          RU
                        </Nav.Link>
                        <Nav.Link
                          style={{
                            padding: "5px 5px 5px 0",
                            color: language === "en" ? "#de944c" : "black",
                          }}
                          onClick={() => this.handleLanguageChange("en")}
                        >
                          EN
                        </Nav.Link>
                        <Nav.Link
                          style={{
                            padding: "5px 10px 5px 0",
                            color: language === "kz" ? "#de944c" : "black",
                          }}
                          onClick={() => this.handleLanguageChange("kz")}
                        >
                          KZ
                        </Nav.Link>
                      </div>
                    )}
                  </Nav>
                  <section
                    className={cl(
                      "header__browser",
                      authenticated && "header__browser--authenticated",
                      !this.isChromeBrowser() && "header__browser--show"
                    )}
                  >
                    <Trans>BrowserMessage</Trans>{" "}
                    <a
                      href={
                        language === "ru"
                          ? "https://www.google.kz/intl/ru/chrome"
                          : "https://www.google.kz/intl/en/chrome "
                      }
                      target="_blank"
                    >
                      <Trans>BrowserBtn</Trans>
                    </a>
                  </section>
                  {authenticated && (
                    <Nav
                      className="justify-content-end header__links--mob"
                      activeKey="/home"
                      id="SignOut__section"
                    >
                      <div className="langSelector">
                        <Nav.Link
                          style={{
                            padding: "5px 5px 5px 5px",
                            color: language === "ru" ? "#de944c" : "black",
                          }}
                          onClick={() => this.handleLanguageChange("ru")}
                        >
                          RU
                        </Nav.Link>
                        <Nav.Link
                          style={{
                            padding: "5px 5px 5px 0",
                            color: language === "en" ? "#de944c" : "black",
                          }}
                          onClick={() => this.handleLanguageChange("en")}
                        >
                          EN
                        </Nav.Link>
                        <Nav.Link
                          style={{
                            padding: "5px 10px 5px 0",
                            color: language === "kz" ? "#de944c" : "black",
                          }}
                          onClick={() => this.handleLanguageChange("kz")}
                        >
                          KZ
                        </Nav.Link>
                      </div>
                      <Popup
                        trigger={
                          <span className="header-menu__logout">
                            {authenticated && <Trans>Logout</Trans>}
                          </span>
                        }
                        modal
                      >
                        {close => (
                          <div className="modal">
                            <div className="modal__header modal-title">
                              <h1>
                                <Trans>ConfirmationLogout</Trans>
                              </h1>
                            </div>
                            <div className="btn__actions">
                              <button
                                className="button-input btn btn-success"
                                onClick={() => {
                                  close();
                                  this.logout();
                                }}
                              >
                                <Trans>Yes</Trans>
                              </button>
                              <button
                                className="button-input btn btn-light btn-popup"
                                onClick={() => {
                                  close();
                                  this.closeMenu();
                                }}
                              >
                                <Trans>Cancel</Trans>
                              </button>
                            </div>
                          </div>
                        )}
                      </Popup>
                    </Nav>
                  )}
                </Navbar.Collapse>
              </div>
            </Navbar>
          </div>

          {authenticated && (
            <div
              className="container container__nav fix-secondHeader"
              id="header__section2"
            >
              <div className="header__divider" />
              <section className="header__section2">
                <section className="header__breadcrumbs">
                  <Breadcrumb className="breadcrumbs__header">
                    <Breadcrumps links={links} />
                  </Breadcrumb>{" "}
                  <section className="header__timer">
                    {/* <TimerforCompetition /> */}
                    <TimerEndCurrentStep />
                  </section>
                </section>
                <section className="header__name">
                  <div className="authorized__name__content">
                    {applicant && (
                      <div className="header__username">
                        <span className="viewStatus">
                          <BsFillEyeFill
                            color="#de944c8f"
                            style={{ marginRight: "5px", marginBottom: "2px" }}
                          />
                          <Link
                            style={{ textTransform: "unset" }}
                            to="/applications"
                          >
                            <Trans>ViewApplication</Trans>
                          </Link>
                        </span>
                        <div style={{ marginLeft: "20px" }}>
                          <span className="header__username header__username--name">
                            <span>
                              {renameEntityGrade[applicant.applicantType]} |
                            </span>
                            <Link
                              style={{ fontWeight: "normal" }}
                              to={menu[1].link}
                            >
                              {applicant?.fio}
                            </Link>
                          </span>
                        </div>
                      </div>
                    )}
                  </div>
                </section>
              </section>
            </div>
          )}
        </header>
      </>
    );
  }

  renderMenu() {
    const { authenticated } = this.props.appState.userStore;
    const { menu } = this.state;
    const { showMenu } = this.state;

    if (authenticated) {
      return (
        <>
          <Link
            to={menu[1].link}
            onClick={this.toggleMenuBurger}
            className="header__link"
          >
            <Trans>Profile</Trans>
          </Link>
          <div className="header-menu__wrapper">
            {showMenu && (
              <HeaderMenu
                logout={this.logout}
                handleClickOutside={this.closeMenu}
              />
            )}
          </div>
        </>
      );
    }
  }

  renderActiveLang(lang) {
    const { language } = i18n;
    return cl("header__language", lang === language && "active");
  }
}

export default withTranslation()(withRouter(Header));
