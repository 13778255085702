import React from "react";
import { Trans } from "react-i18next";
import i18next from "i18next";
import { IG, PG, SE, SG, SI, SP } from "../../consts";
import checkLimits from "../../helpers/checkLimits";
import { ApplicationFormContext } from "../ApplicationFormProvider";
import Textarea from "../Ui/Textarea";
import { sectionNumber } from "components/ApplicationFormProvider/sectionNumber";
import TM from '../TM';

class FieldResource extends React.Component<any, any> {
  static contextType = ApplicationFormContext;

  public sources = {
    fundsr: i18next.t("fundsr"),
    sobsr: i18next.t("sobsr"),
    sofin: i18next.t("sofin"),
  };

  componentDidMount(): void {
    const { materialBase } = this.context.applicationForm.resource;
    const { budget } = this.context.applicationForm.resource;
    if (!materialBase.length || materialBase.length === 0) this.addMaterial();
    if (!budget.length || budget.length === 0) this.addBudget();
  }

  componentDidUpdate(
    prevProps: Readonly<any>,
    prevState: Readonly<any>,
    snapshot?: any,
  ): void {
    const { materialBase } = this.context.applicationForm.resource;
    const { budget } = this.context.applicationForm.resource;
    if (!materialBase.length || materialBase.length === 0) this.addMaterial();
    if (!budget.length || budget.length === 0) this.addBudget();
  }

  getSourceKeyByValue = (value: string) => {
    return Object.keys(this.sources).find(key => this.sources[key] === value);
  };

  handleInputChange = event => {
    const target = event.target;
    const value = target.type === "checkbox" ? target.checked : target.value;
    const name = target.name;

    this.context.handleApplicationFormChange({ resource: { [name]: value } });
  };

  handleInputChangeMaterial = (event, index) => {
    const target = event.target;
    const value = target.type === "checkbox" ? target.checked : target.value;
    const name = target.name;

    const materialBase = this.context.applicationForm.resource.materialBase;
    materialBase[index][name] = value;

    this.context.handleApplicationFormChange({ resource: { materialBase } });
  };

  addMaterial = () => {
    let materialBaseForm = {
      purpose: "",
      name: "",
      number: Math.floor(Math.random() * 1000),
    };
    const materialBase =
      this.context.applicationForm.resource.materialBase || [];
    if (materialBase.length === 20) return;
    materialBase.push(materialBaseForm);

    this.context.handleApplicationFormChange({ resource: { materialBase } });
  };

  deleteMaterial = index => {
    let materialBase = this.context.applicationForm.resource.materialBase;
    if (materialBase.length > 0) materialBase.splice(index, 1);

    this.context.handleApplicationFormChange({ resource: { materialBase } });
  };

  handleInputChangeBudgetWithMask = (event, index) => {
    if (this.context.getError("expenditureItem"))
      this.context.clearError("expenditureItem");
    const target = event.target;
    const name = target.name;
    let budget = this.context.applicationForm.resource.budget;
    let value = null;
    let numericAmount = null;
    let numericQuantity = null;
    let form = budget[index];

    if (name === "amount") {
      let tarValue = target.value.replace(/\s/g, "");
      if (tarValue.length > 10 || isNaN(Number(tarValue))) {
        return;
      }
      numericAmount = tarValue && Number(tarValue);
      value = numericAmount;
    }
    if (name === "quantity") {
      let tarValue = target.value.replace(/\s/g, "");
      if (tarValue.length > 10 || isNaN(Number(tarValue))) return;
      numericQuantity = tarValue && Number(tarValue);
      value = numericQuantity;
    }
    form[name] = value;
    if (form.quantity && form.amount) {
      if (typeof form.quantity === "number") {
        numericQuantity = form.quantity;
      } else {
        numericQuantity = Number(form.quantity.replace(/\s/g, ""));
      }
      if (typeof form.quantity === "number") {
        numericAmount = form.amount;
      } else {
        numericAmount = Number(form.amount.replace(/\s/g, ""));
      }
      form.price = numericQuantity * numericAmount;
    } else {
      form.price = 0;
    }
    budget[name] = form[name];

    this.context.handleApplicationFormChange({ resource: { budget } });
  };

  handleInputChangeSelfBudgetWithMask = (event, index) => {
    if (this.context.getError("fundingInfo"))
      this.context.clearError("fundingInfo");
    const target = event.target;
    const name = target.name;
    const selfBudget = this.context.applicationForm.resource.selfBudget;
    let value = null;
    let numericAmount = null;
    const form = selfBudget[index];

    if (name === "amount") {
      const tarValue = target.value.replace(/\s/g, "");
      if (tarValue.length > 10 || isNaN(Number(tarValue))) {
        return;
      }
      numericAmount = tarValue && Number(tarValue);
      value = numericAmount;
    }
    form[name] = value;
    selfBudget[name] = form[name];

    this.context.handleApplicationFormChange({ resource: { selfBudget } });
  };

  handleInputChangeBudget = (event, index) => {
    if (this.context.getError("expenditureItem"))
      this.context.clearError("expenditureItem");
    const target = event.target;
    const value = target.type === "checkbox" ? target.checked : target.value;
    const name = target.name;

    const budget = this.context.applicationForm.resource.budget;
    budget[index] = { ...budget[index], [name]: value };

    this.context.handleApplicationFormChange({ resource: { budget } });
  };

  handleInputChangeSelfBudget = (event, index) => {
    if (this.context.getError("fundingInfo"))
      this.context.clearError("fundingInfo");
    const target = event.target;
    const value = target.type === "checkbox" ? target.checked : target.value;
    const name = target.name;

    const selfBudget = this.context.applicationForm.resource.selfBudget;
    selfBudget[index] = { ...selfBudget[index], [name]: value };

    this.context.handleApplicationFormChange({ resource: { selfBudget } });
  };

  addBudget = () => {
    if (this.context.getError("budget")) this.context.clearError("budget");
    let budgetForm = {
      expenditureItem: "",
      amount: 0,
      quantity: 0,
      source: "Fund grant funds",
      number: Math.floor(Math.random() * 1000),
      price: 0,
    };

    let budget = this.context.applicationForm.resource.budget || [];
    if (budget.length === 20) return;
    budget.push(budgetForm);
    this.context.handleApplicationFormChange({ resource: { budget } });
  };

  deleteBudget = index => {
    let budget = this.context.applicationForm.resource.budget || [];
    if (budget.length > 0) budget.splice(index, 1);

    this.context.handleApplicationFormChange({ resource: { budget } });
  };

  addSelfBudget = () => {
    if (this.context.getError("selfBudget"))
      this.context.clearError("selfBudget");
    const budgetForm = {
      fundingInfo: "",
      amount: 0,
    };
    const selfBudget = this.context.applicationForm.resource.selfBudget || [];
    if (selfBudget.length === 20) return;
    selfBudget.push(budgetForm);
    this.context.handleApplicationFormChange({ resource: { selfBudget } });
  };

  deleteSelfBudget = index => {
    let selfBudget = this.context.applicationForm.resource.selfBudget || [];
    if (selfBudget.length > 0) selfBudget.splice(index, 1);

    this.context.handleApplicationFormChange({ resource: { selfBudget } });
  };

  convertToNumber = str => {
    if (typeof str === "number") return str;

    return +str.replace(/^\s+|\s+|\s$/g, "");
  };

  convertToMasked = num => {
    const arr = num.toString().split("").reverse();
    const maskedArr = [];
    if (arr.length > 3) {
      for (let i = 0; i < arr.length; i++) {
        if (i % 3 === 0) {
          maskedArr.push(arr[i] + " ");
          continue;
        }
        maskedArr.push(arr[i]);
      }
    } else {
      return num.toString();
    }
    return maskedArr.reverse().join("");
  };

  calculateSums = (budget, prog) => {
    // const fundsr = [i18next.t('fundsr', { lng: 'ru' }), i18next.t('fundsr', { lng: 'en' }), i18next.t('fundsr', { lng: 'kz' })]
    const sobsr = [
      i18next.t('sobsr', { lng: 'ru' }).toLowerCase(),
      i18next.t('sobsr', { lng: 'en' }).toLowerCase(),
      i18next.t('sobsr', { lng: 'kz' }).toLowerCase(),
    ];
    const sofin = [
      i18next.t('sofin', { lng: 'ru' }).toLowerCase(),
      i18next.t('sofin', { lng: 'en' }).toLowerCase(),
      i18next.t('sofin', { lng: 'kz' }).toLowerCase(),
    ];

    const dontCount = [...sobsr, ...sofin]
    const sumFund = budget.reduce(
      (acc, el) => {
        if (dontCount.includes(el.source.toLowerCase())) return acc;
        if (el.price) {
          return acc + this.convertToNumber(el.price);
        }
        return acc;
      },
      0,
    );

    const sumAll = budget.reduce(
      (acc, el) => {
        if (el.price) {
          return acc + this.convertToNumber(el.price);
        }
        return acc;
      },
      0,
    );

    const sumSelf = budget.reduce(
      (acc, el) => {
        if (sobsr.includes(el.source.toLowerCase()) && el.price) {
          return acc + this.convertToNumber(el.price);
        }
        return acc;
      },
      0,
    );

    const sumCoFin = budget.reduce(
      (acc, el) => {
        if (sofin.includes(el.source.toLowerCase()) && el.price) {
          return acc + this.convertToNumber(el.price);
        }
        return acc;
      },
      0,
    );

    return (
      <>
        {this.context.applicationForm.resource.budget.length > 0 && (
          <>
            <div style={{ display: "flex", flexDirection: "column" }}>
              <div>
                <span className="budget-result">
                  <Trans>sumUp</Trans>
                </span>
                :{" "}
                <span className="budget-count">
                  {this.convertToMasked(sumAll)} ₸
                </span>
              </div>
              <div>
                <span className="budget-result">
                  <Trans>sumUp1</Trans>
                </span>
                :{" "}
                <span className="budget-count">
                  {this.convertToMasked(sumFund)} ₸
                </span>
              </div>
              <div>
                <span className="budget-result">
                  <Trans>sumUp2</Trans>
                </span>
                :{" "}
                <span className="budget-count">
                  {this.convertToMasked(sumSelf)} ₸
                </span>
              </div>
              <div>
                <span className="budget-result">
                  <Trans>sumUp3</Trans>
                </span>
                :{" "}
                <span className="budget-count">
                  {this.convertToMasked(sumCoFin)} ₸
                </span>
              </div>
               {checkLimits(sumFund, prog)}
            </div>
          </>
        )}
      </>
    );
  };

  handleMaterialBaseBlur = () => {
    this.context.clearError("materialBase");
    this.context.clearError("purpose");
  };

  render() {
    const {
      income,
      description,
      experience,
      partners,
      marketTest,
      funds,
      results,
      materialBase,
    } = this.context.applicationForm.resource;
    const programName = this.props.program.name;
    const { readOnly, getError } = this.context;
    const materialBaseErrors =
      this.context.getError("materialBase") || this.context.getError("purpose");
    const { teamMembers, handleTMChange } = this.props;

    return (
      <>
        {programName === PG && (
          <>
            <div className="compform__divider" />
            <h1 className="main__question">
              {sectionNumber.renderSection("RESOURCEPILOT")}.{" "}
              <Trans>RESOURCEPILOT</Trans>
            </h1>
          </>
        )}

        {(programName === SP || programName === SE) && (
          <>
            <div className="compform__divider" />
            <h1 className="main__question">
              {sectionNumber.renderSection("RESOURCE")}. <Trans>RESOURCE</Trans>
            </h1>
          </>
        )}

        {(programName === SI || programName === SG || programName === IG) && (
          <>
            <h3 className="form__question">
              {sectionNumber.renderSubSection("partners")}.{" "}
              <Trans>partners</Trans>
            </h3>
            <p className={"form__question__descrption"}>
              <Trans>partnersExtra</Trans>
            </p>

            <Textarea
              defaultValue={partners}
              name="partners"
              onChange={this.handleInputChange}
              readOnly={readOnly}
            />
          </>
        )}
        {/* TODO this solution is temporarily, the index of subsection in TM is last, thus 4, but it should be 1 */}
        {programName === SE && sectionNumber.renderSubSection("teamMembers") && false}
        {programName === SE &&
          <TM
            program={SE}
            teamMembers={teamMembers}
            handleTMChange={handleTMChange}
          />
        }
        {programName === SE &&
          <>
            <h3 className="form__question">
              {sectionNumber.renderSubSection('partners')}.{' '}
              <Trans>partners</Trans>
            </h3>
            <p className="form__question__descrption">
              <Trans>budgetExtras.SE</Trans>
            </p>

            <Textarea
              defaultValue={partners}
              name="partners"
              onChange={this.handleInputChange}
              readOnly={readOnly}
            />
          </>
        }
        <div className="section__text__input">
          <h3 className="form__question" id="budget">
            {sectionNumber.renderSubSection("budget")}. <Trans>budget</Trans>
          </h3>
          <p className="form__question__descrption-p">
            <Trans>budgetExtras.SP</Trans>
          </p>
          {programName === SI && (
            <p className="form__question__descrption-p">
              <Trans>budgetNotRecommendedSI</Trans>
            </p>
          )}
          {programName !== SE &&
            <p className="form__question__descrption-p">
              <Trans>budgetNotRecommended</Trans>
            </p>
          }
          <div
            className={
              getError("budget") || getError("expenditureItem")
                ? "red-border-error application-table__body  m-t"
                : "application-table__body  m-t"
            }
          >
            <div className="application-table">{this.renderBudget()}</div>
          </div>
        </div>
        <div
          className="add-expense__container"
          style={{
            display: "flex",
            flexDirection: "column",
            marginTop: "20px",
          }}
        >
          {this.context.applicationForm.resource.budget &&
            this.calculateSums(
              this.context.applicationForm.resource.budget,
              programName,
            )}
        </div>
        {/*TODO SE BUDGET TABLE IS DIFFERENT*/}
        {this.context.applicationForm.resource?.budget?.length < 20 && (
          <button
            className="button-input btn btn-success"
            onClick={this.addBudget}
            disabled={
              (this.context.applicationForm.resource.budget &&
                this.context.applicationForm.length >= 10) ||
              readOnly
            }
          >
            <Trans>Add</Trans>
          </button>
        )}
        {programName !== SE && <div className="section__text__input">
          <h3 className="form__question" id="budget">
            {sectionNumber.renderSubSection("selfBudget")}.{" "}
            <Trans>SocEnt.ExtraFund</Trans>
          </h3>
          <p className="form__question__descrption">
            <Trans>SocEnt.ExtraFundExtra</Trans>
          </p>
          <div className="application-table__body  m-t">
            {this.renderSelfBudget()}
          </div>
        </div>}
        {programName !== SE && this.context.applicationForm.resource?.selfBudget?.length < 10 && (
          <button
            className="button-input btn btn-success"
            onClick={this.addSelfBudget}
            disabled={
              (this.context.applicationForm.resource?.budget &&
                this.context.applicationForm.length >= 10) ||
              readOnly
            }
          >
            <Trans>Add</Trans>
          </button>
        )}
        {programName === SI && (
          <>
            <h3 className="form__question">
              {sectionNumber.renderSubSection("marketTest")}.{" "}
              <Trans>marketTest</Trans>
            </h3>
            <p className={"form__question__descrption"}>
              <Trans>marketTestExtra</Trans>
            </p>

            <div className="wrap-area">
              <Textarea
                defaultValue={marketTest}
                name="marketTest"
                onChange={this.handleInputChange}
                readOnly={readOnly}
              />
            </div>
          </>
        )}
        {programName !== SP && programName !== PG && (
          <>
            <h3 className="form__question">
              {sectionNumber.renderSubSection("materialBase")}.{" "}
              <Trans>materialBase</Trans>
            </h3>
            <p className="form__question__descrption">
              <Trans>materialBaseExtra</Trans>
            </p>

            <div
              className={
                materialBaseErrors
                  ? "red-border-error application-table__body m-t"
                  : "application-table__body m-t"
              }
            >
              <div className="application-table">
                {this.renderMaterialBase()}
              </div>
            </div>

            {materialBase?.length < 20 && (
              <div className="add-expense__container">
                <button
                  className="button-input btn btn-success"
                  onClick={this.addMaterial}
                  disabled={readOnly}
                >
                  <Trans>Add</Trans>
                </button>
              </div>
            )}
          </>
        )}
        {programName === SI && (
          <table className="talap-form">
            <tbody>
            {/* SI */}
            {programName === SI && (
              <>
                <tr className={"extra-tr"}>
                  <th>
                    <label className={"main-label"} htmlFor="">
                      <Trans>incomeR</Trans>
                    </label>
                    <p className={"extra-label"}>
                      <Trans>incomeRExtra</Trans>
                    </p>
                  </th>
                  <td>
                    <input
                      type="number"
                      className="general-info__input"
                      value={income}
                      name="income"
                      id={"extra__input"}
                      onChange={this.handleInputChange}
                      readOnly={readOnly}
                      placeholder={i18next.t('placeHolderNumber')}
                    />
                  </td>
                </tr>
                <tr className={"extra-tr"}>
                  <th>
                    <h3 className="form__question">
                      {sectionNumber.renderSubSection("funds")}.{" "}
                      <Trans>funds</Trans>
                    </h3>
                  </th>
                  <td>
                    <div className="wrap-area">
                      <Textarea
                        defaultValue={funds}
                        name="funds"
                        onChange={this.handleInputChange}
                        readOnly={readOnly}
                      />
                    </div>
                  </td>
                </tr>
                <tr className={"extra-tr"}>
                  <th>
                    <h3 className="form__question">
                      {sectionNumber.renderSubSection("descriptionResource")}.{" "}
                      <Trans>descriptionResource</Trans>
                    </h3>
                    <p className={"form__question__descrption"}>
                      <Trans>descriptionResourceExtra</Trans>
                    </p>
                  </th>
                  <td>
                    <div className="wrap-area">
                      <Textarea
                        defaultValue={description}
                        name="description"
                        onChange={this.handleInputChange}
                        readOnly={readOnly}
                      />
                    </div>
                  </td>
                </tr>
              </>
            )}
            {/* both */}
            {programName === SI && (
              <tr className={"extra-tr"}>
                <th>
                  <h3 className="form__question">
                    {sectionNumber.renderSubSection("results")}.{" "}
                    <Trans>results</Trans>
                  </h3>
                  {/*<p className={'form__question__descrption'}><Trans>resultsExtra</Trans></p>*/}
                </th>
                <td>
                  <div className="wrap-area">
                    <Textarea
                      defaultValue={results}
                      name="results"
                      onChange={this.handleInputChange}
                      readOnly={readOnly}
                    />
                  </div>
                </td>
              </tr>
            )}
            </tbody>
          </table>
        )}
      </>
    );
  }

  renderMaterialBase() {
    const { materialBase } = this.context.applicationForm.resource;
    const { readOnly } = this.context;

    return (
      <table className="second__table" id="materialBase">
        <tbody>
        <tr className="first__table-title">
          <th id="width-60" style={{ minWidth: "250px" }}>
            <Trans>nameMaterial</Trans>
          </th>
          <th id="width-40" style={{ minWidth: "200px" }}>
            <Trans>purpose</Trans>
          </th>
        </tr>
        {materialBase &&
          materialBase.map((mb, idx) => (
            <tr key={idx}>
              <td className="expense-plan__td m-t-10">
                <input
                  value={mb.name}
                  maxLength={200}
                  className="expense-plan__input "
                  type="text"
                  name="name"
                  onBlur={this.handleMaterialBaseBlur}
                  onChange={event =>
                    this.handleInputChangeMaterial(event, idx)
                  }
                  readOnly={readOnly}
                  placeholder={i18next.t('placeHolder')}
                />
              </td>
              <td className="expense-plan__td position-relative">
                <input
                  value={mb.purpose}
                  placeholder={i18next.t('placeHolder')}
                  maxLength={200}
                  className="expense-plan__input"
                  onBlur={this.handleMaterialBaseBlur}
                  type="text"
                  name="purpose"
                  onChange={event =>
                    this.handleInputChangeMaterial(event, idx)
                  }
                  readOnly={readOnly}
                />
                {!readOnly && (
                  <span
                    onClick={() => this.deleteMaterial(idx)}
                    className="icon-delete top-right"
                  >
                      +
                    </span>
                )}
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    );
  }

  renderBudget() {
    const { budget } = this.context.applicationForm.resource;
    const programName = this.props.program.name;
    const { readOnly } = this.context;

    return (
      <table className="second__table">
        <thead>
        <tr className="first__table-title">
          <th id="width-30">
            <Trans>expenditureItem</Trans>
          </th>
          <th id="width-15">
            <Trans>quantity</Trans>
          </th>
          <th id="width-15">
            <Trans>amount</Trans>
          </th>

          <th
            id="width-15"
            style={{ minWidth: "200px" }}
            className="budget-header"
          >
            <Trans>price</Trans>
          </th>
          {programName === SE &&
            <th
              id="width-30"
              style={{ minWidth: '200px' }}
              className="budget-header"
            >
              <Trans>source</Trans>
            </th>
          }
        </tr>
        </thead>
        <tbody>
        {budget &&
          budget.map((bd, idx) => {
            return (
              <tr key={idx}>
                <td className="expense-plan__td m-t-10">
                  <input
                    value={bd.expenditureItem}
                    className="expense-plan__input"
                    type="text"
                    maxLength={200}
                    name="expenditureItem"
                    onChange={event =>
                      this.handleInputChangeBudget(event, idx)
                    }
                    placeholder={i18next.t('placeHolder')}
                    readOnly={readOnly}
                  />
                </td>
                <td className="expense-plan__td">
                  <input
                    value={
                      bd.quantity === 0
                        ? ""
                        : bd?.quantity?.toLocaleString("ru")
                    }
                    className="expense-plan__input"
                    type="text"
                    name="quantity"
                    onChange={event =>
                      this.handleInputChangeBudgetWithMask(event, idx)
                    }
                    readOnly={readOnly}
                    placeholder={i18next.t('placeHolderNumber')}
                  />
                </td>
                <td className="expense-plan__td">
                  <input
                    value={
                      bd.amount === 0 ? "" : bd.amount?.toLocaleString("ru")
                    }
                    className="expense-plan__input"
                    type="text"
                    name="amount"
                    onChange={event =>
                      this.handleInputChangeBudgetWithMask(event, idx)
                    }
                    placeholder={i18next.t('placeHolderNumber')}
                    readOnly={readOnly}
                  />
                </td>

                <td className="expense-plan__td position-relative">
                  <input
                    readOnly
                    value={
                      bd.price === 0 ? "" : bd.price?.toLocaleString("ru")
                    }
                    className="expense-plan__input"
                    type="text"
                    name="price"
                    placeholder={i18next.t("AutoCalculated")}
                  />
                  {!readOnly && programName !== SE &&(
                    <span
                      onClick={() => this.deleteBudget(idx)}
                      className="icon-delete top-right"
                    >
                        +
                      </span>
                  )}
                </td>
                {programName === SE && <td className="expense-plan__td position-relative">
                  <select
                    value={bd.source}
                    name="source"
                    onChange={(event) =>
                      this.handleInputChangeBudget(event, idx)
                    }
                    className="default-input default-input--select"
                    disabled={readOnly}
                  >
                    <option value="" disabled hidden>
                      {i18next.t('choose')}
                    </option>
                    {Object.keys(this.sources).map((sourceKey) => (
                      <option key={sourceKey} value={this.sources[sourceKey]}>
                        {i18next.t(sourceKey)}
                      </option>
                    ))}
                  </select>
                  {!readOnly && (
                    <span
                      onClick={() => this.deleteBudget(idx)}
                      className="icon-delete top-right"
                    >
                        +
                      </span>
                  )}
                </td>}
              </tr>
            );
          })}
        </tbody>
      </table>
    );
  }

  renderSelfBudget() {
    const { budget, selfBudget = [] } = this.context.applicationForm.resource;
    const { readOnly } = this.context;

    return (
      <>
        <div className="application-table">
          <table className="second__table">
            <thead>
            <tr className="first__table-title">
              <th id="width-30">
                <Trans>Sum</Trans>
              </th>
              <th id="width-60">
                <Trans>fundSource</Trans>
              </th>
            </tr>
            </thead>
            <tbody>
            {selfBudget &&
              selfBudget.map((bd, idx) => (
                <tr key={idx}>
                  <td className="expense-plan__td m-t-10">
                    <input
                      readOnly={readOnly}
                      value={
                        bd.amount === 0 ? "" : bd?.amount.toLocaleString("ru")
                      }
                      className="expense-plan__input"
                      type="text"
                      name="amount"
                      onChange={event =>
                        this.handleInputChangeSelfBudgetWithMask(event, idx)
                      }
                      placeholder={i18next.t('placeHolder')}
                    />
                  </td>
                  <td className="expense-plan__td position-relative">
                    <input
                      value={bd.fundingInfo}
                      className="expense-plan__input"
                      type="text"
                      maxLength={200}
                      name="fundingInfo"
                      onChange={event =>
                        this.handleInputChangeSelfBudget(event, idx)
                      }
                      placeholder={i18next.t('placeHolderNumber')}
                      readOnly={readOnly}
                    />
                    {!readOnly && (
                      <span
                        onClick={() => this.deleteSelfBudget(idx)}
                        className="icon-delete top-right"
                      >
                          +
                        </span>
                    )}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </>
    );
  }
}

export default FieldResource;
