import * as React from 'react';
import Header from '../../components/Header';
import Footer from '../../components/Footer/Footer';
import './AuthLayout.css';

const MainLayout = (props) => {
  return (
    <>
      <Header />

      <div className={'page-wrapper page-wrapper__bg'}>
        <div className="page-wrapper__bgc">{props.children}</div>
      </div>

      <Footer />
    </>
  );
};

export default MainLayout;
