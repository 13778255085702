import * as React from "react";
import { observer, inject } from "mobx-react";
import { Route, RouteComponentProps } from "react-router-dom";
import { AppStateObserver } from "../../stores";
import Applications from "../../components/Applications";
import { Trans } from "react-i18next";
import ChangePassword from "components/ChangePassword";
import "./ChangePasswordPage.css";

interface ChangePasswordPageProps extends RouteComponentProps {}

@inject("appState")
@observer
class ChangePasswordPage extends React.Component<
  ChangePasswordPageProps & AppStateObserver
> {
  state = {
    links: [
      {
        path: "/",
        name: "Main",
      },
      {
        path: "/change-password",
        name: "changePassword",
      },
    ],
  };

  logout = () => {
    this.props.appState.userStore.logout();
  };

  componentDidMount() {
    this.props.appState.breadcrumbsStore.setLinks(this.state.links);
  }

  componentWillUnmount() {
    this.props.appState.breadcrumbsStore.clearLinks();
  }

  render() {
    const { language } = this.props.appState.userStore;

    return (
      <div style={{ paddingBottom: "60px" }}>
        <h1 className="title-changePassword">
          <Trans>changePassword</Trans>
        </h1>
        <div className="box-ChangePassword">
          <Route path="/change-password" component={ChangePassword} />
        </div>
      </div>
    );
  }
}

export default ChangePasswordPage;
