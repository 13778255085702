import memoizeOne from 'memoize-one';

class Section {
  private sections: string[] = [];
  private subSections: Record<string, string[]> = {};
  private currentSection: string = '';

  constructor() {}

  public renderSection = memoizeOne((section) => {
    if (!this.sections.includes(section)) this.sections.push(section);

    this.currentSection = section;

    return this.convert(this.sections.findIndex((i) => i === section) + 1);
  });

  public renderSubSection = memoizeOne((section) => {
    if (!this.subSections[this.currentSection])
      this.subSections[this.currentSection] = [];

    if (!this.subSections[this.currentSection].includes(section))
      this.subSections[this.currentSection].push(section);

    return (
      this.subSections[this.currentSection].findIndex((i) => i === section) + 1
    );
  });

  public clearSections = () => {
    this.sections = [];
    this.subSections = {};
    this.currentSection = '';
  };

  public convert(num) {
    const c = [
      ['', 'I', 'II', 'III', 'IV', 'V', 'VI', 'VII', 'VIII', 'IX'],
      ['', 'X', 'XX', 'XXX', 'XL', 'L', 'LX', 'LXX', 'LXXX', 'XC'],
      ['', 'C', 'CC', 'CCC', 'CD', 'D', 'DC', 'DCC', 'DCCC', 'CM'],
      ['', 'M', 'MM', 'MMM'],
    ];

    return (
      c[3][Math.floor((num / 1000) % 10)] +
      c[2][Math.floor((num / 100) % 10)] +
      c[1][Math.floor((num / 10) % 10)] +
      c[0][Math.floor(num % 10)]
    );
  }
}

export const sectionNumber = new Section();
