import {
  action,
  reaction,
  computed,
  observable,
  runInAction,
  toJS,
} from "mobx";
import { cubaREST } from "../cubaREST";
import { toast } from "react-toastify";
import i18next from "i18next";
import { errorTranslateForApplicantType } from "helpers/tranlsateApplicantTypeErrors";
import Notify from "../helpers/Notifier";

export class ApplicantPart {
  static ENTITY_NAME = "fsr_Applicant";
  static VIEW = "applicant-edit-view";
}

export class ApplicantsStore {
  rootStore;
  @observable applicant: any = null;
  @observable count: number;
  @observable loading = false;
  @observable entities = [];
  @observable entitiesOne = [];
  @observable isTypeChanging = false;

  constructor(rootStore) {
    this.rootStore = rootStore;
  }

  @computed
  private get loadOptions() {
    return {
      view: ApplicantPart.VIEW,
    };
  }

  @action
  loadEntities = () => {
    this.loading = true;
    cubaREST
      .loadEntitiesWithCount("fsr_Applicant", this.loadOptions)
      .then((resp) => {
        runInAction(() => {
          this.count = resp.count;
          this.entities = resp.result;
          this.loading = false;
        });
      })
      .catch(
        action(() => {
          this.loading = false;
        })
      );
  };

  @action
  loadApplicant = () => {
    this.loading = true;
    return cubaREST
      .searchEntitiesWithCount(
        "fsr_Applicant",
        {
          conditions: [
            {
              property: "email",
              operator: "startsWith",
              value: localStorage.getItem("applicant"),
            },
          ],
        },
        this.loadOptions
      )
      .then(
        action((resp) => {
          this.applicant = resp.result[0];
          this.loading = false;
        })
      )
      .catch(
        action(() => {
          this.loading = false;
        })
      );
  };

  @action
  loadApplicant2 = (email) => {
    return cubaREST.searchEntities("fsr_Applicant", {
      conditions: [
        {
          property: "email",
          operator: "startsWith",
          value: email,
        },
      ],
    });
  };
  @action
  createApplicant = (form) => {
    return cubaREST.commitEntity("fsr_Applicant", form);
  };

  //TODO isOnlyTypeChange is for type change from third person only because otherwise it breaks change to GRADUATE_NU
  @action
  updateApplicant = (form, isOnlyTypeChange = false) => {
    if (form.applicantType === "STUDENT") {
      form.organization = null;
      form.staff = null;
      form.graduateNIS = null;
      form.graduateNU = null;
      form.thirdPerson = null;
    }
    if (form.applicantType === "EMPLOYEE") {
      form.organization = null;
      form.student = null;
      form.graduateNIS = null;
      form.graduateNU = null;
      form.thirdPerson = null;
    }
    if (form.applicantType === "ORGANIZATION") {
      form.staff = null;
      form.student = null;
      form.graduateNIS = null;
      form.graduateNU = null;
      form.thirdPerson = null;
    }
    if (form.applicantType === "THIRD_PERSON") {
      form.organization = null;
      form.staff = null;
      form.student = null;
      form.graduateNU = null;
      form.graduateNIS = null;
      delete form.thirdPerson.id;
      if (!form.thirdPerson.isWork) {
        form.thirdPerson.isWork = false;
      }
      if (form.thirdPerson.isWork === true) {
        form.thirdPerson.organization = null;
        form.thirdPerson.position = null;
        form.thirdPerson.department = null;
      }
    }

    if (form.applicantType === "GRADUATE_NIS") {
      form.organization = null;
      form.staff = null;
      form.student = null;
      form.graduateNU = null;
      form.thirdPerson = null;
      delete form.graduateNIS.id;

      if (!form.graduateNIS.isWork) {
        form.graduateNIS.isWork = false;
      }
      if (form.graduateNIS.isWork === true) {
        form.graduateNIS.organization = null;
        form.graduateNIS.position = null;
        form.graduateNIS.department = null;
      }
    }
    if (form.applicantType === "GRADUATE_NU") {
      form.organization = null;
      form.staff = null;
      form.student = null;
      form.graduateNIS = null;
      form.thirdPerson = null;

      // set inital faculty as GSB
      if (!form.graduateNU.faculty || form.graduateNU.faculty === "") {
        form.graduateNU.faculty = "GSB";
      }

      if (!isOnlyTypeChange) {
        delete form.graduateNU.id;
        if (!form.graduateNU.isWork) {
          form.graduateNU.isWork = false;
        }
        if (form.graduateNU.isWork === true) {
          form.graduateNU.organization = null;
          form.graduateNU.position = null;
          form.graduateNU.department = null;
        }
      }
    }
    return cubaREST
      .invokeService("fsr_ApplicantUserService", "saveApplicant", {
        applicant: form,
      })
      .then(
        action((res) => {
          let result = res as string;
          let status = JSON.parse(result).status;
          let message = JSON.parse(result).message
            ? JSON.parse(result).message
            : "Произошла ошибка";

          if (status === "SUCCESS") {
            Notify.pushSuccessMessage(i18next.t("Success"));
            this.loadEntities();
            const newApplicant = form.email;
            localStorage.setItem("applicant", newApplicant);
            this.rootStore.userStore.getUserInfo();
            this.loadApplicant();
          } else if (status === "ERROR") {
            Notify.pushErrorMessage(message);
          } else if (status === "WARNING") {
            Notify.pushWarningMessage(message);
          } else if (status === "EMAIL_ERROR") {
            Notify.pushErrorMessage(message);
          } else if (status === "ERROR_IIN_DUPLICATE") {
            Notify.pushErrorMessage(
              i18next.t("changeProfile.uinDuplicate", { ns: "errors" }),

            );
          } else if (status === "ERROR_EMAIL_DOMAIN_GRADUATE_NU") {
            Notify.pushErrorMessage(i18next.t("emailErrorNuGrad"));
          } else if (status === "ERROR_APPLICANT_TYPE_CHANGE") {
            const message = errorTranslateForApplicantType(
              JSON.parse(result).from,
              JSON.parse(result).to
            );
            Notify.pushErrorMessage(message);
            //ERROR_EMAIL_DOMAIN_STUDENT_NU
          } else if (status === "ERROR_EMAIL_DOMAIN_STUDENT_NU") {
            Notify.pushErrorMessage(i18next.t("emailError"));
          }
          return status;
        })
      )
      .catch(
        action((error) => {
          if (!cubaREST.restApiToken) {
            window.location.reload();
          }
          Notify.pushErrorMessage("Ошибка сервера");
          return "FAIL";
        })
      );
  };
  @action
  checkPhoneDuplicate = (form) => {
    return cubaREST
      .invokeService(
        "fsr_ApplicantUserService",
        "checkApplicantPhoneNumberDuplicate",
        form
      )
      .then((res) => {
        const result = JSON.parse(res as string);
        const status = result.status;
        let message = result.message ? result.message : "Произошла ошибка";
        if (status === "SUCCESS") {
          return true;
        } else if (status === "ERROR") {
          Notify.pushErrorMessage(message);
          return false;
        } else if (status === "ERROR_PHONE_NUMBER_DUPLICATE") {
          Notify.pushErrorMessage(
            i18next.t("changeProfile.phoneDuplicate", { ns: "errors" })
          );
          return false;
        }
      })
      .catch((error) => {
        Notify.pushErrorMessage(i18next.t("Error"));
        return false;
      });
  };
  @action
  updateApplicant2 = (form) => {
    cubaREST
      .commitEntity("fsr_Applicant", form)
      .then(
        action((res) => {
          this.loadEntities();
          Notify.pushSuccessMessage(i18next.t("Success"));
          console.log(toJS(form));
          const newApplicant = form.email;
          localStorage.setItem("applicant", newApplicant);
          this.applicant = res;
        })
      )
      .catch(
        action((error) => {
          Notify.pushErrorMessage(i18next.t("Error"));
        })
      );
  };

  @action
  registerApplicant = (applicant) => {
    return cubaREST.invokeService(
      "fsr_ApplicantUserService",
      "registerApplicantAndUser",
      { applicant: applicant }
    );
  };

  @action
  updateProperty(key, value) {
    this.applicant[key] = value;
  }

  @action
  changeEmail = (email) => {
    return cubaREST.invokeService(
      "fsr_ApplicantUserUtilsService",
      "changeEmail",
      { email, applicant: this.applicant }
    );
  };
  @action
  verifyEmail = (code) => {
    return cubaREST.invokeService(
      "fsr_ApplicantUserUtilsService",
      "verifyEmail",
      { verificationCode: code }
    );
  };
}
