import * as React from "react";
import { observer } from "mobx-react";
import { Route } from "react-router-dom";
import { AppStateObserver, injectAppState } from "../../stores";
import Applications from "../../components/Applications";
import { Trans } from "react-i18next";

export interface CabinetProps {}

export interface CabinetState {}

@injectAppState
@observer
class ApplicationsPage extends React.Component<
  AppStateObserver,
  CabinetProps,
  CabinetState
> {
  state = {
    links: [
      {
        path: "/",
        name: "Main",
      },
      {
        path: "/myApplications",
        name: "myApplications",
      },
    ],
  };

  logout = () => {
    this.props.appState.userStore.logout();
  };

  componentDidMount() {
    this.props.appState.breadcrumbsStore.setLinks(this.state.links);
  }

  componentWillUnmount() {
    this.props.appState.breadcrumbsStore.clearLinks();
  }

  render() {
    const { language } = this.props.appState.userStore;

    return (
      <div style={{ paddingBottom: "60px" }}>
        <h1 style={{ marginBottom: "30px" }} className="title__cabinet">
          <Trans>myApplications</Trans>
        </h1>

        <Route path="/applications" component={Applications} />
      </div>
    );
  }
}

export default ApplicationsPage;
