import { Trans } from "react-i18next";
import React, { useState } from "react";
import { inject } from "mobx-react";
import MiniLoad from "components/MiniLoad/MiniLoad";
import { BsArrowRight } from "react-icons/bs";
const NotifyTeamMembersButton = inject("appState")(props => {
  const [isLoading, setLoading] = useState(false);
  const isInApplications = props.isInApplications;

  const sendNotification = () => {
    if (props.appID) {
      props.appState.applicationsStore.sendNotificationTeamMembers(
        props.appID,
        setLoading
      );
    } else {
      console.log("id is incorrect");
    }
  };

  return (
    <div
      style={{
        display: "flex",
        alignItems: "center",
      }}
    >
      <button
        className={
          isInApplications
            ? "SendNotifications-btn"
            : "button-input btn btn-save"
        }
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          textAlign: "left",
        }}
        onClick={sendNotification}
      >
        <Trans>SendNotifications</Trans>
        {isLoading && (
          <MiniLoad
            style={{
              border: isInApplications
                ? "2px solid #007e6d"
                : "2px solid white",
            }}
          />
        )}
        {!isLoading && isInApplications && (
          <BsArrowRight style={{ width: "30px", marginLeft: "-3px" }} />
        )}
      </button>
    </div>
  );
});

export default NotifyTeamMembersButton;
