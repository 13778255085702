const applicantChangeMatrix = {
  STUDENT: [
    {
      name: "STUDENT",
      caption: "Student",
    },
    {
      name: "EMPLOYEE",
      caption: "Employee",
    },
    // {
    //   name: "GRADUATE_NIS",
    //   caption: "GraduateNIS",
    // },
    {
      name: "GRADUATE_NU",
      caption: "GraduateNU",
    },
  ],

  GRADUATE_NU: [
    {
      name: "GRADUATE_NU",
      caption: "GraduateNU",
    },
    {
      name: "EMPLOYEE",
      caption: "Employee",
    },

    // {
    //   name: "GRADUATE_NIS",
    //   caption: "GraduateNIS",
    // },
  ],

  GRADUATE_NIS: [
    // {
    //   name: "GRADUATE_NIS",
    //   caption: "GraduateNIS",
    // },
    {
      name: "EMPLOYEE",
      caption: "Employee",
    },
    {
      name: "STUDENT",
      caption: "Student",
    },
    {
      name: "GRADUATE_NU",
      caption: "GraduateNU",
    },
  ],

  EMPLOYEE: [
    {
      name: "EMPLOYEE",
      caption: "Employee",
    },
    {
      name: "STUDENT",
      caption: "Student",
    },

    // {
    //   name: "GRADUATE_NIS",
    //   caption: "GraduateNIS",
    // },
    {
      name: "GRADUATE_NU",
      caption: "GraduateNU",
    },
  ],
};

const getApplicantChangeStatuses = (status: string) => {
    if(!(status in applicantChangeMatrix)) {
        return [];
    } else {
        return applicantChangeMatrix[status];
    }
}
export default getApplicantChangeStatuses;

/**
 * [
        {
            "name": "EMPLOYEE",
            "caption": "Employee"
        },
        {
            "name": "STUDENT",
            "caption": "Student"
        },
        {
            "name": "GRADUATE_NIS",
            "caption": "GraduateNIS"
        },
        {
            "name": "GRADUATE_NU",
            "caption": "GraduateNU"
        }
    ],
 *
 *
 *
 */
