import * as React from 'react';
import { format } from 'date-fns';
import './ApplicationInfo.css';
import { AppStateObserver, injectAppState } from '../../stores';
import { observer } from 'mobx-react';
import { Trans } from 'react-i18next';
import localeChanger from '../../helpers/localeChanger';
import { Container } from 'react-bootstrap';
import i18n from 'i18next';

export interface ApplicationInfoProps {
  application;
}

export interface ApplicationInfoState {}

@injectAppState
@observer
class ApplicationInfo extends React.Component<
  ApplicationInfoProps & AppStateObserver,
  ApplicationInfoState
> {
  state = {
    stars: null,
  };

  // async loadEntities() {
  //   let stars = await this.props.appState.applicationsStore.getStarsForApplication();
  //   this.setState({
  //     stars
  //   })
  // }
  componentDidMount() {
    // this.loadEntities();
  }

  render() {
    const { application } = this.props;
    const { stars } = this.state;
    const { language } = this.props.appState.userStore;
    const localeDate = localeChanger(i18n.language ? i18n.language : 'ru');
    return (
      <React.Fragment>
        <section className="status__tab1">
          <p className="portal__text">
            <Trans>ApplicationNumber</Trans>
          </p>
          <p className="portal__pi__text">{application.serialNumber}</p>
          <p className="portal__text">
            <Trans>Status</Trans>
          </p>
          <p className="portal__pi__text">
            <Trans>{application.applicationStatus}</Trans>
          </p>
          {stars && (
            <>
              <p className="portal__text">
                <Trans>YourStars</Trans>
              </p>
              <p className="portal__pi__text">
                <Trans>{application.applicationStatus}</Trans>
              </p>
            </>
          )}
          <>
            <p className="portal__text">
              <Trans>Applicant</Trans>
            </p>
            <p className="portal__pi__text">
              {application.applicant.fio}
              {/*<Trans>{application.applicationStatus}</Trans>*/}
            </p>
          </>
          <>
            <p className="portal__text">
              <Trans>ApplyDate</Trans>
            </p>
            <p className="portal__pi__text">
              {application.dateApply
                ? format(Date.parse(application.dateApply), 'dd MMMM u', {
                    locale: localeDate,
                  })
                : format(
                    Date.parse(application.dateRegistration),
                    'dd MMMM u',
                    {
                      locale: localeDate,
                    }
                  )}
            </p>
          </>
        </section>
      </React.Fragment>
    );
  }
}

export default ApplicationInfo;
