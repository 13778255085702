import { PG, SP } from '../../consts';
import { Trans } from 'react-i18next';
import React from 'react';

const renderHeader = ({ prog }) => {
  if (prog) {
    if (prog === SP || prog === PG) {
      return <Trans>PROJECTIDEA</Trans>;
    }

    return <Trans>ACTUALNOST</Trans>;
  }

  return <Trans>ACTUALNOST</Trans>;
};

export default renderHeader;
