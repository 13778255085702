import React from "react";
import { ProgressBar } from "react-bootstrap";
import useProgressBar from "../../helpers/useProgressBar";

const CustomProgressBar = () => {
  const progress = useProgressBar();
  const barColor = progress > 0 ? "#61ce70" : "#e9ecef";
  const textColor = progress > 0 ? "#fff" : "#000";
  const minWidth = progress > 0 ? "20px" : "0";
  return (
    <div className="custom-progress-container">
      <div
        className="custom-progress-bar"
        style={{
          width: `${progress}%`,
          backgroundColor: barColor,
          minWidth: minWidth,
        }}
      >
        {progress > 0 && (
          <span
            className="progress-label"
            style={{ color: textColor }}
          >{`${progress}%`}</span>
        )}
      </div>
      {progress === 0 && (
        <div className="progress-label-container">
          <span
            className="progress-label"
            style={{ color: textColor }}
          >{`${progress}%`}</span>
        </div>
      )}
    </div>
  );
};

export default CustomProgressBar;
