export interface ITeamMember {
  email: string;
  iin: string;
  fio: string;
  workExperience: string;
  role: string;
  resume?: {
    file: IFile;
  };
}

export interface IImageFile {
  resume?: {
    file: IFile;
  };
}

export interface IFile {
  id: string;
  name: string;
  size?: number;
}

export interface IIndicator {
  name: string;
  unit: string;
  ultimateGoal: number | string;
}

export enum EApplicantKind {
  organization = "ORGANIZATION",
  individual = "INDIVIDUAL",
}

export interface IApplicationForm {
  application?: Record<string, any>;
  competition?: Record<string, any>;
  applicant?: Record<string, any>;
  socialCategory?: {
    name: string;
    id: string;
  };
  applicationStatus: string;
  applicationForm: {
    projectName: string;
    organizationName: string;
    activityDirection: string;
    projectDuration: string;
    applicantKind: EApplicantKind;
    organizationBin: string;
  };
  id: string;
  environmental: {
    environmental: string;
    methods: string;
    friendliness: string;
  };
  resource: {
    materialBase: [];
    budget: [];
    selfBudget: [];
    partners: string;
  };
  organizationPotential: {
    operatingBusiness?: string;
    financialExpenses?: any[];
    financialRevenue?: any[];
    file?: any;
  };
  innovativeness: {
    innovation: string;
    competitors: string;
  };
  expense: Record<string, any>[];
  sustainability?: Record<string, any>;
  relevance?: {
    application_id: string;
    socialProblem: string;
  };
  programs?: Record<string, any>[];
  projectIdea: {
    socialProblem: string;
    motivationalQuestion: string;
    problemSolving: string;
    beneficiaries: string;
    indicators: IIndicator[];
    calendarPlan: Record<string, any>[];
    geography?: string;
    viability: string;
  };
  businessModel: {
    financialModel: {
      expenditure: string;
      costAndVolume: string;
      incomeSource: string;
      incomeDistribution: string;
    };
    clientPortrait?: string;
    marketingChannels?: string;
    product?: string;
  };
  teamMembers: ITeamMember[];
  scalability: {
    indicators: IIndicator[];
    calendarPlan: Record<string, any>[];
  };
  appDocs?: Record<string, any>[];
  direction: Record<string, any>;
  effectiveness: {
    indicators: IIndicator[];
    calendarPlan: Record<string, any>[];
  };
  businessPlan: Record<string, any>;
  checkProgram?: boolean;
  courseCertificate?: any
}

export interface IApplicationFormContext {
  applicationForm: IApplicationForm;
  applicant?: Record<string, any>;
  application?: any;
  saveAndCheckApplicationBeforeSign?: (procTaskId?: string) => Promise<any>;
  handleApplicationFormChange?: ({}) => void;
  saveApplication?: (procTaskId: string, type: string) => void;
  signApplication?: (procTaskId: string, file, password: string) => void;
  clearApplicationForm?: ({}) => void;
  clearData?: ({}) => void;
  isFormChanged?: boolean;
  readOnly?: boolean;
  errors?: Record<string, string>;
  getError?: (errorKey: string) => string | undefined;
  clearError?: (errorKey: string) => void;
  isValid?: boolean;
  verifyApplication?: Function;
  notifyTeamMembersAfterSign?: Function;
  resetApplicationForm?: Function;
}

export interface IProgram {
  id: string;
  name: string;
}
