import React from 'react';
import {Trans} from 'react-i18next';
import {IG, PG, SE, SG, SP} from '../../consts';
import Plan from '../Tables/Plan';
import Indicators from '../Tables/Indicators';
import renderHeader from './title';
import BeneficiaryText from 'components/FieldRelevance/BeneficiaryText';
import {injectAppState} from '../../stores';
import {observer} from 'mobx-react';
import {ApplicationFormContext} from '../ApplicationFormProvider';
import Textarea from '../Ui/Textarea';
import {sectionNumber} from '../ApplicationFormProvider/sectionNumber';

@injectAppState
@observer
class FieldProjectIdea extends React.Component<any, any> {
  static contextType = ApplicationFormContext;

  handleInputChange = (event) => {
    const target = event.target;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const name = target.name;

    // this.context.clearError(name);
    this.context.handleApplicationFormChange({
      projectIdea: {[name]: value},
    });
  };

  handleDeletePlan = (plan) => {
    this.context.handleApplicationFormChange({
      projectIdea: {calendarPlan: plan},
    });
  };

  handlePlanChange = (plan) => {
    this.context.handleApplicationFormChange({
      projectIdea: {calendarPlan: plan},
    });
  };

  render() {
    const programName = this.props.program.name;
    const {language} = this.props.appState.userStore;
    const {
      marketingChannels,
      customers,
      projectPartners,
      motivationalQuestion,
      problemSolving,
      viability,
      beneficiaries,
      socialProblem,
      geography,
    } = this.context.applicationForm.projectIdea;
    const {readOnly} = this.context;

    return (
      <>
        <div className="compform__divider"/>
        <h1 className="main__question">
          {sectionNumber.renderSection('IDEA')}. {renderHeader(programName)}
        </h1>

        {/*FOR FUTURE MAY Be*/}
        {/*{(programName === SP || programName === PG) && (*/}
        {/*  <p className="form__question__descrption">*/}
        {/*    <Trans>projectIdea.projectIdeaExtra</Trans>*/}
        {/*  </p>*/}
        {/*)}*/}

        <h3 className="form__question">
          {sectionNumber.renderSubSection('socialProblem')}.{' '}
          <Trans>socialProblem</Trans>
        </h3>
        <p className="form__question__descrption">
          <Trans>socialProblemExtra</Trans>
        </p>
        <Textarea
          defaultValue={socialProblem}
          name="socialProblem"
          onChange={this.handleInputChange}
          readOnly={readOnly}
        />

        {programName !== SP && programName !== IG && (
          <>
            <h3 className="form__question">
              {sectionNumber.renderSubSection('couse')}. <Trans>couse</Trans>
            </h3>
            <p className="form__question__descrption">
              <Trans>couseExtra</Trans>
            </p>

            <Textarea
              defaultValue={motivationalQuestion}
              name="motivationalQuestion"
              onChange={this.handleInputChange}
              readOnly={readOnly}
            />
          </>
        )}

        {programName !== IG && (
          <>
            <h3 className="form__question">
              {sectionNumber.renderSubSection('projectIdea.problemSolving')}.{' '}
              <Trans>projectIdea.problemSolving</Trans>
            </h3>
            <p className="form__question__descrption">
              {programName === SP ? (
                <Trans>projectIdea.problemSolvingExtraSP</Trans>
              ) : (
                <Trans>projectIdea.problemSolvingExtra</Trans>
              )}
            </p>
            <Textarea
              defaultValue={problemSolving}
              name="problemSolving"
              onChange={this.handleInputChange}
              readOnly={readOnly}
            />
          </>
        )}

        {programName === SG && (
          <>
            <h3 className="form__question">
              {sectionNumber.renderSubSection('projectIdea.results')}.{' '}
              <Trans>projectIdea.results</Trans>
            </h3>
            <p className="form__question__descrption">
              <Trans>projectIdea.resultsExtra</Trans>
            </p>

            <Textarea
              defaultValue={viability}
              name="viability"
              onChange={this.handleInputChange}
              readOnly={readOnly}
            />
          </>
        )}

        {/*7. География реализации*/}
        {(programName === SG || programName === SP) && (
          <>
            <h3 className="form__question">
              {sectionNumber.renderSubSection('territory')}.{' '}
              <Trans>territory</Trans>
            </h3>
            <p className="form__question__descrption">
              <Trans>territoryExtra</Trans>
            </p>

            <Textarea
              defaultValue={geography}
              name="geography"
              onChange={this.handleInputChange}
              readOnly={readOnly}
            />
          </>
        )}

        {programName !== SE &&
          <>
            {/*Благополучатели*/}
            <h3 className="form__question">
              {sectionNumber.renderSubSection('beneficiaty')}.{' '}
              <Trans>beneficiaty</Trans>
            </h3>
            <p className="form__question__descrption">
              <Trans>beneficiatyExtra</Trans>
            </p>

            <Textarea
              defaultValue={beneficiaries}
              name="beneficiaries"
              onChange={this.handleInputChange}
              readOnly={readOnly}
            />
          </>
        }

        {/*Жизнеспособность проекта*/}
        {programName === SE &&
          <>
            <h3 className="form__question">
              {sectionNumber.renderSubSection('projectIdea.results')}.{' '}
              <Trans>projectIdea.results</Trans>
            </h3>
            <p className="form__question__descrption">
              <Trans>projectIdea.resultsExtra</Trans>
            </p>

            <Textarea
              defaultValue={viability}
              name="viability"
              onChange={this.handleInputChange}
              readOnly={readOnly}
            />
          </>
        }

        {/*7. География реализации*/}
        {programName === SE && (
          <>
            <h3 className="form__question">
              {sectionNumber.renderSubSection('territory')}.{' '}
              <Trans>territory</Trans>
            </h3>
            <p className="form__question__descrption">
              <Trans>territoryExtra</Trans>
            </p>

            <Textarea
              defaultValue={geography}
              name="geography"
              onChange={this.handleInputChange}
              readOnly={readOnly}
            />
          </>
        )}

        {/*Благополучатели*/}
        {programName === SE &&
          <>
            <h3 className="form__question">
              {sectionNumber.renderSubSection('beneficiaty')}.{' '}
              <Trans>beneficiaty</Trans>
            </h3>
            <p className="form__question__descrption">
              <Trans>beneficiatyExtra</Trans>
            </p>

            <Textarea
              defaultValue={beneficiaries}
              name="beneficiaries"
              onChange={this.handleInputChange}
              readOnly={readOnly}
            />
          </>
        }

        {programName === IG && (
          <>
            <h3 className="form__question">
              {sectionNumber.renderSubSection('couse')}. <Trans>couse</Trans>
            </h3>
            <p className="form__question__descrption">
              <Trans>couseExtra</Trans>
            </p>

            <Textarea
              defaultValue={motivationalQuestion}
              name="motivationalQuestion"
              onChange={this.handleInputChange}
              readOnly={readOnly}
            />

            <h3 className="form__question">
              {sectionNumber.renderSubSection('territory')}.{' '}
              <Trans>territory</Trans>
            </h3>
            <p className="form__question__descrption">
              <Trans>territoryExtra</Trans>
            </p>

            <Textarea
              defaultValue={geography}
              name="geography"
              onChange={this.handleInputChange}
              readOnly={readOnly}
            />
          </>
        )}

        {programName !== SE && programName !== SP && programName !== IG && programName !== SG && (
          <>
            <h3 className="form__question">
              {sectionNumber.renderSubSection('projectIdea.customers')}.{' '}
              <Trans>projectIdea.customers</Trans>
            </h3>
            <p className="form__question__descrption">
              <Trans>projectIdea.customersExtra</Trans>
            </p>

            <Textarea
              defaultValue={customers}
              name="customers"
              onChange={this.handleInputChange}
              readOnly={readOnly}
            />
            <h3 className="form__question">
              {sectionNumber.renderSubSection('projectIdea.marketingChannels')}.{' '}
              <Trans>projectIdea.marketingChannels</Trans>
            </h3>
            <p className="form__question__descrption">
              <Trans>projectIdea.marketingChannelsExtra</Trans>
            </p>

            <Textarea
              defaultValue={marketingChannels}
              name="marketingChannels"
              onChange={this.handleInputChange}
              readOnly={readOnly}
            />

            <h3 className="form__question">
              {sectionNumber.renderSubSection('projectIdea.projectPartners')}.{' '}
              <Trans>projectIdea.projectPartners</Trans>
            </h3>
            <p className="form__question__descrption">
              <Trans>projectIdea.projectPartnersExtra</Trans>
            </p>

            <Textarea
              defaultValue={projectPartners}
              name="projectPartners"
              onChange={this.handleInputChange}
              readOnly={readOnly}
            />
          </>
        )}

        {programName === SP && (
          <>
            <h3 className="form__question">
              {sectionNumber.renderSubSection('couse')}. <Trans>couse</Trans>
            </h3>
            <p className="form__question__descrption">
              <Trans>couseExtra</Trans>
            </p>

            <Textarea
              defaultValue={motivationalQuestion}
              name="motivationalQuestion"
              onChange={this.handleInputChange}
              readOnly={readOnly}
            />
          </>
        )}

        {programName === SE && (
          <>
            <Indicators program={this.props.program} namespace="projectIdea" />
            <Plan program={this.props.program} namespace="projectIdea" />
          </>
        )}
      </>
    );
  }
}

export default FieldProjectIdea;
