import { IG } from '../../consts';
import { Trans } from 'react-i18next';
import React from 'react';

const renderTitle = (prog) => {
  if (prog === IG) {
    return <Trans>BMforIG</Trans>;
  }

  return <Trans>BM</Trans>;
};

export default renderTitle;
