import { useState, useEffect } from "react";

const ReCAPTCHA = () => {
  const key = "6LcNwp4nAAAAAL0wTW0-Yhqg97GH9jdg9i-ojaiZ";
  const recaptchaId = "recaptcha-script";

  const [captchaIsDone, setCaptchaIsDone] = useState(false);

  useEffect(() => {
    const script = document.createElement("script");
    script.id = recaptchaId;
    script.src = `https://www.google.com/recaptcha/api.js?render=${key}`;
    script.async = true;
    document.head.appendChild(script);

    return () => {
      const existingScript = document.getElementById(recaptchaId);
      if (existingScript) {
        document.head.removeChild(existingScript);
      }
    };
  }, []);

  useEffect(() => {
    if (!captchaIsDone) {
      if (typeof window.grecaptcha !== "undefined") {
        window.grecaptcha.ready(() => {
          window.grecaptcha.execute(key, { action: "submit" }).then(token => {
            if (token) {
              setCaptchaIsDone(true);
            }
          });
        });
      }
    }
  }, [captchaIsDone]);

  useEffect(() => {
    if (captchaIsDone) {
      console.log("CAPTCHA verification completed!");
    }
  }, [captchaIsDone]);
  useEffect(() => {
    const style = document.createElement("style");
    style.className = "grecaptcha-badge";
    style.innerHTML = `
      .grecaptcha-badge {
        display: none !important;
      }
    `;
    document.head.appendChild(style);

    return () => {
      const existingStyle = document.querySelector(".grecaptcha-badge");
      if (existingStyle && existingStyle.parentNode === document.head) {
        document.head.removeChild(existingStyle);
      }
    };
  }, []);

  return null;
};

export default ReCAPTCHA;

// ReCAPTHCA
