import React from 'react';
import { Trans } from 'react-i18next';
import Indicators from '../Tables/Indicators';
import Plan from '../Tables/Plan';
import { IG } from '../../consts';
import { ApplicationFormContext } from '../ApplicationFormProvider';
import Textarea from '../Ui/Textarea';
import { sectionNumber } from '../ApplicationFormProvider/sectionNumber';

class FieldScalability extends React.Component<any, any> {
  static contextType = ApplicationFormContext;

  handleInputChange = (event) => {
    const target = event.target;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const name = target.name;

    this.context.handleApplicationFormChange({
      scalability: { [name]: value },
    });
  };

  handlePlanChange = (calendarPlan) => {
    this.context.handleApplicationFormChange({ calendarPlan });
  };

  render() {
    const { readOnly } = this.context;
    const { goal, idea } = this.context.applicationForm.scalability;
    const programName = this.props.program.name;

    return (
      <>
        <div className="compform__divider" />
        <h1 className="main__question">
          {sectionNumber.renderSection('SCALABILITY')}.{' '}
          <Trans>scalability.scalability</Trans>
        </h1>

        <h3 className="form__question">
          {sectionNumber.renderSubSection('scalability.idea')}.{' '}
          <Trans>scalability.idea</Trans>
        </h3>
        <p className="form__question__descrption">
          <Trans>scalability.ideaExtra</Trans>
        </p>

        <Textarea
          defaultValue={idea}
          name="idea"
          onChange={this.handleInputChange}
          readOnly={readOnly}
        />

        <h3 className="form__question">
          {sectionNumber.renderSubSection('scalability.goal')}.{' '}
          <Trans>scalability.goal</Trans>
        </h3>
        <p className="form__question__descrption">
          <Trans>scalability.goalExtra</Trans>
        </p>

        <Textarea
          defaultValue={goal}
          name="goal"
          onChange={this.handleInputChange}
          readOnly={readOnly}
        />
        <>
          {programName == IG && (
            <Indicators program={this.props.program} namespace="scalability" />
          )}

          <Plan program={this.props.program} namespace="scalability" />
        </>
      </>
    );
  }
}

export default FieldScalability;
