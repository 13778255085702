const isCompetitionDeadline = (endDateString: string) => {
  const offset = 6;

  const d = new Date();

  const utc = d.getTime() + d.getTimezoneOffset() * 60000;

  const nd = utc + 3600000 * offset;

  const endDate = Date.parse(endDateString);
  const applyDate = nd;

  return applyDate > endDate;
};

export { isCompetitionDeadline };
