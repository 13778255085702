export const SI = 'Social Invest';
export const SE = 'Social Entrepreneurship Project';
export const SP = 'Social Project';
export const IG = 'Impulse Grant';
export const SG = 'Start Grant';
export const PG = 'Idea Grant';
 export const applicationActiveStatuses = [
  'NEW',
  'PENDING',
  'REWORK_DOCS',
  'TEAMLEAD_REWORK',
  'REVISION_AFTER_VALIDATION',
  'BUSINESS_PLAN',
];

export const applicationReworkStatuses = [
  'REWORK',
];
export const applicationCompleteStatuses = [
  'INTERNAL_VOTING_DIRECTION',
  'PHASE_1_FINISHED',
  'VALIDATION',
  'INTERNAL_VOTING',
  'WORK_ORGAN',
  'JAS_CAMP',
  'EXTERNAL_VOTING_DIRECTION',
  'EXTERNAL_VOTING',
  'PERMISSION',
  'PASSED',
  'VOTING'
];

export const applicationFailStatuses = [
  'NOT_PASSED',
  'REFUSED',
  'REFUSED_BY_APPLICANT',
];

export const applicationByRevisionDateStatuses = [
  'REWORK', "WORK_ORGAN", "INTERNAL_VOTING_DIRECTION" , "VOTING", "PERMISSION" , "PASSED"
]
