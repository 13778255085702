import * as React from "react";
import "../Competition/Competition.css";
import { Link } from "react-router-dom";
import { format, differenceInCalendarDays } from "date-fns";
import { Trans } from "react-i18next";
import { observer } from "mobx-react";
import { AppStateObserver, injectAppState } from "../../stores";
import { toast } from "react-toastify";
import Popup from "reactjs-popup";
import { cubaREST } from "../../cubaREST";
import i18next from "i18next";
import localeChanger from "../../helpers/localeChanger";
import i18n from "i18next";
import {
  applicationActiveStatuses,
  applicationCompleteStatuses,
  applicationReworkStatuses,
} from "consts";
import {
  BsArrowClockwise,
  BsArrowRight,
  BsCheckCircle,
  BsInfoCircle,
  BsPencil,
} from "react-icons/bs";
import Notify from "../../helpers/Notifier";

export interface SentApplicationViewProps {
  name: string;
  dateStart: string;
  dateEnd: string;
  id: string;
  status: string;
  info: string;
  isMainPage?: boolean;
  appId: string;
  anketaOk: any;
  applicationStatus?: string;
}

export interface SentApplicationViewState {}

@injectAppState
@observer
class SentApplicationView extends React.Component<
  AppStateObserver & SentApplicationViewProps,
  {}
> {
  public static defaultProps = {
    isMainPage: false,
  };

  componentDidMount() {
    if (this.props.appId)
      this.props.appState.applicationsStore.loadEntity(this.props.appId);
  }

  handleNotActive = () => {
    Notify.pushErrorMessage(i18next.t("not_active_to_apply"));
  };

  // deny
  buttonDeny = applicationId => {
    return cubaREST.invokeService("fsr_ApplicationService", "endApplication", {
      applicationId: applicationId,
    });
  };

  onClickDeny = () => {
    this.buttonDeny(this.props.appId)
      .then(() => {
        Notify.pushSuccessMessage(i18next.t("SuccessDeny"));

        this.props.appState.competitionsStore.loadCompetitions();
        this.props.appState.applicationsStore.loadEntities();
      })
      .catch(() => {
        Notify.pushErrorMessage("Возникла ошибка");
      });
  };

  denyButton = () => {
    //TODO temporary hiding
    return null;
    return (
      <Popup
        trigger={
          <button className="Deny-btn">
            <Trans>Deny</Trans> <BsArrowRight style={{ width: "30px" }} />
          </button>
        }
        modal
        closeOnDocumentClick
      >
        {close => (
          <div className="modal">
            <div className="modal__header modal-title">
              <h1>
                <Trans>areUSure</Trans>
              </h1>
            </div>
            <div className="btn__actions">
              <button
                className="button-input btn btn-success"
                onClick={this.onClickDeny}
              >
                <Trans>Yes</Trans>
              </button>
              <button
                className="button-input btn btn-light btn-popup"
                onClick={close}
              >
                <Trans>Cancel</Trans>
              </button>
            </div>
          </div>
        )}
      </Popup>
    );
  };

  render() {
    const {
      name,
      dateStart,
      dateEnd,
      id,
      status,
      info,
      appId,
      applicationStatus,
    } = this.props;
    const { language } = this.props.appState.userStore;
    const localeDate = localeChanger(i18n.language ? i18n.language : "ru");
    const competitionLasts = dateStart
      ? differenceInCalendarDays(new Date(dateStart), new Date())
      : null;
    let competitionRightComponent;

    const currentApplication =
      this.props.appState.applicationsStore.applicationList.filter(
        application => application.id === appId
      )[0] ?? this.props.appState.applicationsStore.application;

    const isLeader =
      this.props.appState.userStore.teamRole.currentApplicantId ===
      currentApplication?.applicant.id;

    switch (status) {
      case "COLLECTION_OF_APPLICATION":
        competitionRightComponent = (
          <div>
            {this.props.anketaOk ? (
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "flex-start",
                }}
              >
                {!applicationActiveStatuses
                  .concat(applicationCompleteStatuses)
                  .concat(applicationReworkStatuses)
                  .includes(currentApplication?.applicationStatus) && (
                  <Link
                    className="ViewAsMember-btn"
                    to={{ pathname: `/competition/${id}` }}
                  >
                    <Trans>Apply</Trans>{" "}
                    <BsArrowRight style={{ width: "30px" }} />
                  </Link>
                )}
                {applicationActiveStatuses
                  .concat(applicationCompleteStatuses)
                  .includes(currentApplication?.applicationStatus) && (
                  <Link
                    className="ViewAsMember-btn"
                    to={`/application/${appId}/${id}`}
                  >
                    <Trans>ViewAsMember</Trans>{" "}
                    <BsArrowRight style={{ width: "30px" }} />
                  </Link>
                )}
                {applicationReworkStatuses.includes(
                  currentApplication?.applicationStatus
                ) && (
                  <Link
                    className="ViewAsMember-btn"
                    to={`/application/${appId}/${id}`}
                  >
                    <Trans>ViewAsRework</Trans>{" "}
                    <BsArrowRight style={{ width: "30px" }} />
                  </Link>
                )}
                {applicationActiveStatuses
                  .concat(applicationCompleteStatuses)
                  .includes(currentApplication?.applicationStatus) &&
                  isLeader &&
                  this.denyButton()}
              </div>
            ) : (
              <>
                <button
                  className="ViewAsMember-btn"
                  onClick={this.handleNotActive}
                >
                  <Trans>Apply</Trans>{" "}
                  <BsArrowRight style={{ width: "30px" }} />
                </button>
              </>
            )}
          </div>
        );
        break;
      case "REVIEW":
        competitionRightComponent = (
          <div className="competition__right">
            {this.props.anketaOk ? (
              <>
                <Link
                  className="ViewAsMember-btn"
                  to={`/application/${appId}/${id}`}
                >
                  <Trans>ViewAsMember</Trans>{" "}
                  <BsArrowRight style={{ width: "30px" }} />
                </Link>
                {this.denyButton()}
                {applicationActiveStatuses.includes(
                  currentApplication?.applicationStatus
                ) &&
                  this.onClickDeny && (
                    <Link
                      className="ViewAsMember-btn"
                      style={{ marginTop: "20px" }}
                      to={{ pathname: `/competition/${id}` }}
                    >
                      <Trans>Apply</Trans>{" "}
                      <BsArrowRight style={{ width: "30px" }} />
                    </Link>
                  )}
              </>
            ) : (
              <button
                className="ViewAsMember-btn"
                onClick={this.handleNotActive}
              >
                <Trans>ViewAsMember</Trans>{" "}
                <BsArrowRight style={{ width: "30px" }} />
              </button>
            )}
            <p className="competition__last">
              {competitionLasts > 0 ? `Осталось ${competitionLasts} дней` : ""}
            </p>
          </div>
        );
        break;
      case "COMPLETED":
        competitionRightComponent = (
          <div className="competition__right">
            <Link className="results-button" to={`/application/${appId}/${id}`}>
              <Trans>ViewResults</Trans>{" "}
              <BsArrowRight style={{ width: "30px" }} />
            </Link>
            {applicationActiveStatuses.includes(
              currentApplication?.applicationStatus
            ) &&
              this.onClickDeny && (
                <Link
                  className="ViewAsMember-btn"
                  style={{ marginTop: "20px" }}
                  to={{ pathname: `/competition/${id}` }}
                >
                  <Trans>Apply</Trans>{" "}
                  <BsArrowRight style={{ width: "30px" }} />
                </Link>
              )}
          </div>
        );
        break;
      default:
        competitionRightComponent = <div className="competitionRight" />;
        break;
    }

    return (
      <div className="application-view compition-card">
        <h3
          style={{
            display: "flex",
            justifyContent: "space-between",
            color: "#007e6d",
            fontWeight: 500,
            fontSize: "18px",
            marginBottom: "20px",
          }}
        >
          {name}{" "}
          <span className="text__success" style={{ fontSize: "14px" }}>
            <a
              className="AboutCompetition-text"
              href="https://fund.nu.edu.kz/social-impact/"
              target="_blank"
            >
              <span
                style={{
                  marginRight: "5px",
                  marginTop: "-2px",
                }}
              >
                <BsInfoCircle />
              </span>
              <Trans>AboutCompetition</Trans>
            </a>
          </span>
        </h3>
        <p className="comp__description">{info}</p>
        <div className="application-view__content">
          <div>
            <p className="portal__text">
              <Trans>StartDateApplication</Trans>
            </p>
            <p className="portal__pi__text">
              <span>
                {dateStart
                  ? format(Date.parse(dateStart), "dd MMMM u", {
                      locale: localeDate,
                    })
                  : "Не назначена"}
              </span>
            </p>
            <p className="portal__text">
              <Trans>EndDateApplication</Trans>
            </p>
            <p className="portal__pi__text">
              <span>
                {dateEnd
                  ? format(Date.parse(dateEnd), "dd MMMM u", {
                      locale: localeDate,
                    })
                  : "Не назначена"}
              </span>
            </p>

            <p className="portal__text">
              <Trans>CompetitionStatus</Trans>
            </p>
            <p className="portal__pi__text">
              <Trans>{status}</Trans>
            </p>
            <div
              style={{
                width: "40%",
                borderTop: "1px solid #a8a8a8",
                marginBottom: "20px",
              }}
            />
          </div>
          {competitionRightComponent}
        </div>
      </div>
    );
  }

  stylePanel() {
    let style;
    if (
      (this.props.isMainPage &&
        this.props.status === "COLLECTION_OF_APPLICATION") ||
      (this.props.isMainPage && this.props.status === "REVIEW")
    ) {
      style = {
        background: "#FDF5F1",
        borderBottom: "none",
      };
    } else if (this.props.isMainPage && this.props.status === "COMPLETED") {
      style = {
        background: "#b7d1ff",
        borderBottom: "none",
      };
    }

    return style;
  }

  styleDate() {
    let style;
    if (
      (this.props.isMainPage &&
        this.props.status === "COLLECTION_OF_APPLICATION") ||
      (this.props.isMainPage && this.props.status === "REVIEW")
    ) {
      style = {
        background: "#FFC000",
        padding: "6px 4px",
      };
    }

    return style;
  }
}

export default SentApplicationView;
