import { action, observable } from 'mobx';

type TBreadcrumbs = {
  name: string;
  path: string;
};

export class BreadcrumbsStore {
  @observable links: TBreadcrumbs[] = [];

  @action
  setLinks = (links: TBreadcrumbs[]) => {
    this.links = links;
  };

  @action
  clearLinks = () => {
    this.links = [];
  };
}
