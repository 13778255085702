import * as React from "react";
import './UserGuide.css';

const UserGuideInstruction = ({title, desc, imgURL, imgAlt, id}) => {
    return (
        <div className="guide__instruction-part" id={id}>
                        <div className="guide__instruction-part-headline">
                            {title}
                        </div>
                        <div className="guide__instruction-part-description">
                            {desc}
                        </div>
                        <div className="guide__instruction-part-image">
                            <img src="https://qazvolunteer.kz/uploads/dd/f6/626a587bf399c885140042.jpg" 
                                alt="instruction" />
                            <i>{imgURL}</i>
                        </div>
        </div>
    )
}

export default UserGuideInstruction;