import { IG, PG, SE, SI, SP } from '../../consts';
import { Trans } from 'react-i18next';
import React from 'react';

const renderHeader = (prog) => {
  if (prog === PG || prog === IG) {
    return <Trans>EFF</Trans>;
  }

  if (prog === SP || prog === SI || prog === SE) {
    return <Trans>EFFECTIVNOST</Trans>;
  }

  return <Trans>ACTUALNOST</Trans>;
};

export default renderHeader;
