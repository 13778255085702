import React, { useEffect, useState } from 'react';
import { ProgressBar } from 'react-bootstrap';

function ProgressBarUi({ className }) {
  const [scrolling, setScrolling] = useState(0);
  const scrollHandler = () => {
    const totalScroll = document.documentElement.scrollTop;
    const windowHeight =
      document.documentElement.scrollHeight -
      document.documentElement.clientHeight;
    const scroll = Math.round(Number(`${(totalScroll / windowHeight) * 100}`));

    setScrolling(scroll);
  };

  useEffect(() => {
    window.addEventListener('scroll', scrollHandler);

    return () => window.removeEventListener('scroll', scrollHandler);
  }, []);

  return (
    <ProgressBar
      onScroll={scrollHandler}
      striped
      className={className}
      now={scrolling}
      label={`${scrolling}%`}
    />
  );
}

export default ProgressBarUi;
