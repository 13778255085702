import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import resources from './translations';

i18n
  .use(initReactI18next)
  .use(LanguageDetector)
  .init({
    resources: resources,
    fallbackLng: 'ru',
    // debug: true,

    // have a common namespace used around the full app
    ns: ['resources', 'errors'],
    defaultNS: 'translations',

    keySeparator: '.',
    interpolation: {
      escapeValue: false, // not needed for react!!
      formatSeparator: ',',
    },

    react: {
      wait: true,
    },
  });

i18n.changeLanguage(i18n.language.slice(0, 2));

export interface I18nObserver {
  i18n?;
}

export default i18n;
