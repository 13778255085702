// @flow
import * as React from 'react';
import MainLayout from '../../layouts/MainLayout';
import { Trans } from 'react-i18next';
import './ErrorPage.css';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { AppStateObserver, injectAppState } from '../../stores';
import { observer } from 'mobx-react';

interface ErrorPageProps extends AppStateObserver, RouteComponentProps {
  onClick?: () => void;
}

@injectAppState
@observer
class ErrorPage extends React.Component<ErrorPageProps> {
  handleClick = () => {
    if (this.props?.onClick) this.props.onClick();
    this.props.history.push('/');
  };

  render() {
    const { language } = this.props.appState.userStore;
    return (
      <MainLayout>
        <div className="error-page__container">
          <h1 className="title__home" style={{ marginBottom: "10px" }}>
            <Trans>sthWentWrong</Trans>
          </h1>
          <div className="portal__card--content">
            <p className="portal__text"><Trans>unknownError</Trans></p>
            <button
              className="button-input btn btn-success"
              onClick={this.handleClick}
            >
              <Trans>backToHome</Trans>
            </button>
          </div>
        </div>
      </MainLayout>
    );
  }
}

export default withRouter(ErrorPage);
