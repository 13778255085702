import React, { Fragment, useContext, useRef, useState } from "react";
import Popup from "reactjs-popup";
import { Trans } from "react-i18next";
import { toast } from "react-toastify";
import i18next from "i18next";
import { cubaREST } from "../cubaREST";
import { ApplicationFormContext } from "components/ApplicationFormProvider";
import eyeIcon from "../assets/icons/eye.svg";
import CustomProgressBar from "../components/ProgressBar/ProgressBar";
import ResetFormButton from "../components/ResetFormButton/ResetFormButton";
import Notify from "./Notifier";

const LoadButtons = (props) => {
  const btnRef = useRef(null);
  const { appId, buttonsInfo, loading, loadingECP, isECPsigning } = props;
  const {
    saveApplication,
    signApplication,
    saveAndCheckApplicationBeforeSign,
    applicationForm,
    applicant,
    verifyApplication,
  } = useContext(ApplicationFormContext);

  const [openSign, setOpenSign] = useState<boolean>(false);
  const [file, setFile] = useState(null);
  const [password, setPassword] = useState<string>(null);
  const [disabled, setDisabled] = useState(false);
  const [ESPfileName, setESPfileName] = useState("");
  const [showPassword, setShowPassword] = useState(false);

  const denyButton = () => {
    return (
      <Popup
        trigger={
          <div>
            <button className="application-form__save">
              <Trans>Deny</Trans>
            </button>
          </div>
        }
        modal
        closeOnDocumentClick
      >
        {(close) => (
          <div className="modal">
            <div className="modal__header modal-title">
              <h1>
                <Trans>areUSure</Trans>
              </h1>
            </div>
            <div className="modal__actions">
              <button
                className="button-input btn btn-success"
                onClick={onClickDeny}
              >
                <Trans>Yes</Trans>
              </button>
              <button
                className="button-input btn btn-light btn-popup"
                onClick={close}
              >
                <Trans>Cancel</Trans>
              </button>
            </div>
          </div>
        )}
      </Popup>
    );
  };

  // add comments

  const onClickDeny = () => {
    cubaREST
      .invokeService("fsr_ApplicationService", "endApplication", {
        applicationId: appId,
      })
      .then(() => {
        Notify.pushSuccessMessage(i18next.t("SuccessDraft"));
      })
      .catch(() => {
        Notify.pushErrorMessage("Возникла ошибка");
      });
  };

  const onFileChange = (e) => {
    let file = e.target.files[0];
    if (file.name.substring(0, 3) !== "RSA") {
      Notify.pushErrorMessage(i18next.t("ESP_FILE_TYPE_ERROR"));
      return;
    }
    setESPfileName(file.name || "file can not be opened");
    getBase64(file)
      .then((result) => {
        file["base64"] = result;
        setFile({
          base64URL: result,
          file,
        });
      })
      .catch((err) => {
        console.log(err);
      });

    setFile({
      file: e.target.files[0],
    });
  };

  const removeESP = () => {
    setFile(null);
    setESPfileName("");
  };

  const handlePassword = (e) => {
    setPassword(e.target.value);
  };

  const getBase64 = (file) => {
    return new Promise((resolve) => {
      let baseURL: string | ArrayBuffer = "";
      // Make new FileReader
      let reader = new FileReader();

      reader.readAsDataURL(file);

      reader.onload = () => {
        baseURL = reader.result;
        resolve(baseURL);
      };
    });
  };

  const showPopup = async (btn) => {
    const screenWidth = window.innerWidth;
    if (screenWidth < 768) {
      setDisabled(true);
      Notify.pushSuccessMessage(i18next.t("applicationSignIn"));
      return;
    } else {
      setDisabled(false);
      const isValidForm = verifyApplication(applicationForm, applicant);
      if (isValidForm) {
        const isFormVerified = await saveAndCheckApplicationBeforeSign(btn);
        isFormVerified && setOpenSign(true);
      }
    }
  };
  // };

  const togglePassword = () => {
    setShowPassword((state) => !state);
  };

  return (
    <>
      {buttonsInfo && Object.keys(buttonsInfo).length > 0 &&
      applicationForm.programs?.length ? (
        <>
          <div
            style={{
              display: "flex",
              width: "100%",
              flexWrap: "wrap",
              gap: "10px",
              position: "sticky",
              bottom: "0",
              background: "#fff",
              paddingBottom: "20px",
            }}
          >
            <ResetFormButton loading={loading} isECPsigning={isECPsigning} />
            {Object.keys(buttonsInfo).map((buttonKey: string) => {
              const button = buttonsInfo[buttonKey];

              if (buttonKey === "saveTemp") {
                return (
                  <button
                    key={buttonKey}
                    className={"button-input btn btn-save"}
                    onClick={() => {
                      saveApplication(button.procTaskId, "temp");
                    }}
                    disabled={loading || isECPsigning}
                  >
                    <Trans>saveTemp</Trans>
                  </button>
                );
              }

              if (buttonKey === "sign") {
                return (
                  <Fragment key={buttonKey}>
                    <button
                      ref={btnRef}
                      className="button-input btn btn-success btnSignIn btn-mob"
                      disabled={loadingECP || disabled || isECPsigning}
                      onClick={() => {
                        showPopup(button.procTaskId);
                      }}
                    >
                      <Trans>{buttonKey}</Trans>
                    </button>

                    <Popup
                      open={openSign}
                      modal
                      closeOnDocumentClick
                      onClose={() => {
                        setOpenSign(false);
                        removeESP();
                      }}
                    >
                      {(close) => (
                        <div className="avatar-modal">
                          <div>
                            <div className="avatar__container">
                              <div className="avatar__content">
                                <div className="avatar__wrap-input">
                                  <label
                                    className="avatar__label avatar__label--cabinet"
                                    htmlFor="file_id"
                                  >
                                    <Trans>AttachESP</Trans>
                                    <input
                                      className="avatar__input"
                                      id="file_id"
                                      type="file"
                                      accept=".p12"
                                      onChange={onFileChange}
                                    />
                                    <div className="button-input btn btn-save avatar__btn width250">
                                      <Trans>
                                        {ESPfileName.length == 0
                                          ? "Add"
                                          : "AttachAnotherESP"}
                                      </Trans>
                                    </div>
                                  </label>
                                  <div className="esp-filename-label">
                                    <div className={"esp-filename-name"}>
                                      {ESPfileName}
                                    </div>
                                    {!!file && (
                                      <span
                                        onClick={() => removeESP()}
                                        className="icon-delete"
                                        style={{ marginTop: "5px" }}
                                      >
                                        +
                                      </span>
                                    )}
                                  </div>
                                </div>
                                <div
                                  className="avatar__wrap-input"
                                  style={{ margin: "0 10px" }}
                                >
                                  <label
                                    className="input__header input__header--popup"
                                    style={{
                                      textAlign: "center",
                                      display: "block",
                                      margin: "10px",
                                    }}
                                  >
                                    <Trans>ESPpassword</Trans>
                                  </label>
                                  <div className="input__wrapper width250">
                                    <input
                                      className="default-input"
                                      type={showPassword ? "text" : "password"}
                                      placeholder="••••••••••"
                                      onChange={handlePassword}
                                    />
                                    <img
                                      onClick={togglePassword}
                                      className="input__password-icon"
                                      src={eyeIcon}
                                      alt="eye"
                                    />
                                  </div>
                                </div>
                                <div className="btn__actions btn__actions--avatar">
                                  <button
                                    className="button-input btn btn-success"
                                    style={{ marginRight: "10px" }}
                                    onClick={() => {
                                      signApplication(
                                        button.procTaskId,
                                        file,
                                        password
                                      );
                                      close();
                                    }}
                                    disabled={loadingECP || !file || !password}
                                  >
                                    <Trans>Send</Trans>
                                  </button>
                                  <button
                                    className="button-input btn btn-light btn-popup"
                                    onClick={close}
                                  >
                                    <Trans>Cancel</Trans>
                                  </button>
                                </div>
                              </div>
                              <span
                                onClick={() => {
                                  setOpenSign(false);
                                  removeESP();
                                }}
                                className="icon-delete top-right"
                                style={{ marginTop: "5px" }}
                              >
                                +
                              </span>
                            </div>
                          </div>
                        </div>
                      )}
                    </Popup>
                  </Fragment>
                );
              }
            })}
            <span className="progressName">
              <Trans>CompletionProgress</Trans>:
            </span>
            <div className="conteinerProgress">
              <CustomProgressBar />
            </div>
          </div>

          {!Object.keys(buttonsInfo).length && (
            <div className="process_application-form__footer">
              <h2
                style={{
                  display: "flex",

                  justifyContent: "center",
                }}
                className="red-error"
              >
                <Trans>YouHaveSent</Trans>
              </h2>
            </div>
          )}
        </>
      ) : (
        ""
      )}
    </>
  );
};

export default LoadButtons;
