import * as React from "react";
import { observer } from "mobx-react";
import { RouteComponentProps, Redirect } from "react-router-dom";
import { AppStateObserver, injectAppState } from "../../stores";
import CompetitionRequirements from "../../components/CompetitionRequirements";
import CompetitionInfo from "../../components/CompetitionInfo";
import PersonalTables from "../../components/PersonalTables";
import Popup from "reactjs-popup";

import "./CompetitionDetail.css";
import { cubaREST } from "../../cubaREST";
import { toast } from "react-toastify";
import { Trans } from "react-i18next";
import LoadButtons from "../../helpers/loadButtons";
import { Container } from "react-bootstrap";
import Load from "../../components/Load";
import eyeIcon from "../../assets/icons/eye.svg";
import Notify from "../../helpers/Notifier";

export interface CompetitionDetailProps {}

export interface CompetitionDetailState {}

@injectAppState
@observer
class CompetitionDetail extends React.Component<
  AppStateObserver & RouteComponentProps,
  CompetitionDetailProps,
  CompetitionDetailState
> {
  constructor(props) {
    super(props);
    this.formRef = React.createRef();
    this.personalRef = React.createRef();
  }

  formRef;
  personalRef;

  state = {
    file: null,
    base64URL: "",
    password: null,
    appId: "",
    tabs: [
      { name: "Programs", active: true },
      // { name: "PlanRashodov", active: false },
      { name: "teamMembers", active: false },
    ],
    links: [
      {
        name: "Main",
        path: "/", // competitions
      },
      {
        name: "Конкурс",
        path: this.props.match.params["id"],
      },
    ],
    statuses: {
      NEW: "Новый",
      COLLECTION_OF_APPLICATION: "Сбор заявок",
      REVIEW: "На рассмотрении",
      COMPLETED: "Завершен",
    },
    mounted: false,
    directions: [],
    buttonsInfo: new Map(),
    comment: "",
    checkedCompetition: null,
    errorMessage: null,
    checkProgram: true,
    showPassword: false,
    showPasswordSecond: false,
  };

  handleCheckProgram = (bool) => {
    this.setState({
      checkProgram: bool,
    });
  };

  loadEntitiesService = async () => {
    let competitionId = this.props.match.params["id"];

    let userInfo = await this.props.appState.userStore.getUserInfo2();
    let applicant = await this.props.appState.applicantsStore.loadApplicant2(
      userInfo.email
    );
    let applicationList =
      await this.props.appState.applicationsStore.loadEntitiesService(
        userInfo.email
      );
    let competition = await this.props.appState.competitionsStore.loadEntity(
      competitionId
    );

    let checkedCompetition = competition.competitionType;
    this.setState({ checkedCompetition });

    if (checkedCompetition.needTeamMembers === false) {
      this.setState({
        tabs: [
          { name: "Programs", active: true },
          { name: "PlanRashodov", active: false },
        ],
      });
    }
    if (checkedCompetition.isEasyStart) {
      this.props.appState.applicationsStore
        .StartProcess(competitionId, applicant[0].id)
        .then((response) => {
          const processAnswer = JSON.parse(response);

          if (processAnswer.status === "ERROR") {
            Notify.pushErrorMessage(processAnswer.message);
            return;
          }

          let appId, procId;

          Object.keys(processAnswer).map((process) => {
            if (process !== "status") {
              appId = process;
              procId = processAnswer[process];

              localStorage.setItem("appId", appId);
              localStorage.setItem("procId", procId);

              this.setState({ appId: appId });
            }
          });

          return appId;
        })
        .then((appId) => {
          if (appId)
            this.props.history.replace(
              `/application/${appId}/${competitionId}`
            );
          else {
            this.props.history.replace(`/`);
          }
        })
        //             this.props.appState.applicationsStore.loadEntity(appId)
        //                 .catch(() => {
        //                     Notify.pushErrorMessage('Заявка не найдена');
        //
        //                     return;
        //                 });
        //
        //             return appId;
        //         })
        //         .then((appId) => {
        //             this.props.appState.competitionsStore.getButtons(appId, userInfo.id)
        //                 .then(response => {
        //                     this.setState({ buttonsInfo: JSON.parse(response) });
        //                 })
        //                 .catch(() => {
        //                     this.setState({ errorMessage: "Вы не сможете подать так как у вас отсутствует ИИН или вы не гражданин Казахстана" });
        //                 });
        //         })
        .catch(() => {
          Notify.pushErrorMessage("Ошибка при создании процесса");
        });
    }

    // Fetch Directions only for SCP
    if ((checkedCompetition.code = "SCP")) {
      let res = await this.props.appState.applicationsStore.getDirections2();
      this.setState({
        directions: res,
      });
    }

    if (!checkedCompetition.isEasyStart) {
      // For applicationList
      let hasApplication = false;
      let applicationID = "";
      if (applicationList) {
        for (let i in applicationList) {
          if (applicationList[i]?.competition?.id === competitionId) {
            hasApplication = true;
            applicationID = applicationList[i].id;
            break;
          }
        }
        if (hasApplication) {
          this.props.history.push(
            `/application/${applicationID}/${competitionId}`
          );
        }
      }
    }
  };

  componentDidMount() {
    this.loadEntitiesService();
    this.props.appState.breadcrumbsStore.setLinks(this.state.links);
  }

  componentDidUpdate(prevProps, prevState) {
    // if(prevState.appId !== this.state.appId) {
    //     this.props.history.push(
    //         `/application/${this.state.appId}/${this.props.match.params["id"]}`
    //     );
    // }
  }

  componentWillUnmount() {
    this.props.appState.breadcrumbsStore.clearLinks();
  }

  loadDocument = (id) => {
    return this.props.appState.filesStore.loadDocument(id);
  };

  loadAttachment = (id) => {
    return this.props.appState.filesStore.loadAttachment(id);
  };

  loadFile = (id) => {
    return this.props.appState.filesStore.downloadFile(id);
  };

  onFileChange = (e) => {
    console.log(e.target.files[0]);
    let { file } = this.state;

    file = e.target.files[0];

    this.getBase64(file)
      .then((result) => {
        file["base64"] = result;
        this.setState({
          base64URL: result,
          file,
        });
      })
      .catch((err) => {
        console.log(err);
      });

    this.setState({
      file: e.target.files[0],
    });
  };

  handlePassword = (e) => {
    this.setState({ password: e.target.value });
  };

  getBase64 = (file) => {
    return new Promise((resolve) => {
      let baseURL: string | ArrayBuffer = "";
      // Make new FileReader
      let reader = new FileReader();

      reader.readAsDataURL(file);

      reader.onload = () => {
        baseURL = reader.result;
        console.log(baseURL);
        resolve(baseURL);
      };
    });
  };

  saveApplicationWithProcess = (key, procTaskId) => {
    this.formRef.current.saveApplicationWithProcess(key, procTaskId);
    if (this.personalRef.current) {
      this.personalRef.current.saveApplicationWithProcess(key, procTaskId);
    }
  };

  saveApplication = () => {
    this.formRef.current.saveApplication();
    if (this.personalRef.current) {
      this.personalRef.current.sendApplicantForm();
    }
  };

  sendApplication = () => {
    console.log("sendApplication");
    this.formRef.current.sendApplication();
    if (this.personalRef.current) {
      this.personalRef.current.sendApplicantForm();
    }
  };

  render() {
    const { competition, isLoadingCompetition, notFound } =
      this.props.appState.competitionsStore;
    const { applicant } = this.props.appState.applicantsStore;
    const { isApplicationSaving, isLoadingApplication } =
      this.props.appState.applicationsStore;
    const { language } = this.props.appState.userStore;
    const { showPassword, showPasswordSecond } = this.state;

    if (isLoadingCompetition || isLoadingApplication) {
      return (
        <div className="loader-container">
          <Load />
        </div>
      );
    }

    if (!cubaREST.restApiToken) {
      return <Redirect to="/sign-in" />;
    }

    if (!competition && notFound) {
      return <Redirect to="/404" />;
    }

    return (
      <Container className="wrapper">
        <Container className="portal__content">
          <h2 className="title__cabinet">
            <Trans>applicationFormHead</Trans>
          </h2>

          {this.state.checkedCompetition && (
            <Container>
              <div className="portal__card__home portal__card--content">
                <div className="portal__card--wrap">
                  <CompetitionInfo
                    competition={competition}
                    statuses={this.state.statuses}
                    loadFile={this.loadFile}
                  />
                </div>

                <CompetitionRequirements
                  loadAttachment={this.loadAttachment}
                  loadFile={this.loadFile}
                />

                {
                  // (competition.status !== "COMPLETED" &&
                  //     this.state.appId &&
                  //     !this.state.checkedCompetition.isEasyStart) ?
                  //     <ApplicationForm espFile={this.state.base64URL} password={this.state.password} documents={competition.reqDocs} competitionId={competition.id} ref={this.formRef} tabs={this.state.tabs} mounted={this.state.mounted} directions={this.state.directions} /> :
                  //     <ProcessApplicationForm espFile={this.state.base64URL} password={this.state.password} documents={competition.reqDocs} competitionId={competition.id} appId={this.state.appId} ref={this.formRef} tabs={this.state.tabs} mounted={this.state.mounted} directions={this.state.directions} comment={this.state.comment} checkProgram={this.state.checkProgram} handleCheckProgram={this.handleCheckProgram} />
                }
                {competition.status !== "COMPLETED" &&
                  competition.competitionType.code === "TLP" &&
                  applicant && (
                    <PersonalTables
                      applicant={applicant}
                      loadFile={this.loadFile}
                      ref={this.personalRef}
                    />
                  )}
                {competition.status !== "COMPLETED" &&
                  this.state.checkedCompetition.isEasyStart &&
                  this.state.appId && (
                    <div className="complete-footer">
                      {this.state.buttonsInfo && (
                        <LoadButtons
                          appId={this.state.appId}
                          buttonsInfo={this.state.buttonsInfo}
                          onFileChange={this.onFileChange}
                          handlePassword={this.handlePassword}
                        />
                      )}
                      {this.state.errorMessage && (
                        <h2
                          style={{
                            display: "flex",
                            justifyContent: "center",
                          }}
                          className="red-error"
                        >
                          <Trans>IINisNULL</Trans>
                        </h2>
                      )}
                    </div>
                  )}
                {competition.status !== "COMPLETED" &&
                  !this.state.checkedCompetition.isEasyStart && (
                    <div className="application-form__footer">
                      {/*  Not used code 2 popups*/}
                      <Popup
                        trigger={
                          <button className="application-form__save">
                            <Trans>Save</Trans>
                          </button>
                        }
                        modal
                        closeOnDocumentClick
                      >
                        {(close) => (
                          <div className="modal">
                            <div className="modal__header modal-title">
                              <h1>
                                <Trans>AttachESP</Trans>
                              </h1>
                            </div>
                            <div className="modal__content">
                              <div className="esp__file">
                                <input
                                  type="file"
                                  id="file"
                                  onChange={this.onFileChange}
                                />
                              </div>
                              <div className="esp__password">
                                <label
                                  className="esp_password__label"
                                  htmlFor=""
                                >
                                  <Trans>ESPpassword</Trans>
                                </label>
                                {/* TODO check style esp__password__input everywhere*/}
                                <div className="input__wrapper">
                                  <input
                                    className="esp__password__input"
                                    type={showPassword ? "text" : "password"}
                                    // placeholder="••••••••••"
                                    onChange={this.handlePassword}
                                  />
                                  <img
                                    className="input__password-icon"
                                    onClick={() =>
                                      this.setState({
                                        showPassword: !showPassword,
                                      })
                                    }
                                    src={eyeIcon}
                                    alt="eye icon"
                                  />
                                </div>
                              </div>
                            </div>
                            <div className="btn__actions">
                              <button
                                className="button-input btn btn-success"
                                onClick={() => {
                                  this.saveApplication();
                                  close();
                                }}
                              >
                                <Trans>Send</Trans>
                              </button>
                              <button
                                className="button-input btn btn-light btn-popup"
                                onClick={close}
                              >
                                <Trans>Cancel</Trans>
                              </button>
                            </div>
                          </div>
                        )}
                      </Popup>

                      <Popup
                        trigger={
                          <button className="application-form__submit">
                            <Trans>Apply</Trans>
                          </button>
                        }
                        modal
                        closeOnDocumentClick
                      >
                        {(close) => (
                          <div className="modal">
                            <div className="modal__header modal-title">
                              <h1>
                                <Trans>AttachESP</Trans>
                              </h1>
                            </div>
                            <div className="modal__content">
                              <div className="esp__file">
                                <input
                                  type="file"
                                  id="file"
                                  onChange={this.onFileChange}
                                />
                              </div>
                              <div className="esp__password">
                                <label htmlFor="">
                                  <Trans>ESPpassword</Trans>
                                </label>
                                <div className="input__wrapper">
                                  <input
                                    type={
                                      showPasswordSecond ? "text" : "password"
                                    }
                                    // placeholder="••••••••••"
                                    onChange={this.handlePassword}
                                  />
                                  <img
                                    className="input__password-icon"
                                    onClick={() =>
                                      this.setState({
                                        showPasswordSecond: !showPasswordSecond,
                                      })
                                    }
                                    src={eyeIcon}
                                    alt="eye icon"
                                  />
                                </div>
                              </div>
                            </div>
                            <div className="btn__actions">
                              <button
                                className="button-input btn btn-success"
                                onClick={() => {
                                  this.sendApplication();
                                  close();
                                }}
                              >
                                <Trans>Send</Trans>
                              </button>
                              <button
                                className="button-input btn btn-light btn-popup"
                                onClick={close}
                              >
                                <Trans>Cancel</Trans>
                              </button>
                            </div>
                          </div>
                        )}
                      </Popup>
                    </div>
                  )}

                {isApplicationSaving && (
                  <div className="competition-loading__modal">
                    <Load />
                  </div>
                )}
              </div>
            </Container>
          )}
        </Container>
      </Container>
    );
  }
}

export default CompetitionDetail;
