import * as React from "react";
import { Link } from "react-router-dom";

import "./Breadcrumps.css";
import i18next from "i18next";
import { AppStateObserver } from "../../stores";

export interface BreadcrumbsProps {
  links;
}

const Breadcrumps: React.FC<AppStateObserver & BreadcrumbsProps> = props => {
  const links = props.links;
  const lastLinks = links.length - 1;

  const containerClass = links.length
    ? "breadcrumps__container mr30px"
    : "breadcrumps__container";

  return (
    <div className={containerClass}>
      <Link to={"/"} />
      {links
        ? links.map((link, idx) => {
            return (
              <div className="breadcrumps__item" key={idx}>
                {idx === lastLinks ? (
                  <a className="breadcrumps__link breadcrumps__link--inactive">
                    {i18next.t(link.name)}
                  </a>
                ) : (
                  <Link to={link.path} className="breadcrumps__link">
                    {i18next.t(link.name)}
                  </Link>
                )}
                {idx !== lastLinks && `/`}
              </div>
            );
          })
        : ""}
    </div>
  );
};

export default Breadcrumps;
