import * as React from "react";
import { AppStateObserver, injectAppState } from "../../stores";
import { observer } from "mobx-react";
import { toast } from "react-toastify";
import { Trans } from "react-i18next";
import i18next from "i18next";
import FileComponent from "../../components/FileComponent";
import ExtraFields from "../ExtraFields";
import Load from "../Load";
import { sectionNumber } from "../ApplicationFormProvider/sectionNumber";
import { IG, SG } from "../../consts";
import validateFileSize from "../../helpers/validateFileSize";
import Notify from "../../helpers/Notifier";

export interface ProcessApplicationFormForBusinessPlanViewProps {
  espFile;
  password;
  application;
  requiredDocuments;
  comment;
  checkProgram;
}

export interface ProcessApplicationFormForBusinessPlanViewState {}

@injectAppState
@observer
class ProcessApplicationFormForBusinessPlanView extends React.Component<
  ProcessApplicationFormForBusinessPlanViewProps & AppStateObserver,
  ProcessApplicationFormForBusinessPlanViewState
> {
  state = {
    teamMembers: this.props.application?.teamMembers || [],
    bFile: this.props.application?.businessPlan || null,
    relevance: this.props.application?.relevance || null,
    applicationForm: this.props.application?.applicationForm || null,
    efficiencyAndResult: this.props.application?.efficiencyAndResult || null,
    resource: this.props.application?.resource || null,
    sustainability: this.props.application?.sustainability || null,
    innovativeness: this.props.application?.innovativeness || null,
    environmental: this.props.application?.environmental || null,
    programs: this.props.application?.programs || null,
    projectIdea: this.props.application.projectIdea || null,
    businessModel: this.props.application.businessModel || null,
    effectiveness: this.props.application.effectiveness || null,
    scalability: this.props.application.scalability || null,
    organizationPotential: this.props.application.organizationPotential || null,
    socialCategory: this.props.application.socialCategory,
    checkProgram: this.props.checkProgram,
  };

  componentDidMount() {
    const appDocs = this.props.appState.applicationsStore.application.appDocs;

    if (appDocs && appDocs.length) this.setState({ checkProgram: false });
  }

  loadFile = id => {
    return this.props.appState.filesStore.downloadFile(id);
  };

  programsChecked = name => {
    let checked = false;
    for (let i = 0; i < this.props.application.programs.length; i++) {
      const program = this.props.application.programs[i];
      if (program.name === name) {
        checked = true;
        break;
      }
    }
    return checked;
  };

  handleAgreementJAS = () => {
    let lang = this.props.appState.userStore.language || "ru";

    if (lang === "ru") {
      return (
        <p className="form__question">
          Будете ли Вы проходить полную программу
          <a
            className="tabs__link"
            target="_blank"
            href="https://fund.nu.edu.kz/jas-social-impact-2022/"
          >
            {" "}
            JAS Camp{" "}
          </a>
          до 8 недель инкубации+до 2 недель модуль импакт предпринимательства +
          оценка команды)?
        </p>
      );
    }
    if (lang === "en") {
      return (
        <p className="form__question">
          Will you participate in the full{" "}
          <a
            className="tabs__link"
            target="_blank"
            href="https://fund.nu.edu.kz/en/jas-social-impact-2022/"
          >
            JAS Camp{" "}
          </a>{" "}
          program (up to 8 weeks of incubation+ up to 2 weeks of
          Entrepreneurship impact module + team evaluation)?
        </p>
      );
    }
    if (lang === "kz") {
      return (
        <p className="form__question">
          Сіз{" "}
          <a
            className="tabs__link"
            target="_blank"
            href="https://fund.nu.edu.kz/kz/jas-social-impact-2022/"
          >
            JAS Camp
          </a>{" "}
          толық бағдарламасынан өтесіз бе (инкубацияның 8 аптасына дейін+2
          аптаға дейін импакт-кәсіпкерлік модулі + команданы бағалау)?
        </p>
      );
    }
  };

  render() {
    const { isLoadingApplication, application } =
      this.props.appState.applicationsStore;
    const { competition } = this.props.appState.competitionsStore;
    const requiredDocuments = competition.reqDocs;

    if (isLoadingApplication || !application) {
      return (
        <div className="loader-container">
          <Load />
        </div>
      );
    }

    if (application) {
      const { docFirst, appDocs } = application;
      const { bFile } = this.state;

      return (
        <>
          {application.applicationStatus === "PHASE_1_FINISHED" ? (
            <h2
              style={{
                color: "#007e6d",
                fontSize: "24px",
              }}
            >
              <Trans>phase1Finished</Trans>
            </h2>
          ) : (
            ""
          )}
          <h2 className="form__question">
            <Trans>Form</Trans>
          </h2>
          {competition.status === "BUSINESS_PLAN" && (
            <div className="application-form__documents">
              <div className="application-form__headings">
                <p>
                  <Trans>DocType</Trans>
                </p>
                <p>
                  <Trans>File</Trans>
                </p>
              </div>
              <div className="application-form__document">
                <p>
                  <Trans>BusinessPlan</Trans>{" "}
                  <span className="mandatory">*</span>
                </p>
                <div className="inputfile__container">
                  {this.renderBusinessPlan(bFile)}
                  <label htmlFor={`inputfile-1`} style={{ marginLeft: "13px" }}>
                    <Trans>ChooseFile</Trans>
                  </label>
                  <input
                    type="file"
                    onChange={e => this.handleFileChange(e.target.files)}
                    id={`inputfile-1`}
                  />
                </div>
              </div>
            </div>
          )}
          <h3 className="form__question">
            <Trans>Programs</Trans>
          </h3>
          <div>{this.renderProgramsTable()}</div>

          <ExtraFields
            program={application.programs[0]}
            app_id={application.id}
            relevance={application.relevance}
            applicationForm={application.applicationForm}
            efficiencyAndResult={application.efficiencyAndResult}
            resource={application.resource}
            teamMembers={application.teamMembers}
            sustainability={application.sustainability}
            innovativeness={application.innovativeness}
            projectIdea={application.projectIdea}
            businessModel={application.businessModel}
            effectiveness={application.effectiveness}
            environmental={application.environmental}
            scalability={application.scalability}
            socialCategory={application.socialCategory}
            organizationPotential={application.organizationPotential}
            readonly
          />

          {/*{[SG, IG].includes(application.programs[0].name) && (*/}
          {/*  <>*/}
          {/*    {this.handleAgreementJAS()}*/}
          {/*    <div className={'td-radio radio__tb1 field-radio__btn--group'}>*/}
          {/*      <input*/}
          {/*        readOnly*/}
          {/*        type="radio"*/}
          {/*        name="check"*/}
          {/*        id="checkYes"*/}
          {/*        checked={this.state.checkProgram}*/}
          {/*      />*/}
          {/*      <label htmlFor="checkYes" className="td-radio__label">*/}
          {/*        <Trans>yesJAS</Trans>*/}
          {/*      </label>*/}
          {/*    </div>*/}
          {/*    <div className={'td-radio radio__tb1 field-radio__btn--group'}>*/}
          {/*      <input*/}
          {/*        readOnly*/}
          {/*        type="radio"*/}
          {/*        name="check"*/}
          {/*        id="checkNo"*/}
          {/*        checked={!this.state.checkProgram}*/}
          {/*      />*/}
          {/*      <label htmlFor="checkNo" className="td-radio__label">*/}
          {/*        <Trans>noJAS</Trans>*/}
          {/*      </label>*/}
          {/*    </div>*/}
          {/*  </>*/}
          {/*)}*/}
          {requiredDocuments && (
            <div>
              {requiredDocuments
                .slice()
                .sort((a, b) => a.docType.name.localeCompare(b.docType.name))
                .map((document, idx) => {
                  //Временная заглушка перед стартом конкурса, разобраться почему прилетает док на акселерацию
                  if (
                    document.docType.name ===
                    "Документы, подтверждающие прохождение акселерации/инкубации в течение последних двух лет (1 zip архив) Applicant"
                  )
                    return (
                      <div key={document.id}>
                        <div className="form__question__descrption m-t-20">
                          {document.docType &&
                            this.transLang(
                              this.props.appState.userStore.language,
                              document.docType.name
                            )}
                          {document.mandatory && (
                            <span className="mandatory">*</span>
                          )}
                        </div>
                        <div className="inputfile-wrap inputfile-wrap__download">
                          {this.renderAppDocFile()}
                        </div>
                      </div>
                    );
                })}
            </div>
          )}

          <div style={{ paddingBottom: "50px" }} />
        </>
      );
    }

    return null;
  }

  renderProgramsTable() {
    const { programs } = this.props.appState.applicationsStore.application;

    return (
      <>
        {programs &&
          programs.map(program => (
            <div key={program.id}>
              <div className="input-tabs__name">
                <input
                  className="input-tabs__input"
                  type="checkbox"
                  data-name={program.name}
                  data-id={program.id}
                  id={program.id}
                  defaultChecked={this.programsChecked(program.name)}
                />

                <label
                  style={{ marginLeft: "-15px" }}
                  className="input-tabs__label"
                  htmlFor={program.id}
                >
                  {program.name}
                </label>
              </div>
              <div className="h-divider tap_divider" />
            </div>
          ))}
      </>
    );
  }

  renderBusinessPlan(bFile) {
    if (bFile !== null) {
      const doc = bFile;
      return (
        <FileComponent
          getFile={this.loadFile}
          id={doc.file.id}
          name={doc.file.name}
          extension={doc.file.extension}
          withFileIcon={false}
          withDownloadIcon={false}
        />
      );
    }
  }

  renderDocFile(document, docFirst) {
    if (docFirst.length > 0) {
      let doc;

      for (let i = 0; i < docFirst.length; i++) {
        if (
          document.docType.id === docFirst[i].docType.id &&
          document.docOwner.id === docFirst[i].docOwner.id
        ) {
          doc = docFirst[i];
          // return <p>{doc.file.name}</p>;
          return (
            <FileComponent
              getFile={this.loadFile}
              id={doc.file.id}
              name={doc.file.name}
              extension={doc.file.extension}
              withFileIcon={false}
              withDownloadIcon={false}
            />
          );
        }
      }
    }
  }

  transLang = (lang, doc) => {
    switch (lang) {
      case "en":
        return (doc =
          "Documents confirming the passage of acceleration / incubation within the last two years (1 zip archive)");
      case "kz":
        return (doc =
          "Соңғы екі жыл ішінде жеделдету/инкубациядан өткенін растайтын құжаттар (1 zip мұрағат)");
      case "ru":
      default:
        return (doc =
          "Документы, подтверждающие прохождение акселерации/инкубации в течение последних двух лет (1 zip архив)");
    }
  };

  renderAppDocFile() {
    const appDocs = this.props.application.appDocs;

    return appDocs.map(doc => {
      const file = doc.doc.file;

      return (
        <FileComponent
          key={file.id}
          id={file.id}
          name={file.name}
          extension={file.extension}
          withFileIcon={false}
          withDownloadIcon={false}
        />
      );
    });
  }

  renderArchivedFile(document, appDocs) {
    if (appDocs.length > 0) {
      let doc;
      let idx;
      for (var i = 0; i < appDocs.length; i++) {
        if (
          appDocs[i].doc.docType &&
          document.docType.id === appDocs[i].doc.docType.id
        ) {
          idx = i;
          break;
        }
      }
      let archivedFiles = [];
      if (typeof idx !== "undefined") {
        if (appDocs[idx].docs.length > 2) {
          for (let j = 0; j < appDocs[idx].docs.length; j++) {
            if (appDocs[idx].docs[j].isArchive === true) {
              archivedFiles.push(appDocs[idx].docs[j]);
            }
          }
          if (archivedFiles.length > 1) {
            doc = archivedFiles[1];
            return (
              <FileComponent
                getFile={this.loadFile}
                id={doc.file.id}
                name={doc.file.name}
                extension={doc.file.extension}
                withFileIcon={false}
                withDownloadIcon={false}
              />
            );
          }
        }
        if (appDocs[idx].docs.length <= 2) {
          for (let j = 0; j < appDocs[idx].docs.length; j++) {
            if (appDocs[idx].docs[j].isArchive === true) {
              doc = appDocs[idx].docs[j];
              return (
                <FileComponent
                  getFile={this.loadFile}
                  id={doc.file.id}
                  name={doc.file.name}
                  extension={doc.file.extension}
                  withFileIcon={false}
                  withDownloadIcon={false}
                />
              );
            }
          }
        }
      }
    }
  }

  renderArchivedFile2(document, appDocs) {
    if (appDocs.length > 0) {
      let doc;
      let idx;
      for (var i = 0; i < appDocs.length; i++) {
        if (
          appDocs[i].doc.docType &&
          document.docType.id === appDocs[i].doc.docType.id
        ) {
          idx = i;
          break;
        }
      }
      let archivedFiles = [];
      if (typeof idx !== "undefined") {
        if (appDocs[idx].docs.length <= 2) {
          for (let j = 0; j < appDocs[idx].docs.length; j++) {
            if (appDocs[idx].docs[j].isArchive === true) {
              archivedFiles.push(appDocs[idx].docs[j]);
            }
          }
          if (archivedFiles.length > 1) {
            doc = archivedFiles[1];
            return (
              <FileComponent
                getFile={this.loadFile}
                id={doc.file.id}
                name={doc.file.name}
                extension={doc.file.extension}
                withFileIcon={false}
                withDownloadIcon={false}
              />
            );
          }
        }
        if (appDocs[idx].docs.length > 2) {
          for (let j = 0; j < appDocs[idx].docs.length; j++) {
            if (appDocs[idx].docs[j].isArchive === true) {
              doc = appDocs[idx].docs[j];
              return (
                <FileComponent
                  getFile={this.loadFile}
                  id={doc.file.id}
                  name={doc.file.name}
                  extension={doc.file.extension}
                  withFileIcon={false}
                  withDownloadIcon={false}
                />
              );
            }
          }
        }
      }
      return;
    }
  }

  handleFileChange = files => {
    if (!validateFileSize(50, files)) {
      Notify.pushErrorMessage(i18next.t("info.fileSizeLimit", { ns: "errors" }));
      return;
    }
    // this.setState({
    //   fileLoading: { type: docType.name, owner: docOwner.name },
    // });
    let file;
    let bFile = { ...this.state.bFile };
    // let docFirst = [...this.state.docFirst];

    // for (let i = 0; i < docFirst.length; i++) {
    //   if (
    //       docFirst[i].docType.id === docType.id &&
    //       docFirst[i].docOwner.id === docOwner.id
    //   ) {
    //     docFirst.splice(i, 1);
    //   }
    // }

    if (files[0]) {
      this.props.appState.filesStore
        .uploadFile(files[0])
        .then(response => {
          file = response.data;
          let ivanDoc = this.checkDocuments(files, file, bFile);
          this.setState({ bFile: ivanDoc });
          // this.setState({ fileLoading: { type: null, owner: null } });
        })
        .catch(error => {
          this.setState({ fileLoading: { type: null, owner: null } });
          Notify.pushErrorMessage(i18next.t("Error"), );
          console.log(error);
        });
    }
  };

  checkDocuments(files, file, bFile) {
    if (files.length !== 0) {
      return {
        file: file,
        name: file.name,
        applicant: this.props.appState.applicantsStore.applicant,
      };
    }

    return null;
  }

  createDocs = () => {
    return (async () => {
      const doc = this.state.bFile;
      let businessPlan = null;

      try {
        businessPlan = await this.props.appState.filesStore.updateDocument(doc);
      } catch (error) {
        console.log(error);
      }

      return businessPlan;
    })();
  };
}

export default ProcessApplicationFormForBusinessPlanView;
