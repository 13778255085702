import React from "react";
import { PiWarningCircleFill } from "react-icons/pi";
import { ToastOptions, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import s from "./Notifier.module.scss";


const getBaseOptions = (type: string): ToastOptions => {
  const windowWidth = window.innerWidth;
  return {
    className: s.toastWrapper,
    bodyClassName: s.toastBody,
    position: windowWidth < 768 ? "bottom-center" : "top-right",
    autoClose: 5000,
    hideProgressBar: true,
    closeOnClick: true,
    pauseOnHover: true,
    pauseOnFocusLoss: false,
  };
};

type MessageProps = {
  primaryText: string;
  secondaryText?: string | null;
};

const Message: React.FC<MessageProps> = ({ primaryText, secondaryText }) => {
  return (
    <div className={s.message}>
      <div
        className={s.primaryText}
        dangerouslySetInnerHTML={{ __html: primaryText }}
      />
      {!!secondaryText && (
        <div
          className={s.secondaryText}
          dangerouslySetInnerHTML={{ __html: secondaryText }}
        />
      )}
    </div>
  );
};

namespace Notify {
  export const pushSuccessMessage = (
    primaryText: string,
    secondaryText?: string | null,
    options?: ToastOptions
  ) => {
    toast.success(
      <Message primaryText={primaryText} secondaryText={secondaryText} />,
      {
        ...getBaseOptions("Success"),
        ...options,
      }
    );
  };

  export const pushErrorMessage = (
    primaryText: string,
    secondaryText?: string | null,
    options?: ToastOptions
  ) => {
    toast.error(
      <Message primaryText={primaryText} secondaryText={secondaryText} />,
      {
        ...getBaseOptions("error"),
        ...options,
      }
    );
  };
  export const pushWarningMessage = (
    primaryText: string,
    secondaryText?: string | null,
    options?: ToastOptions
  ) => {
    toast.warn(
      <Message primaryText={primaryText} secondaryText={secondaryText} />,
      {
        ...getBaseOptions("warn"),
        icon: <PiWarningCircleFill viewBox="25 25 208 208"  className={s.warnIcon} />,
        ...options,
      }
    );
  };
  export const pushInfoMessage = (
    primaryText: string,
    secondaryText?: string | null,
    options?: ToastOptions
  ) => {
    toast.info(
      <Message primaryText={primaryText} secondaryText={secondaryText} />,
      {
        ...getBaseOptions("info"),
        ...options,
      }
    );
  };
}

export default Notify;
