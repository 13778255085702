import React, { useContext } from "react";
import { Trans } from "react-i18next";
import { inject } from "mobx-react";
import i18n from "i18next";
import { ApplicationFormContext } from "../ApplicationFormProvider";
import externallink from "../../assets/images/external-link.png";
import "./ShortInfo.css";

type TId = {
  id: string;
};
type TInfo = {
  compType: TId;
  contractCode: string;
  id: string;
  info: string;
  infoKk?: string;
  infoEn?: string;
  name: string;
};

const ShortInfo = inject("appState")((props) => {
  const { language } = i18n;
  const { program } = props;
  const { compPrograms } = props.appState.competitionsStore;

  const languageLink = {
    ru: "https://fund.nu.edu.kz/rukovodstvo-polzovatelja/",
    kz: "https://fund.nu.edu.kz/kz/pajdalanushy-nuskaulygy/",
    en: "https://fund.nu.edu.kz/en/user-guide/",
  };

  const changeLang = (lang, information) => {
    switch (language) {
      case "ru":
        return information["info"];
      case "en":
        return information["infoEn"];
      case "kz":
        return information["infoKk"];
    }
  };

  return (
    <div className="info__box">
      <h2 className="info__header">
        <Trans>shortInformation</Trans>
      </h2>
      {compPrograms.length &&
        compPrograms.map((information: TInfo) => {
          if (program === information?.name) {
            return (
              <div key={information.id}>
                <p className="info__description">
                  {changeLang(language, information)}
                </p>
                <p className="info__instruction">
                  {/*<a*/}
                  {/*  href={languageLink[language]}*/}
                  {/*  target={'_blank'}*/}
                  {/*  className="info__link"*/}
                  {/*>*/}
                  {/*  <img*/}
                  {/*    src={externallink}*/}
                  {/*    className="info__img"*/}
                  {/*    alt="External-link"*/}
                  {/*  />*/}
                  {/*  <Trans>UserGuide</Trans>*/}
                  {/*</a>*/}
                  {/* <a className="info__link" style={{}}>
                    <img
                      src={externallink}
                      className="info__img"
                      alt="External-link"
                    />
                    <Trans>UserGuide</Trans>
                  </a> */}
                </p>
              </div>
            );
          }
        })}
    </div>
  );
});

export default ShortInfo;
