import React, { ChangeEvent, useContext, useEffect, useState } from "react";
import classNames from "classnames";
import { ApplicationFormContext } from "components/ApplicationFormProvider";
import i18next from "i18next";
interface IProps {
  defaultValue: string;
  name: string;
  onChange: (event: ChangeEvent<HTMLInputElement>) => void;
  className?: string;
  readOnly?: boolean;
  maxLength?: number;
  accept?: "positiveInt" | "int";
  placeHolder?: string;
}

// input type=text
export const Input = (props: IProps) => {
  const {
    defaultValue,
    name,
    placeHolder,
    onChange,
    className,
    maxLength = 200,
    accept,
  } = props;

  const { readOnly, getError, clearError } = useContext(ApplicationFormContext);
  const [value, setValue] = useState(defaultValue);

  useEffect(() => {
    setValue(defaultValue);
  }, [defaultValue]);

  const handleChange = (event) => {
    const value = event.target.value;
    if (accept === "positiveInt") {
      if ((value.length > maxLength || !/^\d+$/.test(value)) && value !== "") return;
    }
    clearError && clearError(name);
    setValue(value);
    onChange && onChange(event);
  };

  return (
    <input

      className={classNames(
        "default-input",
        getError(name) && "input__error",
        className
      )}
      placeholder={i18next.t('placeHolder')}
      value={value}
      name={name}
      maxLength={maxLength}
      onChange={handleChange}
      readOnly={readOnly}
      type="text"
    />
  );
};

export default Input;
