import React, { useContext, useEffect, useState } from 'react';
import { Trans } from 'react-i18next';
import i18next from 'i18next';
import { injectAppState } from '../../stores';
import { Container } from 'react-bootstrap';
import { ApplicationFormContext } from '../ApplicationFormProvider';

const FieldSocialCategory = injectAppState((props) => {
  const { applicationForm, handleApplicationFormChange, readOnly } = useContext(
    ApplicationFormContext
  );
  const { socialCategories } = props.appState.applicationsStore;
  const [socialCategory, setSocialCategory] = useState(
    applicationForm.socialCategory ??
      props.appState.applicationsStore.socialCategories[6]
  );

  // если еще ни чего не выбрали записываем в applicationForm
  useEffect(() => {
    if (!applicationForm.socialCategory)
      handleApplicationFormChange({ socialCategory });
  }, []);

  // если сбрасываем нужно установить новое значение в localState
  useEffect(() => {
    setSocialCategory(
      applicationForm.socialCategory ??
        props.appState.applicationsStore.socialCategories[6]
    );
  }, [applicationForm.socialCategory]);

  const handleStateChange = (event) => {
    event.persist();
    const socialCategory = optionObject().filter(
      (category) => category.id === event.target.value
    )[0];
    handleApplicationFormChange({ socialCategory });
  };

  const categoryOptionsArr = [
    i18next.t('socialCategory.rural'),
    i18next.t('socialCategory.vosp'),
    i18next.t('socialCategory.kandas'),
    i18next.t('socialCategory.org'),
    i18next.t('socialCategory.malo'),
    i18next.t('socialCategory.neet'),
    i18next.t('socialCategory.not'),
    i18next.t('socialCategory.pension'),
    i18next.t('socialCategory.rodit'),
  ];

  const optionObject = () => {
    return socialCategories.map((name, indx) => ({
      ...name,
      name: categoryOptionsArr[indx],
    }));
  };

  const { language } = props.appState.userStore;

  return (
    <>
      <Container className="comp__program__form">
        <div className="home__content">
          <label className="input__header" htmlFor="">
            <Trans>socialCategory.head</Trans>
          </label>
          <select
            // defaultValue={`${i18next.t('socialCategory.not')}`}
            value={socialCategory?.id}
            className="default-input default-input--select"
            onChange={handleStateChange}
            disabled={readOnly}
          >
            {optionObject().map((p) => (
              <option key={p.id} value={p.id}>
                {p.name}
                {/* можно использовать дополнительный уникальный ключ */}
                {/*{i18next.t('socialCategory.' + p.key)}*/}
              </option>
            ))}
          </select>
        </div>
      </Container>
    </>
  );
});

export default FieldSocialCategory;
