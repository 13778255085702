import React from 'react';
import { Trans } from 'react-i18next';
import { ApplicationFormContext } from '../ApplicationFormProvider';
import Textarea from '../Ui/Textarea';
import { sectionNumber } from '../ApplicationFormProvider/sectionNumber';

class FieldEco extends React.Component<any, any> {
  static contextType = ApplicationFormContext;

  handleInputChange = (event) => {
    const target = event.target;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const name = target.name;

    this.context.handleApplicationFormChange({
      environmental: { [name]: value },
    });
  };

  render() {
    const { readOnly } = this.context;
    const { environmental, methods, friendliness } =
      this.context.applicationForm.environmental;

    return (
      <>
        <div className="compform__divider" />
        <h1 className="main__question">
          {sectionNumber.renderSection('ECO')}. <Trans>eco.eco</Trans>
        </h1>

        <h3 className="form__question">
          {sectionNumber.renderSubSection('eco.environmental')}.{' '}
          <Trans>eco.environmental</Trans>
        </h3>
        <p className="form__question__descrption">
          <Trans>eco.environmentalExtra</Trans>
        </p>
        <Textarea
          defaultValue={environmental}
          name="environmental"
          onChange={this.handleInputChange}
          readOnly={readOnly}
        />

        <h3 className="form__question">
          {sectionNumber.renderSubSection('eco.methods')}.{' '}
          <Trans>eco.methods</Trans>
        </h3>
        <p className="form__question__descrption">
          <Trans>eco.methodsExtra</Trans>
        </p>

        <Textarea
          defaultValue={methods}
          name="methods"
          onChange={this.handleInputChange}
          readOnly={readOnly}
        />

        <h3 className="form__question">
          {sectionNumber.renderSubSection('eco.friendliness')}.{' '}
          <Trans>eco.friendliness</Trans>
        </h3>
        <p className="form__question__descrption">
          <Trans>eco.friendlinessExtra</Trans>
        </p>

        <Textarea
          defaultValue={friendliness}
          name="friendliness"
          onChange={this.handleInputChange}
          readOnly={readOnly}
        />
      </>
    );
  }
}

export default FieldEco;
