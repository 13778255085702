import TeamMemberApplicationInfo from "components/TeamMemberApplicationInfo";
import i18next from "i18next";

const errorTranslateForApplicantType = (from, to) => {
  setCurrLanguage();
  let template = i18next.t("BadRequest");
  template = i18next.t("applicantTypeChangeError");
  template = alterTemplateForTypeError(template, from, to);
  return template;
};

const alterTemplateForTypeError = (template, from, to) => {
  const applicantType = {
    GRADUATE_NU: "GraduateNU",
    STUDENT: "Student",
    EMPLOYEE: "Employee",
    GRADUATE_NIS: "GraduateNIS",
  };
  return template
    .replace("<type1>", i18next.t(applicantType[from]))
    .replace("<type2>", i18next.t(applicantType[to]));
};

const setCurrLanguage = () => {
  const lang = localStorage.getItem("i18nextLng");
  i18next.changeLanguage(lang);
};

export { errorTranslateForApplicantType };
