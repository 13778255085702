import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { Trans } from "react-i18next";
import { toast } from "react-toastify";
import i18next from "i18next";
import { inject, observer } from "mobx-react";
import logo from "../../assets/icons/logo.png";
import Notify from "../../helpers/Notifier";

const ThirdPersonChangeType = inject("appState")(
  observer(props => {
    const applicant = props.appState?.applicantsStore?.applicant;
    const email = props.appState?.userStore?.userInfo?.email;
    const history = useHistory();
    const [newType, setNewType] = useState<string>("");

    useEffect(() => {
      props.appState.applicantsStore.isTypeChanging = true;
    }, []);

    const sendApplicantForm = async () => {
      if (!email.includes("nu.edu.kz")) {
        Notify.pushErrorMessage(i18next.t("changeEmail.notNuDomain", { ns: "errors" }));
        return;
      }
      //TODO can be problem in some fields would not stringify, costyly napisal vzamen, may be lodash?
      // const applicantCopy = JSON.parse(JSON.stringify(applicant))
      // // applicantCopy.applicantType = newType;
      applicant.applicantType = newType;
      try {
        const status = await props.appState.applicantsStore.updateApplicant(
          applicant,
          true
        );
        if (status === "SUCCESS") window.location.reload();
      } catch (e) {}
    };

    const handleSelectChange = event => {
      setNewType(event.target.value);
    };

    const onSignUpClick = () => {
      props.appState.userStore.logout().then(() => {
        history.push("/sign-up");
      });
    };

    return (
      <div className="login__card__home form-sign_up scrollbar">
        <img src={logo} className="sign-in__logo mb-3" alt="logo" />
        <h1 className="fs-3 fw-bold form-header mb-3">
          <Trans>ChangeApplicantType</Trans>
        </h1>
        {!email.includes("nu.edu.kz") && (
          <>
            <p className="body__p mb-2">
              <Trans>thirdPersonWithNoNuDomain</Trans>
            </p>
            <button
              className="button-input btn btn-success w-100"
              onClick={onSignUpClick}
            >
              <Trans>goBack</Trans>
            </button>
          </>
        )}
        {email.includes("nu.edu.kz") && (
          <>
            <p className="body__p">
              <Trans>chooseApplicantType</Trans>
            </p>
            <select
              defaultValue="THIRD_PERSON"
              placeholder={i18next.t("chooseApplicantType")}
              onChange={event => handleSelectChange(event)}
              className="general-info__input__select form-control-lg border-input"
            >
              <option disabled value="THIRD_PERSON">
                {i18next.t("ThirdPerson")}
              </option>
              <option value="STUDENT">{i18next.t("Student")}</option>
              <option value="GRADUATE_NU">{i18next.t("GraduateNU")}</option>
              <option value="EMPLOYEE">{i18next.t("Employee")}</option>
            </select>
            <button
              disabled={!Boolean(newType) || newType === "THIRD_PERSON"}
              className="button-input btn btn-success w-100"
              onClick={sendApplicantForm}
            >
              <Trans>Confirm</Trans>
            </button>
          </>
        )}
      </div>
    );
  })
);

export default ThirdPersonChangeType;
