import React from 'react';
import { FaArrowUp } from "react-icons/fa";
import './scroll-top-button.css';
import { Trans } from "react-i18next";
import { observer } from "mobx-react";
import { AppStateObserver, injectAppState } from "../../stores";
export interface ApplicationsProps {}

export interface ApplicationsState {}
@injectAppState
@observer
class ScrollTopButton extends React.Component<
AppStateObserver,
ApplicationsProps,
ApplicationsState
> {
  scrollTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth'
    });
  }

  render() {
    const { language } = this.props.appState.userStore;
    return (
      <div className='scrollTopButton' onClick={this.scrollTop}>
        <Trans>up</Trans>
        <FaArrowUp />
      </div>
    );
  }
}

export default ScrollTopButton;
