import React, { PureComponent } from 'react';
import ReactCrop from 'react-image-crop';
import 'react-image-crop/dist/ReactCrop.css';
import './Avatar.css';
import { Trans } from 'react-i18next';
import { toast } from 'react-toastify';
import i18next from 'i18next';
import Notify from "../../helpers/Notifier";

export default class Avatar extends PureComponent {
  state = {
    src: null,
    crop: {
      unit: '%',
      width: 30,
      aspect: 3 / 4,
    },
    croppedImageUrl: null,
    file: null,
  };

  imageRef;
  fileUrl;

  onSelectFile = (e) => {
    if (e.target.files && e.target.files.length > 0) {
      if (e.target.files[0].size > 10485760) {
        e.target.value = '';
        Notify.pushErrorMessage(i18next.t('input.bigSizePhoto', { ns: 'errors' }));
        return;
      }
      const reader = new FileReader();
      reader.addEventListener('load', () =>
        this.setState({ src: reader.result })
      );
      reader.readAsDataURL(e.target.files[0]);
    }
  };

  // If you setState the crop in here you should return false.
  onImageLoaded = (image) => {
    this.imageRef = image;
  };

  onCropComplete = (crop) => {
    this.makeClientCrop(crop);
  };

  onCropChange = (crop, percentCrop) => {
    // You could also use percentCrop:
    // this.setState({ crop: percentCrop });
    this.setState({ crop });
  };

  makeClientCrop = async (crop) => {
    if (this.imageRef && crop.width && crop.height) {
      const croppedImageUrl = await this.getCroppedImg(
        this.imageRef,
        crop,
        'newFile.jpeg'
      );
      this.setState({ croppedImageUrl });
    }
  };

  getCroppedImg(image, crop, fileName) {
    const canvas = document.createElement('canvas');
    const scaleX = image.naturalWidth / image.width;
    const scaleY = image.naturalHeight / image.height;
    canvas.width = 114;
    canvas.height = 151;
    const ctx = canvas.getContext('2d');

    ctx.drawImage(
      image,
      crop.x * scaleX,
      crop.y * scaleY,
      crop.width * scaleX,
      crop.height * scaleY,
      0,
      0,
      114,
      151
    );

    return new Promise((resolve, reject) => {
      canvas.toBlob((blob) => {
        if (!blob) {
          //reject(new Error('Canvas is empty'));
          console.error('Canvas is empty');
          return;
        }

        window.URL.revokeObjectURL(this.fileUrl);
        this.fileUrl = window.URL.createObjectURL(blob);
        this.setState({ file: blob });
        resolve(this.fileUrl);
      }, 'image/jpeg');
    });
  }

  render() {
    // const {language} = this.props.appState.userStore;
    const { crop, croppedImageUrl, src } = this.state;

    return (
      <div className="avatar__container">
        <div className="avatar__content avatar__content__scroll">
          <div className="avatar__wrap-input">
            <label
              className="avatar__label avatar__label--cabinet"
              htmlFor="file-id"
            >
              <Trans>ChooseFile</Trans>
              <input
                className="avatar__input"
                id="file-id"
                type="file"
                accept="image/*"
                onChange={this.onSelectFile}
              />
              <div className="button-input btn btn-save avatar__btn">
                <Trans>Add</Trans>
              </div>
            </label>
          </div>
          {src && (
            <ReactCrop
              src={src}
              crop={crop}
              ruleOfThirds
              onImageLoaded={this.onImageLoaded}
              onComplete={this.onCropComplete}
              onChange={this.onCropChange}
            />
          )}
        </div>

        {croppedImageUrl && (
          <div className="avatar-crop">
            <img
              alt="Crop"
              style={{
                maxWidth: '114px',
                maxHeight: '151px',
              }}
              src={croppedImageUrl}
            />
          </div>
        )}
      </div>
    );
  }
}
