export const SPDefaultForm = {
  applicationForm: {
    projectDuration: null,
    projectName: null,
  },
  resource: {
    budget: [
      {
        expenditureItem: null,
        amount: null,
        quantity: null,
      },
    ],
  },
  projectIdea: {
    indicators: [
      {
        ultimateGoal: null,
        unit: null,
        name: null,
      },
    ],
    motivationalQuestion: null,
    beneficiaries: null,
    geography: null,
    problemSolving: null,
    socialProblem: null,
  },
  teamMembers: [
    {
      role: null,
      workExperience: null,
      fio: null,
      iin: null,
      email: null,
      resume: null,
    },
  ],
  effectiveness: {
    calendarPlan: [
      {
        event: null,
        deadline: null,
      },
    ],
  },
};

export const SEDefaultForm = {
  applicationForm: {
    projectDuration: null,
    projectName: null,
  },
  projectIdea: {
    indicators: [
      {
        ultimateGoal: null,
        unit: null,
        name: null,
      },
    ],
    motivationalQuestion: null,
    beneficiaries: null,
    geography: null,
    problemSolving: null,
    socialProblem: null,
    viability: null,
    calendarPlan: [
      {
        deadline: null,
        event: null,
      },
    ],
  },
  organizationPotential: {
    file: null,
    financialExpenses: [
      {
        directCosts: null,
        marketing: null,
        taxExpenses: null,
        year: null,
      },
    ],
    financialRevenue: [
      {
        services: null,
        year: null,
      },
    ],
  },
  businessModel: {
    clientPortrait: null,
    marketingChannels: null,
    product: null,
    financialModel: {
      costAndVolume: null,
      expenditure: null,
      incomeDistribution: null,
    },
  },
  resource: {
    partners: null,
    budget: [
      {
        expenditureItem: null,
        amount: null,
        quantity: null,
        price: null,
      },
    ],
    materialBase: [
      {
        name: null,
        purpose: null,
      },
    ],
  },
  teamMembers: [
    {
      role: null,
      workExperience: null,
      fio: null,
      iin: null,
      email: null,
      resume: null,
    },
  ],
  innovativeness: {
    competitors: null,
    innovation: null,
  },
  courseCertificate: { file: null },
};
