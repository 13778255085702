import React, { useContext, useState } from "react";
import classNames from "classnames";
import { Trans, useTranslation } from "react-i18next";
import { ApplicationFormContext } from "../ApplicationFormProvider";
import FileComponent from "../FileComponent";
import validateFileSize from "../../helpers/validateFileSize";
import { useLocalStore } from "mobx-react";
import i18next from "i18next";
import { RootStore } from "../../stores";
import wrongText from "../../helpers/wrongText";
import { FilesStore } from "../../stores/FilesStore";
import MiniLoad from "../MiniLoad/MiniLoad";
import Notify from "../../helpers/Notifier";

type Props = {};

const CourseCertificate: React.FC<Props> = ({}) => {
  const {
    readOnly,
    applicationForm,
    handleApplicationFormChange,
    getError,
    clearError,
  } = useContext(ApplicationFormContext);
  let file = applicationForm.courseCertificate?.file;
  const [loading, setLoading] = useState<boolean>(false);
  const { t } = useTranslation("translations");
  const rootStore = useLocalStore(() => ({
    filesStore: new FilesStore(new RootStore()),
  }));

  const { filesStore } = rootStore;

  const handleFileChange = (files) => {
    clearError("courseCertificate");
    if (!validateFileSize(50, files)) {
      Notify.pushErrorMessage(i18next.t("info.fileSizeLimit", { ns: "errors" }));
      return;
    }
    if (files.length) {
      setLoading(true);
      Array.from(files).map((item) => {
        filesStore
          .uploadFile(item)
          .then((response) => {
            file = { ...file, ...response.data };
            const courseCertificate = { file };
            handleApplicationFormChange({ courseCertificate });
          })
          .catch((e) => {
            console.log(e);
            wrongText(i18next.t("ErrorLoading"));
          })
          .finally(() => {
            setLoading(false);
          });
      });
    }
  };

  const deleteFile = () => {
    if (file) {
      for (let key in file) {
        delete file[key];
      }
      handleApplicationFormChange({ courseCertificate: file });
    }
  };

  return (
    <div className="m-t-20" data-val="courseCertificate">
      <div
        className={classNames(
          "form__question mb-0 rounded-1",
          getError("courseCertificate") && "red-border-error px-1"
        )}
        dangerouslySetInnerHTML={{ __html: t("AttachCertificate") }}
      />
      <div className="inputfile-wrap op-file px-0">
        <label
          className={classNames(
            "button-input btn btn-success",
            readOnly && "disabled"
          )}
          style={{ marginTop: "0", minWidth: "126px" }}
          htmlFor={`certificateFile`}
        >
          {loading ? <MiniLoad /> : <Trans>ChooseFile</Trans>}
        </label>
        <input
          type="file"
          accept="application/pdf,.zip,.rar,.7zip"
          id={`certificateFile`}
          onChange={(event) => handleFileChange(event.target.files)}
          disabled={readOnly}
          readOnly={readOnly}
        />

        {file && !!Object.keys(file)?.length && (
          <FileComponent
            id={file.id}
            name={file.name}
            extension={file.extension}
          />
        )}
        {!readOnly && file && !!Object.keys(file)?.length && (
          <div className="empty_td">
            <span onClick={deleteFile} className="icon-delete">
              +
            </span>
          </div>
        )}
      </div>
      <i
        style={{
          fontSize: "12px",
          paddingTop: "5px",
          paddingBottom: "5px",
          fontWeight: "400",
        }}
      >
        <Trans>SupportedFileFormatsCert</Trans>
      </i>
    </div>
  );
};

export default CourseCertificate;
