import React from "react";
import { IG, PG, SE, SG, SI, SP } from "../consts";
import { Trans } from "react-i18next";

const checkLimits = (sum, prog) => {
  if (sum > 1500000 && prog === SP) {
    return (
      <div
        style={{
          color: "#dc3545",
          fontSize: "12px",
          paddingTop: "3px",
        }}
      >
      <span className="budget-error">
        <Trans>limits.limitSP</Trans>
      </span>
      </div>
    );
  } else if (sum > 5000000 && prog === SE) {
    return (
      <div
        style={{
          color: "#dc3545",
          fontSize: "12px",
          paddingTop: "3px",
        }}
      >
      <span className="budget-error">
        <Trans>limits.limitSE</Trans>
      </span>
      </div>
    );
  } else if (sum > 10000000 && prog === SI) {
    return (
      <div
        style={{
          color: "#dc3545",
          fontSize: "12px",
          paddingTop: "3px",
        }}
      >
      <span className="budget-error">
        <Trans>limits.limitSI</Trans> (10 000 000)
      </span>
      </div>
    );
  } else if (sum > 5000000 && prog === IG) {
    return (
      <div
        style={{
          color: "#dc3545",
          fontSize: "12px",
          paddingTop: "3px",
        }}
      >
      <span className="budget-error">
        <Trans>limits.limitIG</Trans> (5 000 000)
      </span>
      </div>
    );
  } else if (sum > 3000000 && prog === SG) {
    return (
      <div
        style={{
          color: "#dc3545",
          fontSize: "12px",
          paddingTop: "3px",
        }}
      >
      <span className="budget-error">
        <Trans>limits.limitSG</Trans> (3 000 000)
      </span>
      </div>
    );
  } else if (sum > 1000000 && prog === PG) {
    return (
      <div
        style={{
          color: "#dc3545",
          fontSize: "12px",
          paddingTop: "3px",
        }}
      >
      <span className="budget-error">
        <Trans>limits.limitPG</Trans> (1 000 000)
      </span>
      </div>
    );
  }
  return null;
};

export default checkLimits;
