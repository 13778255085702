import * as React from "react";
import { Link } from "react-router-dom";

import "./SignUpConfirm.css";
import logo from "../../assets/icons/logo.png";
import { Trans } from "react-i18next";

export interface SignUpConfirmProps {}

const SignUpConfirm: React.SFC<SignUpConfirmProps> = () => {
  return (
    <form className="login__card__home form-sign_up scrollbar">
      <img src={logo} className="sign-in__logo" alt="logo" />
      <h1 className="fs-3 fw-bold form-header">
        <Trans>ThanksforContacting</Trans>
      </h1>
      <p className="body__p">
        <Trans>EmailSentMessage</Trans> <Trans>ContinueRecoveryText</Trans>
      </p>
      <Link to="/sign-in" className="form-button btn btn-success btn-lg mb-4">
        <Trans>Login</Trans>
      </Link>
      <div className="h-divider" />
      <Link to="/" className="back__home__reg d-flex mb-2">
        <Trans>goBack</Trans>
      </Link>
    </form>
  );
};

export default SignUpConfirm;
