import TeamMemberApplicationInfo from "components/TeamMemberApplicationInfo";
import i18next from "i18next";

const errorTranslate = (teamMemberCode, data) => {
  setCurrLanguage();
  let template = i18next.t("BadRequest");
  if (teamMemberCode == 0) {
    template = i18next.t("errorTeamMemberCode2Email");
  } else if (teamMemberCode == 1) {
    // Email match
    template = i18next.t("errorTeamMemberCode2");
  } else if (teamMemberCode == 3) {
    template = i18next.t("errorTeamMemberCode4");
  } else if (teamMemberCode == 4) {
    template = i18next.t("errorTeamMemberCode5");
  }
  template = alterTemplate(template, data.IIN, data.email, data.serial);
  return template;
};

const alterTemplate = (template, iin, email, serial) => {
  template = template
    .replace("<iin>", iin)
    .replace("<email>", email)
    .replace("<serial>", serial);
  return template;
};

const setCurrLanguage = () => {
  const lang = localStorage.getItem("i18nextLng");
  i18next.changeLanguage(lang);
};

export default errorTranslate;
