import * as React from "react";
import { Route } from "react-router-dom";
import { inject, observer } from "mobx-react";
import MainLayout from "./layouts/MainLayout";
import AuthLayout from "./layouts/AuthLayout";
import SignIn from "./pages/SignIn";
import ThirdPersonChangeType from "./pages/ThirdPersonChangeType/ThirdPersonChangeType";
import { useState } from "react";
import ScrollTopButton from "components/scroll-top-button/scroll-top-button";

const ProtectedRoute = inject(
  "userState",
  "appState"
)(
  observer((props) => {
    const {
      component: Component,
      layout: Layout = MainLayout,
      ...rest
    } = props;

    const { authenticated } = props.userState;
    const shouldChangeType =
      props.appState.applicantsStore.isTypeChanging ||
      props.appState?.applicantsStore?.applicant?.applicantType ===
        "THIRD_PERSON";

    if (!authenticated)
      return (
        <AuthLayout>
          <SignIn />
        </AuthLayout>
      );
    else if (shouldChangeType) {
      return (
        <AuthLayout>
          <ThirdPersonChangeType />
        </AuthLayout>
      );
    }

    return (
      <Route
        {...rest}
        render={(props) => (
          <Layout>
            <ScrollTopButton />
            <Component {...props} />
          </Layout>
        )}
      />
    );
  })
);

export default ProtectedRoute;
