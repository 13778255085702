export default {
  ru: {
    changeProfile: {
      uinDuplicate: "Данный ИИН уже существует",
      phoneDuplicate: "Данный номер телефона уже существует",
    },
    passwordValidation: {
      notValidByReqs:
        "Пароль должен состоять не менее чем из 8 символов и содержать заглавные, строчные буквы латинского алфавита, цифры и специальные символы",
    },
    applicationFormValidation: {
      noCourseCertificate: "Приложите сертификат о прохождении курса «Основы социального предпринимательства»",
      invalidEmailInTM:
        'Введите корректную эл. почту в таблице "Члены проектной комманды"',
      invalidUINInTM:
        'Введите корректный ИИН в таблице "Члены проектной комманды"',
      teamMembersTable: {
        fio: 'Заполните поле ФИО в таблице "Члены проектной комманды"',
        role: 'Заполните поле "Чем он будет заниматься в проекте в таблице"  "Члены проектной комманды"',
        workExperience: 'Заполните поле "Опишите опыт члена команды в данном направлении" в таблице "Члены проектной комманды"',
      },
      projectName: 'Заполните поле "Название проекта"',
      projectDuration: 'Заполните поле "Продолжительность проекта"',
      applicantKind: "Выберите одну из категорий",
      organizationName: 'Заполните поле "Название организации"',
      activityDirection: 'Заполните поле "Направление деятельности"',
      socialProblem: 'Заполните поле "Социальная проблема"',
      problemSolving: 'Заполните поле "Решение проблемы"',
      geography: 'Заполните поле "География реализации"',
      beneficiaries: 'Заполните поле "Благополучатели"',
      motivationalQuestion: 'Заполните поле "Мотивационный вопрос"',
      customers: 'Заполните поле "Клиенты"',
      marketingChannels: 'Заполните поле "Каналы маркетинга"',
      projectPartners: 'Заполните поле "Партнеры проекта"',
      viability: 'Заполните поле "Жизнеспособность проекта"',
      indicators: 'Заполните таблицу "Индикаторы проекта"',
      calendarPlan: 'Заполните таблицу "Календарный план"',
      partners: 'Заполните поле "Партнеры проекта"',
      budget: 'Заполните таблицу "Бюджет проекта"',
      teamMembers: 'Заполните таблицу "Кто входит в Вашу команду?"',
      idea: 'Заполните поле "Идея пилота"',
      goal: 'Заполните поле "Цель масштабирования"',
      ideaIG: 'Заполните поле "Идея масштабирования"',
      environmental:
        'Заполните поле "Экологичность вашего товара, продукта, услуги"',
      methods:
        'Заполните поле "Экологичные методы, подходы и ресурсы в процессе реализации Проекта"',
      friendliness:
        'Заполните поле "Экологически благоприятная среда для благополучателей"',
      product: 'Заполните поле "Продукт/услуга"',
      clientPortrait: 'Заполните поле "Портрет клиента"',
      costAndVolume: 'Заполните таблицу "Финансовая модель действующего бизнеса"',
      expenditure: 'Заполните таблицу "Финансовая модель"',
      incomeDistribution: 'Заполните таблицу "Финансовая модель"',
      materialBase: 'Заполните таблицу "Материально-техническая база"',
      purpose: 'Заполните таблицу "Материально-техническая база"',
      materialBaseFull: 'Заполните полностью таблицу "Материально-техническая база"',
      expenditureFull: 'Заполните полностью таблицу "Финансовая модель"',
      indicatorsFull: 'Заполните полностью таблицу "Индикаторы проекта"',
      calendarPlanFull: 'Заполните полностью таблицу "Календарный план"',
      budgetFull: 'Заполните полностью таблицу "Бюджет проекта"',
      teamMembersFull: 'Заполните полностью таблицу "Кто входит в Вашу команду?"',
      financialRevenueFull: 'Заполните полностью таблицу "Финансовая модель действующего бизнеса"',
      innovation: 'Заполните поле "Инновационность в проекте"',
      competitors: 'Заполните поле "Конкуренты"',
      operatingBusiness: 'Заполните поле "Действующий бизнес"',
      financialRevenue: 'Заполните таблицу "Финансовая модель"',
      financialExpenses: 'Заполните таблицу "Финансовая модель"',
      financialRevenueAfterScal:
        'Заполните таблицу "Финансовая модель проекта после масштабирования"',
      checkJasCamp:
        "Выберите документы, подтверждающие прохождение акселерации/инкубации в течение последних двух лет (1 zip архив)",
      tmResume: 'Прикрепите резюме в таблице "Члены проектной команды"',
      financialRevenueFile: 'Прикрепите файл в таблице "Финансовая модель действующего бизнеса"',
      organizationBin: 'Заполните поле "БИН"',
      ideaModel: 'Заполните поле "Какая ваша бизнес модель?"',
      motivationalQuestionSE:
        'Заполните поле "Почему Вы решили заняться решением данной проблемы?"',
      prototype: 'Заполните поле "Был ли у вас прототип?"',
      profitDirection:
        'Заполните поле "Куда вы собираетесь направить свою прибыль?"',
    },
    changeEmail: {
      success: "На указанную почту отправлено письмо с подтверждением",
      errorEmailDuplicate:
        "Данный email уже имеется в базе, попробуйте еще раз",
      notNuDomain: "Почта должна содержать домен Назарбаев Университета",
    },
    verifyEmal: {
      success:
        "Ваша почта успешно изменена, пройдите по ссылке для авторизации",
      generalError:
        "Произошел сбой при попытке сменить почту, пожалуйста попробуйте снова",
      oldLinkError: "Срок действия ссылки истек, пожалуйста попробуйте снова",
      emailAlreadyActivatedError: "Данная электронная почта уже активирована",
      applicantNotFoundError: "Данный пользователь не найден",
      invalidLinkError: "Неверная ссылка, пожалуйста попробуйте снова",
    },
    input: {
      projectNameRequired: 'Поле "Название проекта" обязательно для заполнения',
      required: "Поле обязательно для заполнения",
      radio: "Выберите один из вариантов",
      table: "Заполните таблицу полностью",
      bigSizePhoto:
        "Размер фото превышает допустимый предел, пожайлуста выберите другой файл",
    },
    registration: {
      firstname: "Введите имя",
      lastname: "Введите фамилию",
      inn: "Введите ИИН",
      email: "Введите email",
      success: "Заявка на регистрацию отправлена, ожидайте письмо на почту",
      errorIinDuplicate: "Данный ИИН уже имеется в базе, попробуйте еще раз",
      errorEmailDuplicate:
        "Данный email уже имеется в базе, попробуйте еще раз",
      errorIdDuplicate: "Данный ID уже имеется в базе, попробуйте еще раз",
      errorGeneralDuplicate:
        "Возможно, данный email, ИИН или ID уже имеется в базе, попробуйте еще раз",
    },
    info: {
      firstname: "Введите имя",
      lastname: "Введите фамилию",
      birthDay: "Введите дату рождения",
      gender: "Выберите пол",
      inn: "Введите ИИН",
      validinn: "Введите ИИН полностью",
      cellNumber: "Введите номер телефона в формате +7 (XXX) XXX-XXXX",
      gpa: "Введите GPA до десятичных",
      entryPoints: "Введите баллы при поступлении",
      grade: "Введите курс",
      dateEnroll: "Введите дату зачисления",
      department: "Введите департамент",
      position: "Введите должность",
      dateHired: "Введите дату найма",
      dateFired: "Введите дату увольнения",
      orgName: "Введите название организации",
      orgBank: "Введите название банка",
      orgBin: "Введите БИН",
      orgBik: "Введите БИК",
      orgKbe: "Введите КБЕ",
      orgIban: "Введите IBAN",
      orgDesc: "Введите описание",
      specialty: "Введите специальность",
      address: "Введите адрес",
      university: "Введите ВУЗ/ТиПО",
      startYear: "Введите год начала",
      contactPerson: "Введите контактное лицо",
      endYear: "Введите год окончания",
      faculty: "Введите факультет",
      age: "Вам должно быть 16 лет",
      fileSizeLimit: "Размер файла превышает допустимый предел",
    },
    unknown: "Что-то пошло не так",
  },
  en: {
    changeProfile: {
      uinDuplicate: "This IIN already exists",
      phoneDuplicate: "This phone number already exists",
    },
    passwordValidation: {
      notValidByReqs:
        "The password must be at least 8 characters long and contain uppercase and lowercase Latin letters, numbers and special characters",
    },
    applicationFormValidation: {
      noCourseCertificate: "Attach a certificate of completion of the \"Fundamentals of Social Entrepreneurship course\"",
      invalidEmailInTM:
        'Enter a valid email in the "Project team members" table',
      invalidUINInTM: 'Enter a valid IIN in the "Project team members" table',
      teamMembersTable: {
        fio: 'Enter a valid Full Name in the "Project team members" table',
        role: 'Enter a valid "What he/she will do within the project" in the "Project team members" table',
        workExperience: 'Enter a valid "Describe the experience of a team member in this area" in the "Project team members" table',
      },
      projectName: '"Project name" required field',
      projectDuration: '"Duration of the project" required field',
      applicantKind: "Choose one of the categories",
      organizationName: '"Organization name" required field',
      activityDirection: '"Activity areas" required field',
      socialProblem: '"Social problem" required field',
      problemSolving: '"Problem solution" required field',
      geography: '"Project geography" required field',
      beneficiaries: '"Beneficiaries" required field',
      motivationalQuestion: '"Motivational question" required field',
      customers: '"Clients" required field',
      marketingChannels: '"Marketing channels" required field',
      projectPartners: '"Partners" required field',
      viability: '"Project viability" required field',
      indicators: '"Project indicators" required field',
      calendarPlan: '"Action plan" required field',
      partners:
        '"Project partners" required field',
      budget: '"Project budget" required field',
      teamMembers: '"Who is in your team?" required field',
      idea: '"Pilot idea" required field',
      goal: '"Scaling objective" required field',
      ideaIG: '"Scaling idea" required field',
      environmental:
        '"Environmental friendliness of your product, work, service" required field',
      methods:
        '"Environmentally friendly methods, approaches and resources in the process of project implementation" required field',
      friendliness:
        '"Environmental friendly place for beneficiaries" required field',
      product: '"Product/service" required field',
      clientPortrait: '"Client’s portrait" required field',
      costAndVolume: '"Financial model of the existing business" required field',
      expenditure: '"Financial model" required field',
      incomeDistribution: '"Financial model of the existing business" required field',
      materialBase:
        '"Material and technical base" required field',
      purpose:
        '"Material and technical base" required field',
      innovation:
        '"Project innovation" required field',
      competitors: '"Competitors" required field',
      operatingBusiness: '"Operating business" required field',
      financialRevenue: '"Financial model of the existing business" required field',
      financialExpenses: '"Financial model of the existing business" required field',
      materialBaseFull: '"Material and technical base" fully required field',
      expenditureFull: '"Financial model" fully required field',
      indicatorsFull: '"Project indicators" fully required field',
      calendarPlanFull: '"Action plan" fully required field',
      budgetFull: '"Project Budget" fully required field',
      financialRevenueFull: '"Financial model of the existing business" fully required field',
      teamMembersFull: '"Who is in your team?" fully required field',
      financialRevenueAfterScal:
        '"Financial model of the operating business" required field',
      checkJasCamp:
        "Choose documents confirming the passage of acceleration / incubation within the last two years (1 zip archive)",
      tmResume: 'Attach a resume in the "Project team members" table',
      financialRevenueFile: 'Attach a file in the "Financial model of the existing business" table',
      organizationBin: '"BIN" required field',
      //TODO ПОЛЯМ НИЖЕ НУЖЕН ПЕРЕВОД
      ideaModel: '"What is your business model?" is required field',
      motivationalQuestionSE:
        '"Why did you decide to solve this problem?" is required field',
      prototype: '"Did you have a prototype?" is required field',
      profitDirection:
        '"Where are you going to direct your profits?" is required field',
    },
    changeEmail: {
      success: "A confirmation email was sent to the entered email",
      errorEmailDuplicate: "This email already exists, please try again",
      notNuDomain: "Email must contain the domain of Nazarbayev University",
    },
    verifyEmal: {
      success:
        "Your email has been successfully changed, follow the link to sign in",
      generalError:
        "There was a failure while trying to change email, please try again",
      oldLinkError: "The link has expired, please try again",
      emailAlreadyActivatedError: "This email is already activated",
      applicantNotFoundError: "The user not found",
      invalidLinkError: "Invalid link, please try again",
    },
    input: {
      projectNameRequired: 'The "Project name" field is required',
      required: "Required field",
      radio: "Choose one of the options",
      table: "Complete the table",
      bigSizePhoto: "Photo size exceeds the limit, please select another file",
    },
    registration: {
      firstname: "Enter your name",
      lastname: "Enter your lastname",
      inn: "Enter the IIN",
      email: "Enter your email",
      success:
        "The application for registration has been sent, expect an email",
      errorIinDuplicate: "This IIN already exists, please try again",
      errorEmailDuplicate: "This email already exists, please try again",
      errorIdDuplicate: "This ID already exists, please try again",
      errorGeneralDuplicate:
        "Perhaps, this email, IIN or ID already exists, please try again",
    },
    info: {
      firstname: "Enter your name",
      lastname: "Enter your lastname",
      birthDay: "Enter your date of birth",
      gender: "Choose a gender",
      inn: "Enter the IIN",
      validinn: "Enter the full IIN",
      cellNumber: "Enter the phone number in the format +7 (XXX) XXX-XXXX",
      gpa: "Enter the GPA to decimal",
      entryPoints: "Enter the points upon admission",
      grade: "Enter the grade",
      dateEnroll: "Enter the date of enrollment",
      department: "Enter the department",
      position: "Enter the position",
      dateHired: "Enter the date of hiring",
      dateFired: "Enter the date of dismissal",
      orgName: "Enter organization name",
      orgBank: "Enter name of the bank",
      orgBin: "Enter the BIN",
      orgBik: "Enter the BIK",
      orgKbe: "Enter the KBE",
      orgIban: "Enter the IBAN",
      orgDesc: "Enter a description",
      specialty: "Enter your specialty",
      address: "Enter the address",
      university: "Enter university or other",
      startYear: "Enter the start year",
      contactPerson: "Enter a contact person",
      endYear: "Enter the year of graduation",
      faculty: "Enter the faculty",
      age: "You must be 16 years old",
      fileSizeLimit: "The size of the file exceeds the acceptable limit",
    },
    unknown: "Something went wrong",
  },
  kz: {
    changeProfile: {
      uinDuplicate: "Бұл ЖСН бұрыннан бар",
      phoneDuplicate: "Бұл телефон нөмірі бұрыннан бар",
    },
    passwordValidation: {
      notValidByReqs:
        "Пароль ұзындығы 8-ден кем емес латын әліпбиінің бас, кіші әріптерінен, сандардан және арнайы символдардан құралуы тиіс",
    },
    applicationFormValidation: {
      noCourseCertificate: "\"Әлеуметтік кәсіпкерлік негіздері\" курсынан өткені туралы сертификатты қоса беріңіз",
      invalidEmailInTM:
        '"Жоба тобының мүшелері" өрісінде дұрыс электрондық поштаны енгізіңіз',
      invalidUINInTM: '"Жоба тобының мүшелері" өрісінде дұрыс ЖСН енгізіңіз',
      teamMembersTable: {
        fio: '"Жоба тобының мүшелері" өрісінде дұрыс ТАӘ енгізіңіз',
        role: '"Жоба тобының мүшелері" өрісінде дұрыс "Жобадағы ерекше рөл / жауапкершілік" енгізіңіз',
        workExperience: '"Жоба тобының мүшелері" өрісінде дұрыс "Жоба бойынша сәйкес тәжірибесін сипаттаңыз" енгізіңіз',
      },
      projectName: '"Жоба атауы" өрісін толтырыңыз',
      projectDuration: '"Жобаның ұзақтығы" өрісін толтырыңыз',
      applicantKind: "Санаттардың бірін таңдаңыз",
      organizationName: '"Ұйымның атауы" өрісін толтырыңыз',
      activityDirection: '"Қызмет бағыты" өрісін толтырыңыз',
      socialProblem: '"Әлеуметтік мәселе" өрісін толтырыңыз',
      problemSolving: '"Мәселені шешу" өрісін толтырыңыз',
      geography: '"Іске асыру географиясы" өрісін толтырыңыз',
      beneficiaries: '"Игілік алушылар" өрісін толтырыңыз',
      motivationalQuestion: '"Уәждемелік сұрақ" өрісін толтырыңыз',
      customers: '"Клиенттер" өрісін толтырыңыз',
      marketingChannels: '"Маркетинг арналары" өрісін толтырыңыз',
      projectPartners: '"Жобаның серіктестері" өрісін толтырыңыз',
      viability: '"Жобаның өміршеңдігі" өрісін толтырыңыз',
      indicators: '"Жобаның индикаторлары" өрісін толтырыңыз',
      calendarPlan: '"Күнтізбелік жоспар" өрісін толтырыңыз',
      partners:
        '"Жобаның серіктестері" өрісін толтырыңыз',
      budget: '"Жобаның бюджеті" өрісін толтырыңыз',
      teamMembers: '"Сіздің командаңызға кім кіреді?" өрісін толтырыңыз',
      idea: '"Пилоттың идеясы" өрісін толтырыңыз',
      goal: '"Масштабтау мақсаты" өрісін толтырыңыз',
      ideaIG: '"Масштабтау идеясы" өрісін толтырыңыз',
      environmental:
        '"Өз өніміңіздің, жұмысыңыздың, қызметіңіздің экологиялық жағынан тазалығы" өрісін толтырыңыз',
      methods:
        '"Жобаны iске асыру процесiндегi экологиялық таза әдiстер, тәсiлдер мен ресурстар" өрісін толтырыңыз',
      friendliness:
        '"Игілік алушылар үшін экологиялық таза орта" өрісін толтырыңыз',
      product: '"Өнім/қызмет көрсету" өрісін толтырыңыз',
      clientPortrait: '"Клиенттің портреті" өрісін толтырыңыз',
      costAndVolume: '"Қолданыстағы бизнестің қаржылық моделі" өрісін толтырыңыз',
      expenditure: '"Қаржылық моделі" өрісін толтырыңыз',
      incomeDistribution: '"Қолданыстағы бизнестің қаржылық моделі" өрісін толтырыңыз',
      materialBase:
        '"Материалдық-техникалық база" өрісін толтырыңыз',
      purpose:
        '"Материалдық-техникалық база" өрісін толтырыңыз',
      innovation:
        '"Жобадағы инновациялылық" өрісін толтырыңыз',
      competitors: '"Бәсекелестер" өрісін толтырыңыз',
      operatingBusiness: '"Қолданыстағы бизнес" өрісін толтырыңыз',
      financialRevenue: '"Қолданыстағы бизнестің қаржылық моделі" өрісін толтырыңыз',
      financialExpenses: '"Қолданыстағы бизнестің қаржылық моделі" өрісін толтырыңыз',
      materialBaseFull: '"Материалдық-техникалық база" өрісін толықтай толтырыңыз',
      expenditureFull: '"Қаржылық моделі" өрісін толықтай толтырыңыз',
      indicatorsFull: '"Жобаның индикаторлары" өрісін толықтай толтырыңыз',
      calendarPlanFull: '"Күнтізбелік жоспар" өрісін толықтай толтырыңыз',
      budgetFull: '"Жобаның бюджеті" өрісін толықтай толтырыңыз',
      teamMembersFull: '"Сіздің командаңызға кім кіреді?" өрісін толықтай' +
        ' толтырыңыз',
      financialRevenueFull: '"Қолданыстағы бизнестің қаржылық моделі" өрісін толықтай толтырыңыз',
      financialRevenueAfterScal:
        '"Масштабтаудан кейінгі қаржылық моделі" өрісін толтырыңыз',
      checkJasCamp:
        "Соңғы екі жыл ішінде жеделдету/инкубациядан өткенін растайтын құжаттарды (1 zip мұрағат) таңдаңыз",
      tmResume: '"Жоба тобының мүшелері" өрісінге түйіндемені тіркеңіз',
      financialRevenueFile: '"Қолданыстағы бизнестің қаржылық моделі" өрісінге файлды тіркеңіз',
      organizationBin: '"БСН" өрісін толтырыңыз',
      //TODO ПОЛЯМ НИЖЕ НУЖЕН ПЕРЕВОД
      ideaModel: '"Сіздің бизнес-моделіңіз қандай?" өрісін толтырыңыз',
      motivationalQuestionSE:
        '"Бұл мәселені шешуге неге шешім қабылдадыңыз?" өрісін толтырыңыз',
      prototype: '"Сізде прототип болды ма?" өрісін толтырыңыз',
      profitDirection:
        '"Сіз өз пайдаңызды қайда бағыттайсыз?" өрісін толтырыңыз',
    },
    changeEmail: {
      success: "Енгізілген поштаға растау хаты жіберілді",
      errorEmailDuplicate: "Бұл email бұрыннан бар, әрекетті қайталаңыз",
      notNuDomain: "Почта должна содержать домен Назарбаев Университета",
    },
    verifyEmal: {
      success:
        "Сіздің поштаңыз сәтті өзгертілді, авторизация сілтемесіне өтіңіз",
      generalError: "Поштаны өзгерту әрекеті сәтсіз аяқталды, қайталап көріңіз",
      oldLinkError: "Сілтеменің мерзімі аяқталды, қайталап көріңіз",
      emailAlreadyActivatedError: "Бұл электрондық пошта іске қосылды",
      applicantNotFoundError: "Бұл пайдаланушы табылмады",
      invalidLinkError: "Қате сілтеме, қайталап көріңіз",
    },
    input: {
      projectNameRequired: '"Жоба атауы" өрісі міндетті түрде толтырылады',
      required: "Өрісті толтыру міндетті",
      radio: "Опциялардың бірін таңдаңыз",
      table: "Кестені толығымен толтырыңыз",
      bigSizePhoto:
        "Фотосуреттің өлшемі рұқсат етілген шектен асады, басқа файлды таңдаңыз",
    },
    registration: {
      firstname: "Атын енгізіңіз",
      lastname: "Тегін енгізіңіз",
      inn: "ЖСН енгізіңіз",
      email: "Email енгізіңіз",
      success: "Тіркеуге өтінім жіберілді, поштаға хат күтіңіз",
      errorIinDuplicate: "Бұл ЖСН бұрыннан бар, әрекетті қайталаңыз",
      errorEmailDuplicate: "Бұл email бұрыннан бар, әрекетті қайталаңыз",
      errorIdDuplicate: "Бұл ID бұрыннан бар, әрекетті қайталаңыз",
      errorGeneralDuplicate:
        "Мүмкін, бұл email, ЖСН немесе ID бұрыннан бар, қайталап көріңіз",
    },
    info: {
      firstname: "Атын енгізіңіз",
      lastname: "Тегін енгізіңіз",
      birthDay: "Туған күнді енгізіңіз",
      gender: "Еденді таңдаңыз",
      inn: "ЖСН енгізіңіз",
      validinn: "ЖСН толығымен енгізіңіз",
      cellNumber: "Телефон нөмірін +7 (XXX) XXX-XXXX форматында енгізіңіз",
      gpa: "Ондыққа дейін GPA енгізіңіз",
      entryPoints: "Қабылдау кезінде ұпайларды енгізіңіз",
      grade: "Курсты енгізіңіз",
      dateEnroll: "Қабылдау күнін енгізіңіз",
      department: "Бөлімді енгізіңіз",
      position: "Позицияны енгізіңіз",
      dateHired: "Жалдау күнін енгізіңіз",
      dateFired: "Жұмыстан босату күнін енгізіңіз",
      orgName: "Атауын енгізіңіз",
      orgBank: "Банк атауын енгізіңіз",
      orgBin: "БСН енгізіңіз",
      orgBik: "БИК енгізіңіз",
      orgKbe: "KBE енгізіңіз",
      orgIban: "IBAN енгізіңіз",
      orgDesc: "Сипаттаманы енгізіңіз",
      specialty: "Мамандықты енгізіңіз",
      address: "Мекенжайды енгізіңіз",
      university: "Университетті немесе басқасын енгізіңіз",
      startYear: "Басталу жылын енгізіңіз",
      contactPerson: "Байланысатын адамды енгізіңіз",
      endYear: "Аяқталу жылын енгізіңіз",
      faculty: "Факультетті енгізіңіз",
      age: "Сіз 16 жаста болуыңыз керек",
      fileSizeLimit: "Файл өлшемі рұқсат етілген шектен асады",
    },
    unknown: "Бірдеңе дұрыс болмады",
  },
};
