import React from 'react';
import { Trans } from 'react-i18next';
import FinancialModel from '../Tables/FinancialModel';
import { ApplicationFormContext } from '../ApplicationFormProvider';
import Textarea from '../Ui/Textarea';
import { sectionNumber } from '../ApplicationFormProvider/sectionNumber';
import { SE } from '../../consts';
import renderTitle from '../FieldBusinessModel/title';

class FieldOrganizationPotential extends React.Component<any, any> {
  static contextType = ApplicationFormContext;

  handleInputChange = (event) => {
    const target = event.target;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const name = target.name;

    this.context.handleApplicationFormChange({
      organizationPotential: { [name]: value },
    });
  };

  handleFRChange = (fr) => {
    this.context.handleApplicationFormChange({
      organizationPotential: { financialRevenue: fr },
    });
  };
  handleFEChange = (fe) => {
    this.context.handleApplicationFormChange({
      organizationPotential: { financialExpenses: fe },
    });
  };

  render() {
    const { operatingBusiness, financialRevenue, financialExpenses } =
      this.context.applicationForm.organizationPotential;
    const programName = this.props.program.name;
    const { readOnly } = this.context;

    return (
      <>
        <div className="compform__divider" />
        {programName !== SE &&
          <h1 className={'main__question'}>
            {sectionNumber.renderSection('POTENTIAL')}.{' '}
            <Trans>organizationPotential.title</Trans>
          </h1>
        }
        {programName === SE &&
          <h1 className="main__question">
            {sectionNumber.renderSection('BUSINESS')}. {renderTitle(programName)}
          </h1>
        }
        {programName !== SE &&
          <>
            <h3 className="form__question">
              {sectionNumber.renderSubSection(
                'organizationPotential.operatingBusiness'
              )}
              . <Trans>organizationPotential.operatingBusiness</Trans>
            </h3>
            <p className={'form__question__descrption'}>
              <Trans>organizationPotential.operatingBusinessExtra</Trans>
            </p>

            <Textarea
              defaultValue={operatingBusiness}
              name="operatingBusiness"
              onChange={this.handleInputChange}
              readOnly={readOnly}
            />
          </>
        }

        <FinancialModel
          program={this.props.program}
          financialRevenue={financialRevenue}
          financialExpenses={financialExpenses}
          handleFRChange={this.handleFRChange}
          handleFEChange={this.handleFEChange}
        />
      </>
    );
  }
}

export default FieldOrganizationPotential;
