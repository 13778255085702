import { useContext } from "react";
import { ApplicationFormContext } from "../components/ApplicationFormProvider";
import { SEDefaultForm, SPDefaultForm } from "../const";

const useProgressBar = () => {
  const { applicationForm } = useContext(ApplicationFormContext);
  function calculateFormCompletion(formData, templateObject) {
    if (!formData || !templateObject) return 0;
    let totalFields = 0;
    let unCompletedFields = 0;
    function checkFieldCompletion(field, value) {
      const specificCase = isException(field, value);
      if (specificCase || (!value && !isAcceptableZero(field, value))) {
        unCompletedFields++;
      }
      totalFields++;
    }

    function iterateTemplateObject(obj, formData) {
      for (const key in obj) {
        if (Array.isArray(obj[key])) {
          if (obj[key].length === 0) checkFieldCompletion(key, null);
          for (let i = 0; i < formData[key]?.length || 0; i++) {
            if (typeof obj[key][0] === "object" && obj[key][0] !== null) {
              iterateTemplateObject(obj[key][0], formData[key][i] || {});
            } else {
              checkFieldCompletion(`${key}[${i}]`, formData[key][i]);
            }
          }
        } else if (typeof obj[key] === "object" && obj[key] !== null) {
          iterateTemplateObject(obj[key], formData[key] || {});
        } else {
          checkFieldCompletion(key, formData[key]);
        }
      }
    }

    iterateTemplateObject(templateObject, formData);

    const completionPercentage =
      ((totalFields - unCompletedFields) / totalFields) * 100;
    return completionPercentage.toFixed(2);
  }

  let template;

  template =
    applicationForm?.programs[0]?.contractCode === "SP"
      ? SPDefaultForm
      : applicationForm?.programs[0]?.contractCode === "SE"
      ? SEDefaultForm
      : undefined;

  if (applicationForm.applicationForm.applicantKind === "ORGANIZATION") {
    template.applicationForm.activityDirection = null;
    template.applicationForm.organizationBin = null;
    template.applicationForm.organizationName = null;
  } else {
    delete template.applicationForm.activityDirection;
    delete template.applicationForm.organizationBin;
    delete template.applicationForm.organizationName;
  }

  if (applicationForm?.programs[0]?.contractCode === "SE") {
    checkFileWhenFinModelIsZero(applicationForm, template);
  }

  const progress = calculateFormCompletion(applicationForm, template);

  return Math.round(Number(progress));
};

const isException = (field: string, value: any) => {
  if (typeof value === "object" && value !== null) {
    return isEmpty(value);
  } else
    return (
      value === "-" && (field === "projectDuration" || field === "deadline")
    );
};

const isAcceptableZero = (field: string, value: any) => {
  const zeroIsAcceptable =
    field === "directCosts" ||
    field === "taxExpenses" ||
    field === "services" ||
    field === "marketing";
  return zeroIsAcceptable && value === 0;
};

function isEmpty(obj) {
  for (const prop in obj) {
    if (Object.hasOwn(obj, prop)) {
      return false;
    }
  }

  return true;
}

const checkFileWhenFinModelIsZero = (applicationForm, template) => {
  let allZeros = true;
  if (!applicationForm.organizationPotential) return template;

  applicationForm.organizationPotential?.financialExpenses.forEach((el) => {
    if (el.directCosts !== 0 || el.directCosts !== 0 || el.taxExpenses !== 0) {
      allZeros = false;
    }
  });

  applicationForm.organizationPotential?.financialRevenue.forEach((el) => {
    if (el.services !== 0) {
      allZeros = false;
    }
  });

  if (allZeros) {
    delete template.organizationPotential.file;
  } else {
    template.organizationPotential.file = null;
  }
  return template;
};

export default useProgressBar;
