import React, { createContext, ReactNode, useEffect, useState } from "react";
import { toJS } from "mobx";
import { inject } from "mobx-react";
import {
  EApplicantKind,
  IApplicationForm,
  IApplicationFormContext,
} from "./types";
import { sectionNumber } from "./sectionNumber";
import { toast } from "react-toastify";
import i18next from "i18next";
import { useVerify } from "helpers/verifyApplication";
import { applicationActiveStatuses } from "consts";
import { IG, PG, SE, SG, SI, SP } from "../../consts";
import { isCompetitionDeadline } from "helpers/date";
import Notify from "../../helpers/Notifier";

interface ProviderProps {
  children?: ReactNode;
  appState?: any;
  checkProgram?: boolean;
}

export const defaultApplicationForm: IApplicationForm = {
  applicationStatus: "",
  applicationForm: {
    organizationName: "",
    projectName: "",
    activityDirection: "",
    // компонент делает split всегда, по умолчанию должно быть '-'
    projectDuration: "-",
    applicantKind: EApplicantKind.individual,
    organizationBin: "",
  },
  id: "",
  environmental: {
    environmental: "",
    methods: "",
    friendliness: "",
  },
  resource: {
    materialBase: [],
    budget: [],
    selfBudget: [],
    partners: "",
  },
  organizationPotential: {
    financialExpenses: [],
    financialRevenue: [],
    file: {},
  },
  innovativeness: {
    innovation: "",
    competitors: "",
  },
  expense: [],
  projectIdea: {
    socialProblem: "",
    motivationalQuestion: "",
    problemSolving: "",
    beneficiaries: "",
    viability: "",
    indicators: [],
    calendarPlan: [],
    geography: "",
  },
  businessModel: {
    clientPortrait: "",
    marketingChannels: "",
    product: "",
    financialModel: {
      expenditure: "",
      costAndVolume: "",
      incomeSource: "",
      incomeDistribution: "",
    },
  },
  teamMembers: [
    {
      email: "",
      iin: "",
      fio: "",
      workExperience: "",
      role: "",
    },
    {
      email: "",
      iin: "",
      fio: "",
      workExperience: "",
      role: "",
    },
  ],
  scalability: {
    indicators: [],
    calendarPlan: [],
  },
  // appDocs: [],
  direction: {},
  effectiveness: {
    indicators: [],
    calendarPlan: [],
  },
  businessPlan: {},
  courseCertificate: {
    file: {},
  },
};

const copyDefaultApplicationForm = JSON.parse(
  JSON.stringify(defaultApplicationForm)
);

export const ApplicationFormContext = createContext<IApplicationFormContext>({
  applicationForm: copyDefaultApplicationForm,
});

export const ApplicationFormProvider = inject("appState")(
  (props: ProviderProps) => {
    // const applicationStorage = new LocalStorage({ storageKey: 'applicationForm' });
    const applicant = props.appState.applicantsStore.applicant;
    const competition = props.appState.competitionsStore.competition;
    const application = props.appState.applicationsStore.application;
    const checkProgram = props.checkProgram;

    // checkTeamMember = true
    let readOnly =
      !applicationActiveStatuses.includes(application.applicationStatus) ||
      (props.appState.userStore.teamRole.isTeamMember &&
        props.appState.userStore.teamRole.currentApplicantId !==
          props.appState.userStore.teamRole.teamLeadApplicantId);

    readOnly = application.applicationStatus === "REWORK" ? false : readOnly;
    // состояние формы хранится тут
    const [applicationForm, setApplicationForm] = useState<IApplicationForm>(
      copyDefaultApplicationForm
    );
    const [isFormChanged, setFormChanged] = useState<boolean>(false);
    const teamMembers = applicationForm.teamMembers;
    const program =
      applicationForm.programs?.length && applicationForm.programs[0];

    useEffect(() => {
      const initialTm = [];
      if (!teamMembers?.length || teamMembers?.length < 2) {
        const memberForm = {
          email: "",
          iin: "",
          fio: "",
          workExperience: "",
          role: "",
        };
        const leaderForm = {
          email: applicant.email,
          iin: applicant.iin,
          fio: applicant.fio,
          workExperience: "",
          role: "",
        };
        if (!teamMembers.length) {
          initialTm.push(leaderForm);
          initialTm.push(memberForm);
        } else if (teamMembers.length === 1) {
          initialTm.push(memberForm);
        }

        handleApplicationFormChange({ teamMembers: initialTm }, false);
      }
    }, [program, applicant]);

    const leaderHasNoPresetData =
      teamMembers &&
      teamMembers.length &&
      (!teamMembers[0]?.email || !teamMembers[0]?.iin || !teamMembers[0]?.fio);

    useEffect(() => {
      if (leaderHasNoPresetData) {
        const copyForm = JSON.parse(JSON.stringify(applicationForm));
        if (!teamMembers[0]?.email && applicant?.email) {
          copyForm.teamMembers[0].email = applicant?.email;
        }
        if (!teamMembers[0]?.iin && applicant?.iin) {
          copyForm.teamMembers[0].iin = applicant?.iin;
        }
        if (!teamMembers[0]?.fio && applicant?.fio) {
          copyForm.teamMembers[0].fio = applicant?.fio;
        }
        setApplicationForm(copyForm);
      }
    }, [leaderHasNoPresetData, applicant]);

    const {
      verifyApplication,
      errors,
      getError,
      clearError,
      clearAllErrors,
      isValid,
    } = useVerify(checkProgram);

    const statusObj = {
      ESP_ERROR_APPLICANT_NOT_FOUND: i18next.t("ESP_ERROR_APPLICANT_NOT_FOUND"),
      ESP_ERROR_IIN_NOT_FOUND: i18next.t("ESP_ERROR_IIN_NOT_FOUND"),
      ESP_ERROR_IIN_MISMATCH: i18next.t("ESP_ERROR_IIN_MISMATCH"),
      ESP_ERROR_APPLICATION_ALREADY_SIGNED: i18next.t(
        "ESP_ERROR_APPLICATION_ALREADY_SIGNED"
      ),
      ESP_ERROR_CERTIFICATE_NOT_EFFECTIVE_YET: i18next.t(
        "ESP_ERROR_CERTIFICATE_NOT_EFFECTIVE_YET"
      ),
      ESP_ERROR_CERTIFICATE_EXPIRED: i18next.t("ESP_ERROR_CERTIFICATE_EXPIRED"),
      ESP_ERROR_ISSUER_INVALID: i18next.t("ESP_ERROR_ISSUER_INVALID"),
      ESP_ERROR_GENERAL_ERROR: i18next.t("BadRequest"),
    };

    useEffect(() => {
      if (application) handleApplicationFormChange(toJS(application), false);
    }, [application]);

    const mergeData = (target, source) => {
      for (let key of Object.keys(source)) {
        if (!Array.isArray(source[key]) && typeof source[key] === "object")
          source[key] = {
            ...source[key],
            ...mergeData(target[key] || {}, source[key]),
          };
      }

      return { ...target, ...source };
    };

    // очищаем все объекты в json если они пустые
    // так принимает back
    const clearData = (source) => {
      Object.keys(source).map((key) => {
        if (typeof source[key] === "object") {
          if (Object.entries(source[key]).length === 0) {
            delete source[key];
          } else clearData(source[key]);
        }
      });

      return source;
    };

    // если меняется любое из полей сохраняем состояние формы тут
    const handleApplicationFormChange = (appForm, touch: boolean = true) => {
      // если сюда приходит { anyObject: { property: value } }
      // надо чтобы оно ДОПИСЫВАЛО в anyObject значения, а не перезаписывало весь anyObject
      // use mergeData function
      // applicationStorage.setItem(JSON.stringify({ ...applicationForm, ...appForm }));
      setApplicationForm(mergeData(applicationForm, appForm));
      // console.log("WITH TEMS",applicationForm);
      // при первой загрузке с сервера не ставить флаг
      if (touch) setFormChanged(true);
    };

    // надо почистить json чтобы не отправлять множество не нужных полей
    const clearBeforeSend = (_applicationForm) => {
      let applicationForm = { ..._applicationForm };

      delete applicationForm.updateTs;
      delete applicationForm.createTs;
      delete applicationForm.juries;
      delete applicationForm.externalJuries;
      delete applicationForm.votes;
      delete applicationForm.votes;
      delete applicationForm.stages;
      delete applicationForm.star;
      delete applicationForm.dateApply;
      delete applicationForm.dateRegistration;
      delete applicationForm.applicationStatus;

      delete applicationForm.competition.moderator;
      delete applicationForm.competition.juries;
      delete applicationForm.competition.externalJuries;
      delete applicationForm.competition.reqDocs;
      delete applicationForm.competition.chairman;

      // какая-то проблема на back-е с id члена команды
      applicationForm.teamMembers.map((_teamMember, key) => {
        delete applicationForm.teamMembers[key].id;
      });

      applicationForm = clearData(applicationForm);

      return applicationForm;
    };

    const resetApplicationForm = () => {
      setApplicationForm(defaultApplicationForm);
    };

    // очищаем форму
    const clearApplicationForm = (appForm) => {
      sectionNumber.clearSections();
      const fieldsToLeave = {teamMembers: applicationForm.teamMembers, applicationForm: applicationForm.applicationForm}
      console.log(applicationForm);
      const copyObj = JSON.parse(
        JSON.stringify(mergeData(defaultApplicationForm, {...appForm, ...fieldsToLeave}))
      );

      clearExtraFields(copyObj);
      setApplicationForm(copyObj);
      setFormChanged(false);
      clearAllErrors();
    };

    const clearExtraFields = (appForm) => {
      if (appForm.programs[0].name !== PG) appForm.projectIdea.geography = "";
      if (appForm.programs[0].name === IG || appForm.programs[0].name === SG) {
        appForm.businessModel.clientPortrait = "";
        appForm.businessModel.marketingChannels = "";
        appForm.resource.partners = "";
        appForm.innovativeness.competitors = "";
      }
    };

    // сохраняем черновик формы на сервере
    const saveApplication = (procTaskId?: string, type?: string) => {
      if (!applicationForm.programs) {
        const { id, name } = competition.competitionType.compPrograms[0];
        applicationForm.programs = [{ id, name }];
      }

      let finalApplicationForm = toJS({
        ...applicationForm,
        id: application.id,
        competition: { id: competition.id },
        applicant: { id: applicant.id },
      });


      const clearedForm = clearBeforeSend(finalApplicationForm);
      props.appState.applicationsStore.updateEntityService(
        clearedForm,
        "",
        "",
        "",
        "saveTemp",
        type
      );
    };

    const saveAndCheckApplicationBeforeSign = async (procTaskId?: string) => {
      const { competition } = props.appState.competitionsStore;

      const hasDeadlineCome = isCompetitionDeadline(
        competition.dateEndRegistration
      );
      if (hasDeadlineCome && applicationForm?.applicationStatus !== "REWORK") {
        Notify.pushErrorMessage(i18next.t("lateSubmissionError"));
        return false;
      } else {
        if (!applicationForm.programs) {
          const { id, name } = competition.competitionType.compPrograms[0];
          applicationForm.programs = [{ id, name }];
        }

        let finalApplicationForm = toJS({
          ...applicationForm,
          id: application.id,
          competition: { id: competition.id },
          applicant: { id: applicant.id },
        });

        const clearedForm = clearBeforeSend(finalApplicationForm);

        convertEmptyStringsToNull(clearedForm);

        const isFormVerified =
          await props.appState.applicationsStore.updateEntityServiceWithErrorChecking(
            clearedForm,
            "",
            "",
            "",
            "saveSign",
            "sign"
          );

        return isFormVerified;
      }
    };

    const convertEmptyStringsToNull = (obj, isDeeper = false) => {
      for (const key in obj) {
        if (key === "organizationPotential" || isDeeper) {
          if (typeof obj[key] === "string" && obj[key].trim() === "") {
            obj[key] = null;
          } else if (Array.isArray(obj[key])) {
            obj[key].forEach((item) => {
              if (typeof item === "object" && item !== null) {
                convertEmptyStringsToNull(item, true);
              } else if (typeof item === "string" && item.trim() === "") {
                obj[key][obj[key].indexOf(item)] = null;
              }
            });
          } else if (typeof obj[key] === "object" && obj[key] !== null) {
            convertEmptyStringsToNull(obj[key], true);
          }
        }
      }
    };

    const notifyTeamMembersAfterSign = (applicationID: string) => {
      props.appState.applicationsStore.sendNotificationTeamMembers(
        applicationID
      );
    };

    // отправляем подписанную форму на сервер
    const signApplication = (procTaskId: string, esp, password: string) => {
      // склеиваем модели cuba
      let finalApplicationForm = toJS({
        ...applicationForm,
        id: application.id,
        competition: { id: competition.id },
        applicant: { id: applicant.id },
      });
      props.appState.applicationsStore.isLoadingApplication = true;
      props.appState.applicationsStore
        .approveApplication(
          clearBeforeSend(finalApplicationForm),
          esp.base64URL,
          password,
          "",
          "sign",
          procTaskId
        )
        .then((response) => {
          response = JSON.parse(response as string);
          props.appState.applicationsStore.isLoadingApplication = false;
          // тут может прийти { status: 'ERROR', message: '...' }
          if (response.status === "SUCCESS") {
            window.location.reload();
            // history.push('/');
          } else {
            switch (response.errorCode) {
              case "ESP_ERROR_APPLICANT_NOT_FOUND":
                Notify.pushErrorMessage(statusObj[response.errorCode]);
                break;
              case "ESP_ERROR_IIN_NOT_FOUND":
                Notify.pushErrorMessage(statusObj[response.errorCode]);
                break;
              case "ESP_ERROR_IIN_MISMATCH":
                Notify.pushErrorMessage(statusObj[response.errorCode]);
                break;
              case "ESP_ERROR_APPLICATION_ALREADY_SIGNED":
                Notify.pushErrorMessage(statusObj[response.errorCode]);
                break;
              case "ESP_ERROR_CERTIFICATE_NOT_EFFECTIVE_YET":
                Notify.pushErrorMessage(statusObj[response.errorCode]);
                break;
              case "ESP_ERROR_CERTIFICATE_EXPIRED":
                Notify.pushErrorMessage(statusObj[response.errorCode]);
                break;
              case "ESP_ERROR_ISSUER_INVALID":
                Notify.pushErrorMessage(statusObj[response.errorCode]);
                break;
              case "ESP_ERROR_GENERAL_ERROR":
                Notify.pushErrorMessage(statusObj[response.errorCode]);
                break;
              case "ESP_PASSWORD_INCORRECT":
                Notify.pushErrorMessage(i18next.t("ECP_PASSWORD_ERROR"));
                break;
              default:
                // NOTE: there is case when server won't send the message property, only ERROR property in a body
                if (response.message) {
                  Notify.pushErrorMessage(response.message);
                } else {
                  Notify.pushErrorMessage(i18next.t("BadRequest"));
                }
            }
            // if (response.errorCode === 'ESP_ERROR_GENERAL_ERROR') {
            //     Notify.pushErrorMessage(statusObj[response.errorCode] + ' ' + '(' + response.message + ')');
            // } else {
            //     Notify.pushErrorMessage(response.message);
            // }
          }
        })
        .catch((error) => {
          if (error.message) {
            Notify.pushErrorMessage(error.message);
          } else {
            Notify.pushErrorMessage(i18next.t("unknown", { ns: "errors" }));
          }
        });
    };

    return (
      <ApplicationFormContext.Provider
        value={{
          application,
          applicationForm,
          applicant,
          handleApplicationFormChange,
          saveApplication,
          saveAndCheckApplicationBeforeSign,
          signApplication,
          clearApplicationForm,
          isFormChanged,
          clearData,
          readOnly,
          verifyApplication,
          errors,
          getError,
          clearError,
          isValid,
          notifyTeamMembersAfterSign,
          resetApplicationForm,
        }}
      >
        {props.children}
      </ApplicationFormContext.Provider>
    );
  }
);
